import { useApolloClient, useMutation } from "@apollo/client";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import deleteCache from "../../utils/deleteCache";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import useBrandStyles from "./hooks/useBrandStyles";

// form validations
const schema = yup.object({
  brandName: yup.string().required("Brand Name is Required"),
});

interface Props {
  brand?: {
    id: string;
    brandName: string;
    inactive: boolean;
  };
}

const BrandForm: React.FC<Props> = ({ brand }) => {
  const isEdit = typeof brand !== "undefined";
  const history = useHistory();
  const client = useApolloClient();
  const { displayPopMessage } = useMessageState();
  const [loading, setLoading] = useState<boolean>(false);

  const [submitForm] = useMutation(
    isEdit ? UPDATE_BRAND_MUTATION : CREATE_BRAND_MUTATION
  );

  const classes = useBrandStyles({});

  const handleSubmit = async (values: any) => {
    const data = {
      brandName: values.brandName,
      inactive: values.inactive,
    };
    try {
      setLoading(true);
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: brand?.id } }),
            data,
          },
        }, // pass data
      });
      setLoading(false);
      displayPopMessage(`Brand ${!isEdit ? "Added" : "Updated"} Succefully`);

      if (isEdit) {
        const regex = RegExp(`^Brand:${brand?.id}`);
        deleteCache(regex, client);
      }

      history.push({
        pathname: "/admin/brands",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                brandName: isEdit ? brand?.brandName : "",
                inactive: isEdit ? brand?.inactive : false,
              }}
              validationSchema={schema}
            >
              {({ values }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} <mark></mark> Brand Name
                  </h3>
                  <Field
                    name="brandName"
                    component={TextFormField}
                    label="Brand Name"
                    fullWidth
                    variant="outlined"
                    autoFocus
                  />
                  <Field
                    name="inactive"
                    component={CheckboxFormField}
                    defaultChecked={isEdit ? brand?.inactive : false}
                    label="Inactive"
                  />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" mb="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default BrandForm;

export const CREATE_BRAND_MUTATION = gql`
  mutation CREATE_BRAND_MUTATION($input: createBrandInput) {
    createBrand(input: $input) {
      brand {
        brandName
      }
    }
  }
`;

export const UPDATE_BRAND_MUTATION = gql`
  mutation UPDATE_BRAND_MUTATION($input: updateBrandInput) {
    updateBrand(input: $input) {
      brand {
        brandName
      }
    }
  }
`;
