import { useApolloClient } from "@apollo/client";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import deleteCache from "../../utils/deleteCache";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import useStoreStyles from "./hooks/useStoreStyles";
import axios from "axios";
import { api, cloudApi } from "../../config";

const url = process.env.NODE_ENV === "development" ? api : cloudApi;

// form validations
const schema = yup.object({
  storeName: yup.string().required("Store Name is Required"),
});

interface Props {
  store?: {
    id: string;
    storeName: string;
  };
}

const StoreForm: React.FC<Props> = ({ store }) => {
  const isEdit = typeof store !== "undefined";
  const history = useHistory();
  const client = useApolloClient();
  const { displayPopMessage } = useMessageState();
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStoreStyles({});

  const handleSubmit = async (values: any) => {
    const data = {
      storeName: values.storeName,
    };
    try {
      setLoading(true);

      if (isEdit) {
        const res = await axios.put(`${url}/stores/${store?.id}`, data, {
          withCredentials: true,
        });
      } else {
        const res = await axios.post(`${url}/stores`, data, {
          withCredentials: true,
        });
      }
      setLoading(false);
      displayPopMessage(`Store ${!isEdit ? "Added" : "Updated"} Succefully`);

      if (isEdit) {
        const regex = RegExp(`^Store:${store?.id}`);
        deleteCache(regex, client);
      }

      history.push({
        pathname: "/admin/stores",
      });
    } catch (err) {
      console.log(err);
      displayPopMessage(err.message);
      setLoading(false);
    }
  };
  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                storeName: isEdit ? store?.storeName : "",
              }}
              validationSchema={schema}
            >
              {({ values }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} <mark></mark> Store
                  </h3>
                  <Field
                    name="storeName"
                    component={TextFormField}
                    label="Store Name"
                    fullWidth
                    variant="outlined"
                    autoFocus
                  />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" mb="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default StoreForm;
