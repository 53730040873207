import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Product } from "./useProduct";

const useProducts = (category: string, QUERY: any = PRODUCTS_QUERY) => {
  const { data, loading, error, refetch } = useQuery(QUERY, {
    variables: {
      ...(category && {
        where: {
          category: {
            id: category,
          },
        },
      }),
    },
  });

  return {
    productsData: data as {
      productsConnection: {
        values: Product[];
      };
    },
    productsLoading: loading,
    productsError: error,
    refetchProducts: refetch,
  };
};

export default useProducts;

export const useWhereProducts = (
  variables: any,
  QUERY: any = PRODUCTS_QUERY
) => {
  const { data, loading, error, refetch } = useQuery(QUERY, {
    variables,
  });

  return {
    productsData: data as {
      productsConnection: {
        values: Product[];
      };
    },
    productsLoading: loading,
    productsError: error,
    refetchProducts: refetch,
  };
};

export const mapProducts = (productsData: {
  productsConnection: { values: Product[] };
}) => {
  const mappedProducts = productsData.productsConnection.values.map(
    (product) => ({
      id: product.id,
      label: product.productName,
      value: product.id,
    })
  );
  return mappedProducts;
};

export const PRODUCTS_QUERY = gql`
  query PRODUCTS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    productsConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
        count
      }
      values {
        id
        productCode
        productName
        price
        image
        serialNo
        isVoucher
        material {
          id
          name
        }
        category {
          id
          categoryName
        }
        warranty {
          id
          warranty
        }
        color_cats {
          id
          colorPrice
          img
        }
      }
    }
  }
`;

export const COLOR_CATS_QUERY = gql`
  query COLOR_CATS_QUERY(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    colorCatsConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
        count
      }
      values {
        id
        colorCode
        colorPrice
        img
        colorStock
        inActive
        color {
          id
          colorName
        }
        colorDiscount
        lockable
        closure_type
        laptop_com
        laptop_size
        warranty {
          id
          warranty
        }
        p_length
        width
        height
        weight
        product {
          id
          productName
          material {
            id
            name
          }
        }
      }
    }
  }
`;
