import { makeStyles } from "@material-ui/core";
import React from "react";
import ChartistGraph from "react-chartist";
import Card from "../Template/Card/Card";
import CardBody from "../Template/Card/CardBody";
import CardFooter from "../Template/Card/CardFooter";
import CardHeader from "../Template/Card/CardHeader";
import GridContainer from "../Template/Grid/GridContainer";
import GridItem from "../Template/Grid/GridItem";
import chartsData from "./data/chartsData";
import chartsStyles from "./styles/chartsStyles";

//@ts-ignore
const useStyles = makeStyles(chartsStyles);

type Props = {
  data: any;
};

const Charts: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  console.log(data);
  return (
    <>
      <GridContainer>
        {chartsData.map((item, index) => (
          <GridItem xs={12} sm={12} md={6} key={index}>
            <Card chart>
              <CardHeader color={item.color}>
                <ChartistGraph
                  className="ct-chart"
                  data={{
                    labels: data[item.objName].labels,
                    series: data[item.objName].series,
                  }}
                  type={item.type}
                  options={{
                    ...item.options,
                    high: Math.max(...data[item.objName].series[0]) + 100,
                  }}
                  listener={item.listener}
                />
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle}>{item.title}</h4>
                {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  {item.footerIcon} {item.footerTitle}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </>
  );
};

export default Charts;
