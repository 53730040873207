import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useReviews = () => {
  const { data, loading, error } = useQuery(REVIEWS_QUERY);
  return {
    reviewData: data,
    reviewLoading: loading,
    reviewError: error,
  };
};

export default useReviews;

export const mapReviews = (reviewData: any) => {
  const mappedReviews = reviewData.reviewsConnection.values.map(
    (review: { name: string; __typename: string; id: number }) => ({
      id: review.id,
      label: review.name,
      value: review.name,
    })
  );
  return mappedReviews;
};

export const REVIEWS_QUERY = gql`
  query REVIEWS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    reviewsConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        review
        product {
          id
          productName
        }
        rating
        users_permissions_user {
          id
          username
        }
        created_at
        published_at
        updated_at
        orderh {
          id
          orderCode
        }
      }
    }
  }
`;
