import { gql, useQuery } from "@apollo/client";
import { Box, Button, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import Axios from "axios";
import FileDownload from "js-file-download";
import MaterialTable from "material-table";
import stringify from "qs-stringify";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../config";
import { CBCMRoles, CBMRoles } from "../../utils/misc";
import GoBackButton from "../Misc/GoBackButton";
import Loading2 from "../Misc/Loading2";
import MaterialTableCustomFilterButton from "../Misc/MaterialTableCustomFilterButton";
import OrderDateRangeFilter from "./OrderDateRangeFilter";

interface Props {}
const statuses = [
  "COD order placed",
  "Order Placed & Paid",
  "Ready To Ship",
  "Order Items Picked",
  "Order Delivered / Completed",
  "Pick from store Order placed (Pay by hand)",
  "Pick from store Order placed (Paid online)",
  "Order Rejected",
  "Ready To Pickup From Store",
];
const Orders: React.FC<Props> = () => {
  const [filterOrderDate, setFilterOrderDate] = React.useState([""]);
  const [isDateFilter, setIsDateFilter] = React.useState(false);

  const [statusFilter, setStatusFilter] = React.useState("");
  const [totalCount, setTotalCount] = React.useState("");
  const [totalNetAmt, setTotalNetAmt] = React.useState("");
  const tableRef = React.useRef(null);
  const [loading2, setLoading2] = React.useState(false);
  const history = useHistory();
  const { refetch } = useQuery(ORDERS_QUERY, {
    skip: true,
  });
  const role = sessionStorage.getItem("role") as string;

  const handleViewClick = (id: string) => {
    history.push({
      pathname: `/admin/order/${id}`,
    });
  };
  const getTotal = async (filter: any) => {
    let wherefilter = JSON.parse(JSON.stringify(filter));
    if (filter.status) {
      wherefilter["status.type_contains"] = filter.status.type_contains;
      delete wherefilter.status;
    }
    const where = { _where: wherefilter };
    const query = stringify(where);
    const res = await Axios.get(`${api}/orderhs/get-total?${query}`, {
      withCredentials: true,
    });
    setTotalCount(res.data.count);
    setTotalNetAmt(res.data.totalAmt);
  };
  const downloadSummary = async () => {
    setLoading2(true);
    const fdate = isDateFilter ? filterOrderDate[0] : "";
    const tdate = isDateFilter ? filterOrderDate[1] : "";
    const res = await Axios.get(
      `${api}/orderhs/download-summary?fdate=${fdate}&tdate=${tdate}&status=${encodeURIComponent(
        statusFilter,
      )}`,
      {
        responseType: "blob",
        withCredentials: true,
      },
    );
    FileDownload(res.data, "summary.pdf");
    setLoading2(false);
    // console.log(res);
  };
  const downloadDetails = async () => {
    setLoading2(true);
    const fdate = isDateFilter ? filterOrderDate[0] : "";
    const tdate = isDateFilter ? filterOrderDate[1] : "";
    const res = await Axios.get(
      `${api}/orderhs/download-details?fdate=${fdate}&tdate=${tdate}&status=${encodeURIComponent(
        statusFilter,
      )}`,
      {
        responseType: "blob",
        withCredentials: true,
      },
    );
    FileDownload(res.data, "summary-details.pdf");
    setLoading2(false);
    // console.log(res);
  };
  return (
    <>
      <GoBackButton />
      <div style={{ marginTop: 20 }}>
        <div>
          {CBMRoles(role) && (
            <Box flexDirection="row" display="flex">
              <OrderDateRangeFilter
                setFilter={setFilterOrderDate}
                setIsFilter={setIsDateFilter}
                isFilter={isDateFilter}
                materialTableRef={tableRef}
              />
              <Stat
                p="15px"
                borderRadius="10px"
                bgColor="gray.800"
                color="white"
                margin="5px"
              >
                <StatLabel>Total Count</StatLabel>
                <StatNumber>{totalCount || "Loading..."}</StatNumber>
              </Stat>
              <Stat
                p="15px"
                borderRadius="10px"
                bgColor="gray.800"
                color="white"
                margin="5px"
              >
                <StatLabel>Total Amount</StatLabel>
                <StatNumber>
                  {totalNetAmt
                    ? `${parseFloat(totalNetAmt).toLocaleString("en-us", {
                        style: "currency",
                        currency: "LKR",
                      })}`
                    : "Loading..."}
                </StatNumber>
                (<span>Without voucher discounts</span>)
              </Stat>
              <Button
                colorScheme="blackAlpha"
                margin="5px"
                onClick={downloadSummary}
                whiteSpace="normal"
              >
                Download Summary
              </Button>
              <Button
                colorScheme="blackAlpha"
                margin="5px"
                whiteSpace="normal"
                onClick={downloadDetails}
              >
                Download Details
              </Button>
            </Box>
          )}
          <Box flexDirection="row" display="flex" flexWrap="wrap">
            <MaterialTableCustomFilterButton
              materialTableRef={tableRef}
              setFilter={setStatusFilter}
              filter={statusFilter}
              name="All"
              value=""
            />
            {statuses.map((status) => {
              return (
                <MaterialTableCustomFilterButton
                  materialTableRef={tableRef}
                  setFilter={setStatusFilter}
                  filter={statusFilter}
                  name={status}
                  value={status}
                />
              );
            })}
          </Box>
        </div>
        <MaterialTable
          title="Orders"
          tableRef={tableRef}
          columns={[
            { title: "Order ID", field: "id" },
            { title: "Order Code", field: "orderCode" },
            {
              title: "Order Date",
              field: "invoiceDate",
              type: "date",
            },
            {
              title: "Amount",
              field: "netAmt",
              type: "currency",
              currencySetting: { currencyCode: "LKR" },
            },
            {
              title: "Paid Amount",
              field: "paidAmount",
              type: "currency",
              currencySetting: { currencyCode: "LKR" },
            },
            { title: "User Email", field: "email" },
            { title: "Order Status", field: "status", filtering: false },
            {
              title: "Product Category",
              field: "productCategory",
              filtering: true,
            },
          ]}
          actions={[
            {
              icon: "more",
              tooltip: "View More",
              onClick: (event, rowData: any) => {
                handleViewClick(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            search: false,
            debounceInterval: 500,
            headerStyle: {
              zIndex: "unset",
            },
            pageSize: 10,
            pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
          }}
          onChangePage={(e, page) => {
            localStorage.setItem("pageQueryOrdersNew", e.toString());
            //@ts-ignore
            tableRef.current?.onQueryChange();
            // console.log(e);
            // console.log(page);
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              let filterObj: any = {};
              filterObj.status_in = [
                "4",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "18",
              ];
              query.filters.forEach((col) => {
                if (col.column.field === "netAmt") {
                  filterObj[col.column.field as string] = parseFloat(col.value);
                } else if (col.column.field === "paidAmount") {
                  filterObj[col.column.field as string] = parseFloat(col.value);
                } else if (col.column.field === "email") {
                  filterObj.user = { email_contains: col.value };
                  // } else if (col.column.field === "status") {
                  //   filterObj.status = { type_contains: col.value };
                  // } else if (col.column.field === "created_at") {
                  //   const date = JSON.stringify(col.value).substring(1, 11);
                  //   filterObj.created_at_gte = `${date} 00:00:00`;
                  //   filterObj.created_at_lte = `${date} 23:59:59`;
                } else if (col.column.field === "productCategory") {
                  filterObj.orderds = {
                    product: {
                      category: { categoryName_contains: col.value },
                    },
                  };
                } else if (col.column.field === "id") {
                  filterObj[col.column.field as string] = parseInt(col.value);
                } else {
                  filterObj[`${col.column.field as string}_contains`] =
                    col.value;
                }
              });
              if (isDateFilter) {
                if (filterOrderDate[0] != "")
                  filterObj.invoiceDate_gte = `${filterOrderDate[0]} 00:00:00`;
                if (filterOrderDate[1] != "")
                  filterObj.invoiceDate_lte = `${filterOrderDate[1]} 23:59:59`;
              }
              if (statusFilter != "")
                filterObj.status = { type_contains: statusFilter };
              getTotal(filterObj);
              const role = sessionStorage.getItem("role") as string;
              if (!CBCMRoles(role)) {
                resolve({
                  data: [],
                  page: 0,
                  totalCount: 0,
                });
              }

              refetch({
                where: {
                  ...filterObj,
                },
                sort: "created_at:desc",
                start:
                  parseInt(
                    localStorage.getItem("pageQueryOrdersNew") || (0 as any),
                  ) * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      orderhsConnection: { values, aggregate },
                    },
                  } = res;

                  const newData = values.map((item: any) => ({
                    id: item.id,
                    orderCode: item.orderCode,
                    invoiceDate: item.invoiceDate,
                    netAmt: item.netAmt,
                    paidAmount: item.paidAmount,
                    email: item.user.email,
                    status: item.status.type,
                    productCategory: [
                      ...new Set(
                        item.orderds
                          .filter((d: any) => d.product !== null)
                          .map((d: any) => d.product.category.categoryName),
                      ),
                    ].join(", "),
                  }));

                  resolve({
                    data: newData,
                    page: parseInt(
                      localStorage.getItem("pageQueryOrdersNew") || (0 as any),
                    ),
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
        <Loading2 loading={loading2} />
      </div>
    </>
  );
};

export default Orders;

const ORDERS_QUERY = gql`
  query ORDERS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    orderhsConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        created_at
        netAmt
        paidAmount
        orderCode
        invoiceDate
        status {
          id
          type
        }
        user {
          id
          email
        }
        orderds {
          id
          product {
            category {
              categoryName
            }
          }
        }
      }
    }
  }
`;
