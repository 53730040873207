import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useStores = () => {
  const { data, loading, error } = useQuery(STORES_QUERY);
  return {
    storeData: data,
    storeLoading: loading,
    storeError: error,
  };
};

export default useStores;

export const mapStores = (storeData: any) => {
  const mappedStores = storeData.storesConnection.values.map(
    (store: { storeName: string; __typename: string; id: number }) => ({
      id: store.id,
      label: store.storeName,
      value: store.storeName,
    })
  );
  return mappedStores;
};

export const STORES_QUERY = gql`
  query STORES_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    storesConnection(start: $start, limit: $limit, sort: $sort, where: $where) {
      aggregate {
        totalCount
      }
      values {
        id
        storeName
      }
    }
  }
`;
