import Axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../../config";
import { TableTitle } from "./FilterBox";

interface Props {
  fromDate: string;
  toDate: string;
  orderBy: string;
}

const PurchaseCount: React.FC<Props> = ({ fromDate, toDate, orderBy }) => {
  const history = useHistory();
  const [products, setProducts] = React.useState([]);
  const getProducts = async () => {
    const res = await Axios.get(
      `${api}/products/purchase-count?fromDate=${fromDate}&toDate=${toDate}&orderBy=${orderBy}`,
      {
        withCredentials: true,
      }
    );
    // console.log(res.data);
    setProducts(res.data);
  };
  React.useEffect(() => {
    getProducts();
  }, [fromDate, toDate, orderBy]);
  return (
    <>
      <div style={{ marginTop: 20, backgroundColor: "white" }}>
        <TableTitle
          title="Product Purchases analysis"
          backgroundColorIndex={1}
          ColorIndex={0}
        />
        <MaterialTable
          columns={[
            { title: "Product Name", field: "productName" },
            { title: "Total", field: "coalesce" },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            debounceInterval: 500,
            toolbar: false,
          }}
          data={products}
        />
      </div>
    </>
  );
};

export default PurchaseCount;
