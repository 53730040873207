import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import GoBackButton from "../components/Misc/GoBackButton";
import DeliveryLocations from "../components/Settings/DeliveryLocations";
import DeliveryOptions from "../components/Settings/DeliveryOptions";
import ShopStatus from "../components/Settings/ShopStatus";

interface Props {}

const SettingsPage: React.FC<Props> = () => {
  return (
    <>
      <GoBackButton />
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Box flex="2" mx="2" my="2">
          <DeliveryOptions />
        </Box>
        <Box flex="4" mx="2" my="2">
          <DeliveryLocations />
        </Box>
        <Box flex="1" mx="2" my="2">
          <ShopStatus />
        </Box>
      </Flex>
    </>
  );
};

export default SettingsPage;
