import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import CategoryForm from "../components/Category/CategoryForm";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";

interface Props {}

const CategoryFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/category-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(CATEGORY_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { category } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton mb="3" />
        <CategoryForm category={category} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton mb="3" />
        <CategoryForm />
      </div>
    );
  }
};

export default CategoryFormPage;

export const CATEGORY_QUERY = gql`
  query CATEGORY_QUERY($id: ID!) {
    category(id: $id) {
      id
      categoryName
      inactive
      image
      cat_type_tags {
        id
      }
      surcharge
    }
  }
`;
