import { Box } from "@chakra-ui/react";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import createPersistedState from "use-persisted-state";
import { api } from "../../../config";
import StatusFilterButton from "./StatusFilterButton";

export const useFilterState = createPersistedState("curFilter");

export type ProductStatusFilterTypes =
  | "ALL"
  | "LIVE"
  | "SOLD OUT"
  | "INACTIVE"
  | "SALE";

interface Props {}

const StatusFilters: React.FC<Props> = () => {
  const [curFilter, setCurFilter] =
    useFilterState<ProductStatusFilterTypes>("ALL");
  const [counts, setCounts] = useState({
    all: 0,
    live: 0,
    sold: 0,
    inactive: 0,
    sale: 0,
  });

  const handleClick = (text: ProductStatusFilterTypes) => {
    setCurFilter(text);
  };

  const fetchStatusCounts = async () => {
    const res = await Axios.get(`${api}/color-cats/status-counts`, {
      withCredentials: true,
    });
    setCounts(res.data);
  };

  useEffect(() => {
    fetchStatusCounts();
  }, []);

  return (
    <Box display="flex" boxShadow="md" py="3" px="3" bg="white">
      <StatusFilterButton
        text="ALL"
        handleClick={handleClick}
        curFilter={curFilter}
        count={counts.all}
      />
      <StatusFilterButton
        text="LIVE"
        handleClick={handleClick}
        curFilter={curFilter}
        count={counts.live}
      />
      <StatusFilterButton
        text="SOLD OUT"
        handleClick={handleClick}
        curFilter={curFilter}
        count={counts.sold}
      />
      <StatusFilterButton
        text="INACTIVE"
        handleClick={handleClick}
        curFilter={curFilter}
        count={counts.inactive}
      />
      <StatusFilterButton
        text="SALE"
        handleClick={handleClick}
        curFilter={curFilter}
        count={counts.sale}
      />
    </Box>
  );
};

export default StatusFilters;
