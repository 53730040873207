import { Box, chakra, Text } from "@chakra-ui/react";
import { Button, Grid } from "@material-ui/core";
import { Field, FieldArray } from "formik";
import React from "react";
import { apiLink } from "../../config";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import FileUpload from "../Misc/FormComponents/FileUpload";
import ReactSelectFormField from "../Misc/FormComponents/ReactSelectFormField";
import TextFormField from "../Misc/FormComponents/TextFormField";
const emptyColorCat = {
  colorCode: "",
  color: { id: "", colorName: "" },
  colorPrice: 0,
  colorDiscount: 0,
  colorStock: 0,
  img: "",
  color_images: [],
  warranty: { id: "", warranty: "" },
  material: "",
  closure_type: "",
  lockable: "",
  laptop_com: "",
  laptop_size: "",
  p_length: 0,
  width: 0,
  height: 0,
  weight: 0,
  inActive: false,
  featured: false,
  included: "",
};
interface Props {
  values: any;
  setFieldValue: any;
  colors: any;
  isEdit: boolean;
  warranties: any;
  isVoucher: boolean;
}

const ColorForm: React.FC<Props> = ({
  values,
  setFieldValue,
  colors,
  isEdit,
  warranties,
  isVoucher,
}) => {
  const handleImageDelete = (image: string, index: number) => {
    const prevImages = values.color_cats[index].color_images;
    const index2 = prevImages.indexOf(image);
    const newImages = [...prevImages];
    newImages.splice(index2, 1);
    setFieldValue(`color_cats[${index}].color_images`, newImages);
  };
  const handleChangeFeaturedColor = (index: number, e: any) => {
    for (let i = 0; i < values.color_cats.length; i++) {
      setFieldValue(`color_cats[${i}].featured`, false);
    }
    setFieldValue(`color_cats[${index}].featured`, e.target.checked);
  };
  return (
    <Box borderWidth="2px" marginTop="4px" padding="10px">
      <FieldArray
        name="color_cats"
        render={(arrayHelpers) => (
          <div>
            {values.color_cats && values.color_cats.length > 0 ? (
              values.color_cats.map((colorcat: any, index: number) => (
                <Box mt="2" key={index} id={`colorcat-${colorcat.id}`}>
                  <hr style={{ borderWidth: 3, marginTop: 10 }}></hr>
                  <Text mt="2" fontWeight="bold" fontSize="md">
                    {isVoucher ? `Voucher Details` : `Color Form #${index + 1}`}
                  </Text>
                  <Box
                    display="grid"
                    gridTemplateColumns={{ md: "1fr 1fr 1fr", sm: "1fr" }}
                    alignItems="center"
                    gridGap="1"
                  >
                    <Field
                      name={`color_cats[${index}].colorCode`}
                      component={TextFormField}
                      placeholder={isVoucher ? `Voucher code` : `Color Code`}
                      label={isVoucher ? `Voucher code` : `Color Code`}
                      variant="outlined"
                    />
                    {!isVoucher && (
                      <>
                        <Field
                          style={{ marginTop: 17 }}
                          name={`color_cats[${index}].color.id`}
                          placeholder="Color"
                          // label="Color"
                          component={ReactSelectFormField}
                          as="select"
                          options={colors.map((color: any) => ({
                            label: color.colorName,
                            value: color.id.toString(),
                          }))}
                          variant="outlined"
                        />
                        <Field
                          label="Main Thumbnail Color"
                          name={`color_cats[${index}].featured`}
                          defaultChecked={
                            isEdit
                              ? values.color_cats[index].featured === null
                                ? false
                                : values.color_cats[index].featured
                              : false
                          }
                          component={CheckboxFormField}
                          checked={values.color_cats[index].featured}
                          onChange={(e: any) => {
                            handleChangeFeaturedColor(index, e);
                          }}
                        />
                      </>
                    )}
                  </Box>

                  <Box
                    display="grid"
                    gridTemplateColumns={{ md: "1fr 1fr 1fr", sm: "1fr" }}
                    alignItems="center"
                    gridGap="1"
                  >
                    <Field
                      name={`color_cats[${index}].colorPrice`}
                      component={TextFormField}
                      label="Price"
                      placeholder="Price"
                      type="number"
                      variant="outlined"
                    />

                    <Field
                      name={`color_cats[${index}].colorDiscount`}
                      component={TextFormField}
                      label="Discount %"
                      placeholder="Discount %"
                      type="number"
                      variant="outlined"
                      hidden={isVoucher}
                      disabled
                    />
                    <Field
                      name={`color_cats[${index}].colorStock`}
                      component={TextFormField}
                      placeholder="Stock"
                      label="Stock"
                      type="number"
                      variant="outlined"
                    />
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns={{ md: "1fr 1fr 1fr", sm: "1fr" }}
                    alignItems="center"
                    gridGap="1"
                  >
                    {/* <Field
                      name={`color_cats[${index}].material`}
                      component={TextFormField}
                      label="Material"
                      placeholder="Material"
                      variant="outlined"
                      hidden={isVoucher}
                    /> */}
                    {!isVoucher && (
                      <Field
                        style={{ marginTop: 17 }}
                        name={`color_cats[${index}].warranty.id`}
                        placeholder="Warranty"
                        // label="Color"
                        component={ReactSelectFormField}
                        as="select"
                        options={warranties.map((warranty: any) => ({
                          label: warranty.label,
                          value: warranty.id.toString(),
                        }))}
                        variant="outlined"
                      />
                    )}
                    <Field
                      name={`color_cats[${index}].closure_type`}
                      component={TextFormField}
                      label="Closure Type"
                      placeholder="Closure Type"
                      variant="outlined"
                      hidden={isVoucher}
                    />
                    <Field
                      name={`color_cats[${index}].lockable`}
                      component={TextFormField}
                      placeholder="Lockable"
                      label="Lockable"
                      variant="outlined"
                      hidden={isVoucher}
                    />
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns={{ md: "1fr 1fr 1fr", sm: "1fr" }}
                    alignItems="center"
                    gridGap="1"
                  >
                    <Field
                      name={`color_cats[${index}].laptop_com`}
                      component={TextFormField}
                      label="Laptop Compartment"
                      placeholder="Laptop Compartment"
                      variant="outlined"
                      hidden={isVoucher}
                    />

                    <Field
                      name={`color_cats[${index}].laptop_size`}
                      component={TextFormField}
                      label="Laptop Compartment Size"
                      placeholder="Laptop Compartment Size"
                      variant="outlined"
                      hidden={isVoucher}
                    />
                    <Field
                      name={`color_cats[${index}].weight`}
                      component={TextFormField}
                      placeholder="Weight (kg)"
                      label="Weight (kg)"
                      variant="outlined"
                      type="number"
                      hidden={isVoucher}
                    />
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns={{ md: "1fr 1fr 1fr", sm: "1fr" }}
                    alignItems="center"
                    gridGap="1"
                  >
                    <Field
                      name={`color_cats[${index}].p_length`}
                      component={TextFormField}
                      label="Length"
                      placeholder="Length"
                      variant="outlined"
                      type="number"
                      hidden={isVoucher}
                    />

                    <Field
                      name={`color_cats[${index}].width`}
                      component={TextFormField}
                      label="Width"
                      placeholder="Width"
                      variant="outlined"
                      type="number"
                      hidden={isVoucher}
                    />
                    <Field
                      name={`color_cats[${index}].height`}
                      component={TextFormField}
                      placeholder="Height"
                      label="Height"
                      variant="outlined"
                      type="number"
                      hidden={isVoucher}
                    />
                  </Box>
                  <Field
                    name={`color_cats[${index}].included`}
                    component={TextFormField}
                    placeholder="What's Included"
                    label="What's Included (Please enter the list with comma seperated)"
                    variant="outlined"
                  />
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {colorcat.img && (
                        <div>
                          <h4>Current Main Image</h4>
                          <img
                            alt={`color_cats[${index}].img`}
                            height={200}
                            width={200}
                            src={`${apiLink}${colorcat.img}`}
                          />
                        </div>
                      )}
                      <FileUpload
                        handleSetFieldValue={setFieldValue}
                        handleFieldName={`color_cats[${index}].img`}
                        text={`Upload Main ${!isVoucher && `Color`} Image`}
                        folder="products"
                      />
                      {isEdit && (
                        <>
                          <chakra.h4 my="3">Current Images</chakra.h4>
                          <Box display="flex" flexWrap="wrap">
                            {colorcat.color_images?.map(
                              (img: any, index2: number) => (
                                <div className="mx-2">
                                  <button
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      handleImageDelete(img, index);
                                    }}
                                    className="float-right px-2 py-1 text-red-700 bg-red-200"
                                  >
                                    X
                                  </button>
                                  <img
                                    height={200}
                                    width={200}
                                    src={`${apiLink}${img.image}`}
                                    key={index2}
                                    alt={img.image}
                                  />
                                </div>
                              )
                            )}
                          </Box>
                        </>
                      )}
                      {!isVoucher && (
                        <FileUpload
                          folder="products"
                          colorCatIndex={index}
                          values={colorcat.color_images}
                          text="Upload More Color Images"
                          handleSetFieldValue={setFieldValue}
                          handleFieldName={`color_cats[${index}].color_images`}
                          multiple
                        />
                      )}
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify(values.color_cats[index], null, 2)}</pre> */}
                  <Field
                    label="Inactive"
                    name={`color_cats[${index}].inActive`}
                    defaultChecked={
                      isEdit
                        ? values.color_cats[index].inActive === null
                          ? false
                          : values.color_cats[index].inActive
                        : false
                    }
                    component={CheckboxFormField}
                  />
                  {/* <Button
                    type="button"
                    variant="contained"
                    onClick={() =>
                      values.color_cats.length > 1
                        ? arrayHelpers.remove(index)
                        : null
                    }
                    color="secondary"
                    style={{
                      fontWeight: "bold",
                      marginRight: 10,
                    }}
                  >
                    -
                  </Button> */}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      fontWeight: "bold",
                    }}
                    onClick={() =>
                      values.color_cats.length < (isVoucher ? 1 : 15)
                        ? arrayHelpers.insert(index + 1, emptyColorCat)
                        : null
                    }
                  >
                    Add Product Color
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      fontWeight: "bold",
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      if (values.color_cats.length < (isVoucher ? 1 : 15)) {
                        let clone = values.color_cats[index];
                        arrayHelpers.insert(index + 1, clone);
                        setFieldValue(
                          `color_cats[${index + 1}].featured`,
                          false
                        );
                        setFieldValue(`color_cats[${index + 1}].img`, "");
                        setFieldValue(
                          `color_cats[${index + 1}].color_images`,
                          []
                        );
                      }
                    }}
                  >
                    Clone Product Color
                  </Button>
                </Box>
              ))
            ) : (
              <Button type="button" onClick={() => arrayHelpers.push("")}>
                Add a new Color
              </Button>
            )}
          </div>
        )}
      />
    </Box>
  );
};

export default ColorForm;
