import ReactSelectFormField from "../Misc/FormComponents/ReactSelectFormField"
import { Field } from "formik"
import { useEffect, useState } from "react"
import axios from "axios"
import { api } from "../../config"

interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  values: any
  oldColorCats?: number[]
}

const ItemCodeSelection = ({ values, setFieldValue, oldColorCats }: Props) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    // TODO: This should be asynchoronous
    const fetchProducts = async () => {
      const { data } = await axios.get(`${api}/color-cats?_limit=1000`, {
        withCredentials: true
      })
      const options = data.map((colorCat: any) => ({
        label: `${colorCat.colorCode} - ${colorCat.product.productName}`,
        value: colorCat.id
      }))
      setOptions(options)
    }
    fetchProducts()
  }, [setFieldValue])

  return (
    <Field
      name="colorCats"
      component={ReactSelectFormField}
      label="Item Codes"
      fullWidth
      options={options.map((o: any) => ({
        ...o,
        isFixed: oldColorCats?.includes(o.value)
      }))}
      isMulti
      isClearable={false}
    />
  )
}

export default ItemCodeSelection
