import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useNewsletters = () => {
  const { data, loading, error } = useQuery(NEWSLETTERS_QUERY);
  return {
    newsletterData: data,
    newsletterLoading: loading,
    newsletterError: error,
  };
};

export default useNewsletters;

export const mapNewsletters = (newsletterData: any) => {
  const mappedNewsletters = newsletterData.newslettersConnection.values.map(
    (newsletter: { email: string; __typename: string; id: number }) => ({
      id: newsletter.id,
      label: newsletter.email,
    })
  );
  return mappedNewsletters;
};

export const NEWSLETTERS_QUERY = gql`
  query NEWSLETTERS_QUERY(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    newslettersConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        email
      }
    }
  }
`;
