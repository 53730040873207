import { useQuery } from "@apollo/client";
import { Box, Button } from "@chakra-ui/react";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { CATEGORIES_QUERY } from "../../customHooks/useCategories";
import MaterialTableCustomSort from "../Misc/MaterialTableCustomSort";
import useCategoryStyles from "./hooks/useCategoryStyles";

interface Props {}
const columns = [
  { name: "Category Name", id: "categoryName" },
  // { name: "Inactive", id: "inactive" },
];
const ViewCategories: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryCategory");
  const [sortQueryCategory, setSortQueryCategory] =
    useCounterState("categoryName:asc");
  const tableRef = React.useRef(null);

  const classes = useCategoryStyles();
  const history = useHistory();
  const { refetch } = useQuery(CATEGORIES_QUERY, {
    skip: true,
  });

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/category-form/${id}`,
    });
  };

  const handleAddClick = () => {
    history.push({
      pathname: "/admin/category-form",
    });
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Sub Category
      </Button>
      <div className={classes.table}>
        <MaterialTable
          tableRef={tableRef}
          title="Sub Categories"
          columns={[
            { title: "Name", field: "categoryName", sorting: false },
            { title: "Surcharge", field: "surcharge", sorting: false },
            // {
            //   title: "Inactive",
            //   field: "inactive",
            //   filtering: false,
            //   sorting: false,
            //   render: (rowData) =>
            //     rowData.inactive ? (
            //       <div
            //         style={{
            //           backgroundColor: "red",
            //           color: "#fff",
            //           textAlign: "center",
            //           maxWidth: 50,
            //         }}
            //       >
            //         True
            //       </div>
            //     ) : (
            //       <div
            //         style={{
            //           backgroundColor: "green",
            //           color: "#fff",
            //           textAlign: "center",
            //           maxWidth: 50,
            //         }}
            //       >
            //         False
            //       </div>
            //     ),
            // },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Category",
              onClick: (event, rowData: any) => {
                //@ts-ignore
                handleEditClick(rowData.id);
              },
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryCategory}
                  setSort={setSortQueryCategory}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  categoryName_contains: query.search,
                },
                sort: sortQueryCategory,
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      categoriesConnection: { values, aggregate },
                    },
                  } = res;
                  const newData = values.map((item: any) => ({
                    id: item.id,
                    categoryName: item.categoryName,
                    inactive: item.inactive,
                    surcharge: item.surcharge,
                  }));
                  resolve({
                    data: newData,
                    page: query.page, //- 1,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => {
                  console.log("error", err);
                });
            })
          }
        />
      </div>
    </>
  );
};

export default ViewCategories;
