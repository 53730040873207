import Axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { api } from "../../../config";
import { TableTitle } from "../ProductWise/FilterBox";

interface Props {}

const NullCustomers: React.FC<Props> = () => {
  const [customers, setCustomers] = React.useState([]);
  const getCustomers = async () => {
    const res = await Axios.get(`${api}/orderhs/null-customers`, {
      withCredentials: true,
    });
    // console.log(res.data);
    setCustomers(res.data);
  };
  React.useEffect(() => {
    getCustomers();
  }, []);
  return (
    <>
      <div style={{ marginTop: 20, backgroundColor: "white" }}>
        <TableTitle
          title="Customers without orders"
          backgroundColorIndex={0}
          ColorIndex={0}
        />
        <MaterialTable
          columns={[{ title: "Customer Email", field: "email" }]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            debounceInterval: 500,
            toolbar: false,
          }}
          data={customers}
        />
      </div>
    </>
  );
};

export default NullCustomers;
