import * as yup from "yup";
import checkLoggedIn from "../../utils/checkLoggedIn";

function removeDuplicateObjects(array: any[]) {
  //@ts-ignore
  return [...new Set(array.map((s: any) => JSON.stringify(s)))].map((s) =>
    JSON.parse(s)
  );
}

yup.addMethod(
  yup.array,
  "unique",
  function (message: any, mapper = (a: any) => a) {
    return this.test("unique", message, function (list) {
      list.forEach((item: any) => {
        delete item.price;
        delete item.stock;
      });
      //@ts-ignore
      return list.length === removeDuplicateObjects(list).length;
    });
  }
);

const prodValidationSchema = yup.object({
  productCode: yup.string().required("Product Code is required"),
  serialNo: yup.string().required("Serial No is required"),
  productName: yup.string().required("Product Name is required"),
  productDescription: yup.string().required("Product Description is required"),
  brand: yup.number().required("Brand is required"),
  category: yup.number().required("Category is required"),
  // subcategory: yup.number().required("Sub Category is required"),
  //@ts-ignore
  // productattributes: yup.array().unique("Duplicate Attribute", (a) => a),
  // specs: yup.array().of(
  //   yup.object().shape({
  //     spec: yup.string().required("Spec is required"),
  //   })
  // ),
});

export default prodValidationSchema;

export function validateAttributes(productattributes: any) {
  for (const attrRow of productattributes as any) {
    let noneCount = 0;
    for (const key of Object.keys(attrRow)) {
      if (["price", "stock", "discount"].includes(key)) {
        if (isNaN(parseFloat(attrRow[key]))) {
          alert("Please only enter number for price, stock and discount");
          return false;
        }
      } else {
        if (
          attrRow[key] === "undefined|undefined" ||
          attrRow[key] === "|" ||
          !attrRow[key]
        ) {
          alert("Don't keep blank drop down values in product attriubtes");
          return false;
        }

        const rightSplit = attrRow[key].split("|")[1];
        if (rightSplit === "None") noneCount++;
      }
    }
    if (noneCount === Object.keys(attrRow).length - 2) {
      alert("Don't keep None values for all the drop downs");
      return false;
    }
  }
  return true;
}
