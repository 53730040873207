import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import MoneyIcon from "@material-ui/icons/Money";
import ReceiptIcon from "@material-ui/icons/Receipt";
import StorageIcon from "@material-ui/icons/Storage";
import UpdateIcon from "@material-ui/icons/Update";
import React from "react";

export type SummaryData = {
  color: string;
  icon: React.ReactNode;
  title: string;
  metaData: string;
  objName: string;
  footerIcon: React.ReactNode;
  footerTitle: string;
};

const summaryData: SummaryData[] = [
  {
    color: "success",
    icon: <MoneyIcon />,
    title: "Today's Revenue",
    metaData: "Rs. ",
    objName: "revenue",
    footerIcon: <DateRange />,
    footerTitle: "Today",
  },
  {
    color: "info",
    icon: <ReceiptIcon />,
    title: "No of Orders Today",
    metaData: "",
    objName: "ordersCount",
    footerIcon: <UpdateIcon />,
    footerTitle: "Today",
  },
  {
    color: "warning",
    icon: <StorageIcon />,
    title: "This Month Revenue",
    metaData: "Rs. ",
    objName: "monthRevenue",
    footerIcon: <LocalOffer />,
    footerTitle: "Month",
  },
];

export default summaryData;
