import Axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { api } from "../../../config";
import { TableTitle } from "../ProductWise/FilterBox";

interface Props {
  fromDate: string;
  toDate: string;
  orderBy: string;
}

const TopCustomersCount: React.FC<Props> = ({ fromDate, toDate, orderBy }) => {
  const [customers, setCustomers] = React.useState([]);
  const getCustomers = async () => {
    const res = await Axios.get(
      `${api}/orderhs/top-customer-count?fromDate=${fromDate}&toDate=${toDate}&orderBy=${orderBy}`,
      {
        withCredentials: true,
      }
    );
    // console.log(res.data);
    setCustomers(res.data);
  };
  React.useEffect(() => {
    getCustomers();
  }, [fromDate, toDate, orderBy]);
  return (
    <>
      <div style={{ marginTop: 20, backgroundColor: "white" }}>
        <TableTitle
          title="Top 10 Customer purchase Count"
          backgroundColorIndex={2}
          ColorIndex={0}
        />
        <MaterialTable
          columns={[
            { title: "Customer Email", field: "email" },
            { title: "Total", field: "count" },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            debounceInterval: 500,
            toolbar: false,
          }}
          data={customers}
        />
      </div>
    </>
  );
};

export default TopCustomersCount;
