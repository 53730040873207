import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import ContactForm from "../components/Contact/ContactForm";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";

interface Props {}

const ContactFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/contact-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(CONTACT_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { contact } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <ContactForm contact={contact} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
      </div>
    );
  }
};

export default ContactFormPage;

export const CONTACT_QUERY = gql`
  query CONTACT_QUERY($id: ID!) {
    contact(id: $id) {
      id
      name
      email
      phone
      message
      created_at
    }
  }
`;
