import { useApolloClient, useMutation } from "@apollo/client";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import useNewsletterStyles from "./hooks/useNewsletterStyles";

// form validations
const schema = yup.object({
  email: yup.string().required("Email is Required"),
});

interface Props {
  newsletter?: {
    id: string;
    email: string;
  };
}

const NewsletterForm: React.FC<Props> = ({ newsletter }) => {
  const isEdit = typeof newsletter !== "undefined";
  const history = useHistory();
  const client = useApolloClient();
  const { displayPopMessage } = useMessageState();
  const [loading, setLoading] = useState<boolean>(false);

  const [submitForm] = useMutation(
    isEdit ? UPDATE_NEWSLETTER_MUTATION : CREATE_NEWSLETTER_MUTATION
  );

  const classes = useNewsletterStyles({});

  const handleSubmit = async (values: any) => {
    const data = {
      email: values.email,
    };
    try {
      setLoading(true);
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: newsletter?.id } }),
            data,
          },
        }, // pass data
      });
      setLoading(false);
      displayPopMessage(
        `Newsletter ${!isEdit ? "Added" : "Updated"} Succefully`
      );

      history.goBack();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                email: isEdit ? newsletter?.email : "",
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} <mark></mark> Newsletter
                  </h3>
                  <Field
                    name="email"
                    component={TextFormField}
                    label="Email"
                    fullWidth
                    variant="outlined"
                    autoFocus
                  />

                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" mb="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default NewsletterForm;

export const CREATE_NEWSLETTER_MUTATION = gql`
  mutation CREATE_NEWSLETTER_MUTATION($input: createNewsletterInput) {
    createNewsletter(input: $input) {
      newsletter {
        email
      }
    }
  }
`;

export const UPDATE_NEWSLETTER_MUTATION = gql`
  mutation UPDATE_NEWSLETTER_MUTATION($input: updateNewsletterInput) {
    updateNewsletter(input: $input) {
      newsletter {
        email
      }
    }
  }
`;
