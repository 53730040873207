import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useColors = () => {
  const { data, loading, error } = useQuery(COLORS_QUERY);
  return {
    colorData: data,
    colorLoading: loading,
    colorError: error,
  };
};

export default useColors;

export const mapColors = (colorData: any) => {
  const mappedColors = colorData.colorsConnection.values.map(
    (color: { colorName: string; __typename: string; id: number }) => ({
      id: color.id,
      label: color.colorName,
      value: color.colorName,
    })
  );
  return mappedColors;
};

export const COLORS_QUERY = gql`
  query COLORS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    colorsConnection(start: $start, limit: $limit, sort: $sort, where: $where) {
      aggregate {
        totalCount
      }
      values {
        id
        colorName
        colorHex
      }
    }
  }
`;
