import { Button } from "@chakra-ui/react";
import React from "react";

interface Props {
  name: string;
  value: string;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  materialTableRef: any;
}

const MaterialTableCustomFilterButton: React.FC<Props> = ({
  setFilter,
  name,
  filter,
  value,
  materialTableRef,
}) => {
  const handleFilterCol = () => {
    setFilter(value);
    // console.log(materialTableRef);
    //@ts-ignore
    if (materialTableRef.current) materialTableRef.current.onQueryChange();
  };

  return (
    <Button
      margin="10px"
      onClick={handleFilterCol}
      bgColor={filter == value ? "gray.700" : "gray.200"}
      color={filter == value ? "white" : "black"}
    >
      {name}
    </Button>
  );
};

export default MaterialTableCustomFilterButton;
