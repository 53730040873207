import { useQuery } from "@apollo/client";
import { Box, Button } from "@chakra-ui/react";
import AddIcon from "@material-ui/icons/Add";
import Axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { apiLink } from "../../config";
import MaterialTableCustomSort from "../Misc/MaterialTableCustomSort";
import { WEBCONTENT_QUERY } from "./hooks/useWebContent";

interface Props {}
const columns = [
  {
    id: "type",
    name: "Type Name",
  },
];
const ViewWebContent: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryWarranty");
  const [sortQueryWarranty, setSortQueryWarranty] = useCounterState("type:asc");
  const tableRef = React.useRef(null);

  const history = useHistory();
  const { refetch } = useQuery(WEBCONTENT_QUERY, {
    skip: true,
  });

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/web-content-form/${id}`,
    });
  };

  const handleAddClick = () => {
    history.push({
      pathname: "/admin/web-content-form",
    });
  };
  const handleDeleteClick = async (row: any) => {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return;
    }
    // console.log(row);
    await Axios.delete(`${apiLink}/web-contents/${row.id}`, {
      withCredentials: true,
    });
    //@ts-ignore
    tableRef.current?.onQueryChange();
  };
  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Content
      </Button>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Web Content"
          columns={[
            {
              title: "Image",
              field: "image",
              filtering: false,
              export: false,
              render: (rowData) => (
                <img
                  style={{
                    height: 80,
                    width: 80,
                    objectFit: "contain",
                  }}
                  alt={rowData.link}
                  src={`${apiLink}${rowData.image}`}
                />
              ),
            },
            // { title: "Title", field: "title", sorting: false },
            { title: "Type", field: "type", sorting: false },
            // { title: "Image", field: "image", sorting: false },
            { title: "Link", field: "link", sorting: false },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Web Content",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
            {
              icon: "delete",
              tooltip: "Delete Web Content",
              onClick: (event, rowData: any) => {
                handleDeleteClick(rowData);
              },
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryWarranty}
                  setSort={setSortQueryWarranty}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  title_contains: query.search,
                },
                sort: sortQueryWarranty,
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      webContentsConnection: { values, aggregate },
                    },
                  } = res;
                  const newData = values.map((item: any) => ({
                    id: item.id,
                    title: item.title,
                    type: item.type,
                    image: item.image,
                    link: item.link,
                    linkText: item.linkText,
                    isLeft: item.isLeft,
                    color: item.color,
                  }));
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewWebContent;
