import { useQuery } from "@apollo/client";
import { Box, Switch } from "@chakra-ui/react";
import Axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { api } from "../../../config";
import { COLOR_CATS_QUERY } from "../../../customHooks/useProducts";
import MaterialTableCustomSort from "../../Misc/MaterialTableCustomSort";
import { ProductStatusFilterTypes, useFilterState } from "./StatusFilters";
interface Props {}
const columns = [
  // { name: "Product Name", id: "color_cats.product.productName" },
  { name: "Color Code", id: "colorCode" },
  { name: "Price", id: "colorPrice" },
  { name: "Stock", id: "colorStock" },
  {
    name: "Inactive",
    id: "inActive",
  },
];
const ViewProducts: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryProduct");
  const [sortQueryProduct, setSortQueryProduct] =
    useCounterState("colorCode:asc");
  const tableRef = React.useRef(null);

  const history = useHistory();
  const { refetch } = useQuery(COLOR_CATS_QUERY, {
    skip: true,
  });
  const [curFilter] = useFilterState<ProductStatusFilterTypes>();

  const handleEditClick = (productId: string, colorCatId: number) => {
    history.push({
      // pathname: `/admin/product-form?id=${productId}&scrollTo=colorcat-${colorCatId}`,
      pathname: `/admin/product-form/${productId}/${colorCatId}`,
    });
  };

  const handleInactive = async (
    id: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target.value === "false" ? true : false;
    const res = await Axios.put(
      `${api}/color-cats/${id}`,
      { inActive: val },
      { withCredentials: true }
    );
    //@ts-ignore
    tableRef.current?.onQueryChange();
  };

  React.useEffect(() => {
    //@ts-ignore
    tableRef.current?.onQueryChange();
  }, [curFilter]);

  return (
    <div>
      <div style={{ marginTop: 20 }}>
        <MaterialTable
          tableRef={tableRef}
          title="Products"
          columns={[
            {
              title: "Image",
              field: "image",
              filtering: false,
              export: false,
              render: (rowData) => (
                <img
                  style={{
                    height: 80,
                    width: 80,
                  }}
                  alt={rowData.colorCode}
                  src={rowData.image}
                />
              ),
            },
            { title: "Product Name", field: "productName", sorting: false },
            { title: "Material", field: "material", sorting: false },
            { title: "Color Code", field: "colorCode", sorting: false },
            {
              title: "Price",
              field: "colorPrice",
              filtering: false,
              sorting: false,
            },
            {
              title: "Available",
              field: "colorStock",
              filtering: false,
              sorting: false,
            },
            {
              title: "Inactive",
              field: "inActive",
              filtering: false,
              render: (rowData: any) => (
                <Switch
                  colorScheme="red"
                  size="lg"
                  isChecked={rowData.inActive}
                  value={rowData.inActive}
                  onChange={(e) => handleInactive(rowData.id, e)}
                />
              ),
              sorting: false,
            },
            {
              title: "Color",
              field: "color",
              hidden: true,
              export: true,
            },
            {
              title: "Discount",
              field: "colorDiscount",
              hidden: true,
              export: true,
            },
            {
              title: "Lockable",
              field: "lockable",
              hidden: true,
              export: true,
            },
            {
              title: "Closure type",
              field: "closure_type",
              hidden: true,
              export: true,
            },
            {
              title: "Laptop Compartment",
              field: "laptop_com",
              hidden: true,
              export: true,
            },
            {
              title: "Laptop Size",
              field: "laptop_size",
              hidden: true,
              export: true,
            },
            {
              title: "Warranty",
              field: "warranty",
              hidden: true,
              export: true,
            },
            {
              title: "Length",
              field: "length",
              hidden: true,
              export: true,
            },
            {
              title: "Width",
              field: "width",
              hidden: true,
              export: true,
            },
            {
              title: "Height",
              field: "height",
              hidden: true,
              export: true,
            },
            {
              title: "Weight",
              field: "weight",
              hidden: true,
              export: true,
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryProduct}
                  setSort={setSortQueryProduct}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            ),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Product",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.productId, rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            search: false,
            padding: "dense",
            exportButton: true,
            pageSizeOptions: [10, 50, 100, 1000],
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          onChangePage={(e, page) => {
            localStorage.setItem("pageQueryProductNew", e.toString());
            // refetch();
            //@ts-ignore
            tableRef.current?.onQueryChange();
            console.log(e);
            console.log(page);
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              // console.log("down", query.page);
              // console.log(query.search);

              let filterObj: any = {};

              query.filters.forEach((col) => {
                if (col.column.field === "price") {
                  filterObj[col.column.field as string] = parseFloat(col.value);
                } else if (col.column.field === "email") {
                  filterObj.user = { email_contains: col.value };
                } else if (col.column.field === "productName") {
                  filterObj.product = {
                    productName_contains: col.value,
                  };
                } else if (col.column.field === "material") {
                  filterObj.product = {
                    "material.name_contains": col.value,
                  };
                } else {
                  filterObj[`${col.column.field as string}_contains`] =
                    col.value;
                }
              });
              refetch({
                where: {
                  ...filterObj,
                  ...(curFilter === "SOLD OUT" && {
                    colorStock_lte: 0,
                  }),
                  ...(curFilter === "INACTIVE" && {
                    inActive: true,
                  }),
                  ...(curFilter === "LIVE" && {
                    colorStock_gt: 0,
                    inActive: false,
                  }),
                  ...(curFilter === "SALE" && {
                    colorDiscount_gt: 0,
                  }),
                },
                start:
                  parseInt(
                    localStorage.getItem("pageQueryProductNew") || (0 as any),
                  ) * query.pageSize,
                limit: query.pageSize,
                sort: sortQueryProduct,
              })
                .then((res) => {
                  // console.log(res);
                  const {
                    data: {
                      colorCatsConnection: {
                        values,
                        aggregate: { totalCount },
                      },
                    },
                  } = res;
                  const newData = values.map((item: any) => {
                    const thumbnail = item.img.split("/");
                    return {
                      id: item.id,
                      colorCode: item.colorCode,
                      productName: item.product.productName,
                      material: item.product.material.name,
                      productId: item.product.id,
                      colorPrice: item.colorPrice,
                      image: `${api}/${thumbnail[1]}/thumbnail_${thumbnail[2]}`,
                      colorStock: item.colorStock,
                      inActive: item.inActive,
                      colorDiscount: item.colorDiscount,
                      color: item.color.colorName,
                      lockable: item.lockable,
                      closure_type: item.closure_type,
                      laptop_com: item.laptop_com,
                      laptop_size: item.laptop_size,
                      warranty: item.warranty.warranty,
                      length: item.p_length,
                      width: item.width,
                      height: item.height,
                      weight: item.weight,
                    };
                  });
                  resolve({
                    data: newData,
                    page: parseInt(
                      localStorage.getItem("pageQueryProductNew") || (0 as any),
                    ),
                    totalCount: totalCount,
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        />
      </div>
    </div>
  );
};

export default ViewProducts;
