import { useApolloClient, useMutation } from "@apollo/client";
import {
  Box,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import deleteCache from "../../utils/deleteCache";
import { CBRoles } from "../../utils/misc";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import useVoucherStyles from "./hooks/useVoucherStyles";

// form validations
const schema = yup.object({
  // voucherName: yup.string().required("Voucher Name is Required"),
  email: yup.string().email().required("Please enter a valid email address"),
  price: yup.number().when("isPercentage", {
    is: false,
    then: yup.number().required("Price is required"),
  }),
  percentage: yup.number().when("isPercentage", {
    is: true,
    then: yup.number().required("Percentage is required"),
  }),
  validMonth: yup.number().required("Please select a valid month"),
});
interface user {
  id: string;
  username: string;
}
interface voucherHistory {
  desp: string;
  users_permissions_user?: user;
  created_at: string;
}
interface Props {
  voucher?: {
    id: string;
    code: string;
    active: boolean;
    price: boolean;
    orderh: string;
    returnorderh: string;
    validMonth: number;
    email: string;
    updated_at: string;
    percentage: number;
    isPercentage: boolean;
    voucherhistories?: voucherHistory[];
  };
}

const VoucherForm: React.FC<Props> = ({ voucher }) => {
  const isEdit = typeof voucher !== "undefined";
  const history = useHistory();
  const client = useApolloClient();
  const { displayPopMessage } = useMessageState();
  const [loading, setLoading] = useState<boolean>(false);
  const role = sessionStorage.getItem("role");

  const [submitForm] = useMutation(
    isEdit ? UPDATE_VOUCHER_MUTATION : CREATE_VOUCHER_MUTATION
  );

  const classes = useVoucherStyles({});

  const handleSubmit = async (values: any) => {
    const data = {
      // code: values.code,
      active: values.active,
      price: values.price,
      // orderh: isEdit ? voucher?.orderh : null,
      // returnorderh: isEdit ? voucher?.returnorderh : null,
      validMonth: values.validMonth,
      email: values.email,
      percentage: values.percentage,
      isPercentage: values.isPercentage,
    };

    try {
      setLoading(true);
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: voucher?.id } }),
            data,
          },
        }, // pass data
      });
      setLoading(false);
      displayPopMessage(`Voucher ${!isEdit ? "Added" : "Updated"} Succefully`);

      if (isEdit) {
        const regex = RegExp(`^Voucher:${voucher?.id}`);
        deleteCache(regex, client);
      }

      history.push({
        pathname: "/admin/vouchers",
      });
    } catch (err) {
      console.log(err);
      displayPopMessage(`Error Occured!!! && Maybe you're not allowed`);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    //@ts-ignore
    console.log(new Date(voucher?.updated_at));
  }, [voucher]);
  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                code: isEdit
                  ? voucher?.code
                  : "Code will be generated after submit",
                active: isEdit ? voucher?.active : false,
                price: isEdit
                  ? voucher?.price == null
                    ? 0
                    : voucher?.price
                  : 0,
                validMonth: isEdit ? voucher?.validMonth : 0,
                email: isEdit ? voucher?.email : "",
                //@ts-ignore
                updated_at: isEdit
                  ? JSON.stringify(voucher?.updated_at).substring(1, 11)
                  : JSON.stringify(new Date(Date.now())).substring(1, 11),
                percentage: isEdit
                  ? voucher?.percentage == null
                    ? 0
                    : voucher?.percentage
                  : 0,
                isPercentage: isEdit
                  ? voucher?.isPercentage == true
                    ? true
                    : false
                  : false,
              }}
              validationSchema={schema}
            >
              {({ values }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} <mark></mark> Voucher
                  </h3>
                  {/* {JSON.stringify(values)} */}
                  <Field
                    name="code"
                    component={TextFormField}
                    label="Voucher Code"
                    fullWidth
                    variant="outlined"
                    autoFocus
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Field
                    name="updated_at"
                    component={TextFormField}
                    label="Voucher Effective Date (last updated date)"
                    fullWidth
                    variant="outlined"
                    autoFocus
                    type="date"
                    InputProps={{
                      readOnly: true,
                    }}
                  />

                  <Field
                    name="validMonth"
                    component={TextFormField}
                    label="No. of valid months from effective date"
                    fullWidth
                    variant="outlined"
                    type="number"
                  />
                  <Field
                    name="isPercentage"
                    component={CheckboxFormField}
                    defaultChecked={isEdit ? voucher?.isPercentage : false}
                    label="Set as percentage"
                  />
                  {values.isPercentage ? (
                    <Field
                      name="percentage"
                      component={TextFormField}
                      label="Percentage"
                      fullWidth
                      variant="outlined"
                      type="number"
                    />
                  ) : (
                    <Field
                      name="price"
                      component={TextFormField}
                      label="Price"
                      fullWidth
                      variant="outlined"
                      type="number"
                    />
                  )}
                  <Field
                    name="email"
                    component={TextFormField}
                    label="Email"
                    fullWidth
                    variant="outlined"
                    type="email"
                    InputProps={{
                      readOnly: isEdit,
                    }}
                  />
                  <Field
                    name="active"
                    component={CheckboxFormField}
                    defaultChecked={isEdit ? voucher?.active : false}
                    label="Active"
                  />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" mb="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
        {CBRoles(role) && isEdit && (
          <Box my="10">
            <TableContainer>
              <Table variant="simple">
                <TableCaption placement="top">Voucher History</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>User</Th>
                    <Th>Description</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {voucher?.voucherhistories?.map((vh) => (
                    <Tr>
                      <Td>{JSON.stringify(vh.created_at).substring(1, 11)}</Td>
                      <Td>{vh.users_permissions_user?.username}</Td>
                      <Td>{vh.desp}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default VoucherForm;

export const CREATE_VOUCHER_MUTATION = gql`
  mutation CREATE_VOUCHER_MUTATION($input: createVoucherInput) {
    createVoucher(input: $input) {
      voucher {
        id
        active
      }
    }
  }
`;

export const UPDATE_VOUCHER_MUTATION = gql`
  mutation UPDATE_VOUCHER_MUTATION($input: updateVoucherInput) {
    updateVoucher(input: $input) {
      voucher {
        id
        active
      }
    }
  }
`;
