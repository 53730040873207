import React from "react";
import ViewReviews from "../components/Review/ViewReview";

interface Props {}

const ReviewsPage: React.FC<Props> = () => {
  return <ViewReviews />;
};

export default ReviewsPage;
