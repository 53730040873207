import { useApolloClient, useMutation } from "@apollo/client";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import useColorStyles from "./hooks/useColorStyles";

// form validations
const schema = yup.object({
  colorName: yup.string().required("Color Name is Required"),
  colorHex: yup.string().required("Color is Required"),
});

interface Props {
  color?: {
    id: string;
    colorName: string;
    colorHex: string;
    isTwoTone: boolean;
    colorHex2: string;
    // inactive: boolean;
  };
}

const ColorForm: React.FC<Props> = ({ color }) => {
  const isEdit = typeof color !== "undefined";
  const history = useHistory();
  const client = useApolloClient();
  const { displayPopMessage } = useMessageState();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState(
    isEdit ? color?.colorHex : "#ffffff"
  );
  const [selectedColor2, setSelectedColor2] = useState(
    isEdit ? (color?.isTwoTone ? color?.colorHex2 : "#ffffff") : "#ffffff"
  );
  const [submitForm] = useMutation(
    isEdit ? UPDATE_COLOR_MUTATION : CREATE_COLOR_MUTATION
  );

  const classes = useColorStyles({});

  const handleSubmit = async (values: any) => {
    const data = {
      colorName: values.colorName,
      colorHex: values.colorHex,
      inactive: values.inactive,
      isTwoTone: values.isTwoTone,
      colorHex2: values.isTwoTone ? values.colorHex2 : "",
    };
    try {
      setLoading(true);
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: color?.id } }),
            data,
          },
        }, // pass data
      });
      setLoading(false);
      displayPopMessage(`Color ${!isEdit ? "Added" : "Updated"} Succefully`);

      // if (isEdit) {
      //   const regex = RegExp(`^Color:${color?.id}`);
      //   deleteCache(regex, client);
      // }
      history.goBack();
      // history.push({
      //   pathname: "/admin/colors",
      // });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                colorName: isEdit ? color?.colorName : "",
                colorHex: isEdit ? color?.colorHex : "#ffffff",
                isTwoTone: isEdit ? color?.isTwoTone : false,
                colorHex2: isEdit ? color?.colorHex2 : "#ffffff",
                // inactive: isEdit ? color?.inactive : false,
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} <mark></mark> Color Name
                  </h3>
                  <Field
                    name="colorName"
                    component={TextFormField}
                    label="Color Name"
                    fullWidth
                    variant="outlined"
                    autoFocus
                  />
                  <Field
                    name="colorHex"
                    component={TextFormField}
                    label="Color Hex"
                    fullWidth
                    variant="outlined"
                    autoFocus
                    hidden
                  />
                  <Container
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  >
                    <SketchPicker
                      onChange={(hex) => {
                        setFieldValue("colorHex", hex.hex);
                        setSelectedColor(hex.hex);
                      }}
                      color={selectedColor}
                    />
                  </Container>
                  <Field
                    name="isTwoTone"
                    component={CheckboxFormField}
                    defaultChecked={isEdit ? color?.isTwoTone : false}
                    label="isTwoTone"
                  />
                  {values.isTwoTone && (
                    <>
                      <Field
                        name="colorHex2"
                        component={TextFormField}
                        label="Color Hex 2"
                        fullWidth
                        variant="outlined"
                        autoFocus
                        hidden
                      />
                      <Container
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          marginTop: 30,
                          marginBottom: 30,
                        }}
                      >
                        <SketchPicker
                          onChange={(hex) => {
                            setFieldValue("colorHex2", hex.hex);
                            setSelectedColor2(hex.hex);
                          }}
                          color={selectedColor2}
                        />
                      </Container>
                    </>
                  )}
                  {/* <Field
                    name="inactive"
                    component={CheckboxFormField}
                    defaultChecked={isEdit ? color?.inactive : false}
                    label="Inactive"
                  /> */}
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" mb="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default ColorForm;

export const CREATE_COLOR_MUTATION = gql`
  mutation CREATE_COLOR_MUTATION($input: createColorInput) {
    createColor(input: $input) {
      color {
        colorName
        colorHex
        isTwoTone
        colorHex2
      }
    }
  }
`;

export const UPDATE_COLOR_MUTATION = gql`
  mutation UPDATE_COLOR_MUTATION($input: updateColorInput) {
    updateColor(input: $input) {
      color {
        colorName
        colorHex
        isTwoTone
        colorHex2
      }
    }
  }
`;
