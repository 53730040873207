import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import SortIcon from "@material-ui/icons/Sort";
import React from "react";

interface Props {
  sort: string;
  setSort: React.Dispatch<React.SetStateAction<string>>;
  materialTableRef: any;
  columns: { id: string; name: string }[];
}

const MaterialTableCustomSort: React.FC<Props> = ({
  setSort,
  sort,
  materialTableRef,
  columns,
}) => {
  const handleSortCol = (val: any, param: number) => {
    const sortParam = sort.split(":");
    if (param == 1) {
      setSort(`${val}:${sortParam[1]}`);
    } else if (param == 2) {
      setSort(`${sortParam[0]}:${val}`);
    }
    //@ts-ignore
    materialTableRef.current.onQueryChange();
  };
  const defaultColumn = sort.split(":")[0];
  const defaultType = sort.split(":")[1];
  return (
    <Box margin="10px">
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          boxShadow="md"
          color={"gray.600"}
          size="sm"
          textTransform="uppercase"
          leftIcon={<SortIcon />}
        >
          Sort By
        </MenuButton>
        <MenuList minWidth="240px" boxShadow="2xl">
          <MenuOptionGroup
            // defaultValue="desc"
            defaultValue={defaultType}
            title="Order"
            type="radio"
            onChange={(e) => handleSortCol(e, 2)}
          >
            <MenuItemOption value="asc">Ascending</MenuItemOption>
            <MenuItemOption value="desc">Descending</MenuItemOption>
          </MenuOptionGroup>
          <MenuDivider />
          <MenuOptionGroup
            // defaultValue="created_at:"
            defaultValue={defaultColumn}
            title="Column"
            type="radio"
            onChange={(e) => handleSortCol(e, 1)}
          >
            {columns.map((item) => {
              return (
                <MenuItemOption value={item.id}>{item.name}</MenuItemOption>
              );
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default MaterialTableCustomSort;
