import { makeStyles } from "@material-ui/core";
import React from "react";
import Card from "../Template/Card/Card";
import CardFooter from "../Template/Card/CardFooter";
import CardHeader from "../Template/Card/CardHeader";
import CardIcon from "../Template/Card/CardIcon";
import GridContainer from "../Template/Grid/GridContainer";
import GridItem from "../Template/Grid/GridItem";
import summaryData, { SummaryData } from "./data/summaryData";
import summaryStyles from "./styles/summaryStyles";

//@ts-ignore
const useStyles = makeStyles(summaryStyles);
type Props = {
  data: any;
};

const Summary: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <GridContainer>
      {summaryData.map((item: SummaryData, index: number) => (
        <GridItem xs={12} sm={4} md={4} key={index}>
          <Card>
            <CardHeader color={item.color} stats icon>
              <CardIcon color={item.color}>{item.icon}</CardIcon>
              <p className={classes.cardCategory}>{item.title}</p>
              <h3 className={classes.cardTitle}>
                {item.metaData} {data[item.objName]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                {item.footerIcon}
                {item.footerTitle}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      ))}
    </GridContainer>
  );
};

export default Summary;
