export const cloudApi = "https://api.pgmartin.lk";

export const api =
  process.env.NODE_ENV === "development" ? "http://localhost:1337" : cloudApi;
// export const api = cloudApi;
export const recaptchaKey =
  process.env.NODE_ENV === "development"
    ? "6Lcn6OsbAAAAAENInrkvkXTd_EKaB7g5j_wwTKAG"
    : "6Lcn6OsbAAAAAENInrkvkXTd_EKaB7g5j_wwTKAG";
    
export const apiLink = process.env.NODE_ENV === "development" ? api : cloudApi;
