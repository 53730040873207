import { useApolloClient } from "@apollo/client";
import { Box, Heading } from "@chakra-ui/react";
import { Grid, Link } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import logo from "../../assets/img/pg_logo.jpg";
import { api, cloudApi, recaptchaKey } from "../../config";
import FormError from "../Misc/FormComponents/FormError";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import useLoginStyles from "./hooks/useLoginStyles";

const schema = yup.object().shape({
  email: yup.string().required("email is required"),
  password: yup.string().required("Password is required"),
});

interface Props {}
const LoginForm: React.FC<Props> = () => {
  const classes = useLoginStyles({});
  const history = useHistory();
  const client = useApolloClient();
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const recaptchaRef = React.useRef();
  const [open, setOpen] = React.useState(false);

  const handleSubmit = async (values: any) => {
    setSubmitLoading(true);
    //@ts-ignore
    const token = await recaptchaRef.current.getValue();
    if (!token) {
      setErrMsg("Please check I'm not a robot");
      setSubmitLoading(false);
      return;
    }
    try {
      const res = await axios({
        url: `${process.env.NODE_ENV === "development" ? api : cloudApi}`,
        method: "POST",
        withCredentials: true,
        data: {
          query: `
            mutation LOGIN {
              login(input: {identifier: "${values.email}", password: "${values.password}", reCaptcha: "${token}" }) {
                user {
                  username
                  email
                  role {
                    name
                  }
                }
              }
            }
          `,
        },
      });

      const data = res.data.data.login;

      const role = data.user.role.name;
      const username = data.user.username;

      sessionStorage.setItem("role", role);
      sessionStorage.setItem("username", username);

      setSubmitLoading(false);

      await client.cache.reset();

      history.push("/");
    } catch (err) {
      setSubmitLoading(false);
      //@ts-ignore
      await recaptchaRef.current.reset();
      console.log((err as any).message);
      setSubmitLoading(false);
      setErrMsg("Email or Password is Invalid");
      setTimeout(() => {
        setErrMsg("");
      }, 5000);
    }
  };
  const handleRecaptchaError = (err: any) => {
    console.log({ captchaErr: err });
    setErrMsg("No Internet For Recaptcha");
    setSubmitLoading(false);
  };

  React.useEffect(() => {
    //@ts-ignore
    recaptchaRef.current.reset();
  }, []);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <CssBaseline />
        <img src={logo} alt="company logo" className={classes.logo} />
        <div className={classes.paper}>
          {/* <Typography variant="button" className={classes.heading}>
            P.G.Martin Admin Panel
          </Typography>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Heading>Admin Panel - LOGIN</Heading>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={schema}
          >
            {({ isValid }) => (
              <Form>
                <Field
                  name="email"
                  label="Email/Username"
                  component={TextFormField}
                  fullWidth
                  variant="outlined"
                  autoFocus
                />
                <Field
                  name="password"
                  label="Password"
                  type="password"
                  component={TextFormField}
                  fullWidth
                  variant="outlined"
                />
                <FormError message={errMsg} style={{ textAlign: "center" }} />
                <Box display="flex" justifyContent="center">
                  <Box>
                    <ReCAPTCHA
                      sitekey={recaptchaKey}
                      size="normal"
                      ref={recaptchaRef as any}
                      onErrored={handleRecaptchaError as any}
                    />
                  </Box>
                </Box>
                <SubmitBtn
                  loading={submitLoading}
                  btnText="SIGN IN"
                  w="100%"
                  disabled={!isValid || submitLoading}
                />
                <Grid container>
                  <Grid item xs>
                    <Link
                      href="#"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
        <ForgotPasswordDialog setOpen={setOpen} open={open} />
      </Paper>
    </div>
  );
};
export default LoginForm;
