//@ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//@ts-ignore
import CKEditor from "@ckeditor/ckeditor5-react";
import axios from "axios";
import React from "react";
import { api } from "../../../config";
import "../../../content-styles.css"

interface Props {
  setText: React.Dispatch<React.SetStateAction<string>>;
  text?: string;
  isImage?: boolean;
}

const CKTextEditor: React.FC<Props> = ({
  setText,
  text = "",
  isImage = false,
}) => {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={text}
        config={{
          removePlugins: isImage
            ? []
            : [
                "MediaEmbed",
                "Image",
                "EasyImage",
                "ImageToolbar",
                "ImageCaption",
                "ImageStyle",
                "ImageUpload",
              ],
          extraPlugins: [MyCustomUploadAdapterPlugin],
          mediaEmbed: {
            previewsInData: true,
          },
        }}
        //
        onInit={(editor: any) => {
          // You can store the "editor" and use when it is needed.
          // console.log("Editor is ready to use!", editor);
          editor.setData(text);
        }}
        onChange={(event: any, editor: { getData: () => any }) => {
          const data = editor.getData();
          setText(data);
          // console.log({ event, editor, data });
        }}
        onBlur={(event: any, editor: any) => {
          // console.log("Blur.", editor);
        }}
        onFocus={(event: any, editor: any) => {
          // console.log("Focus.", editor);
        }}
      />
    </>
  );
};

class MyUploadAdapter {
  public loader: any;

  constructor(loader: any) {
    this.loader = loader;
  }

  async upload() {
    const file = await this.loader.file;
    // console.log(file);

    const formData = new FormData();
    formData.append("upload", file);

    return axios
      .post(`${api}/blogs/upload-content-images`, formData, {
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const loaded = progressEvent.loaded;
          this.loader.uploadTotal = total;
          this.loader.uploaded = loaded;
        },
      })
      .then((response) => {
        console.log("from new adapter", response.data.url);
        return {
          default: response.data.url,
        };
      })
      .catch((error) => {
        console.log("from new adapter");
        throw error.response
          ? error.response.data.error.message
          : "Couldn't upload the file.";
      });
  }

  abort() {
    // Implement abort logic using Axios if necessary
  }
}

function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return new MyUploadAdapter(loader);
  };
}
export default CKTextEditor;
