import { makeStyles } from "@material-ui/core";
import DateRange from "@material-ui/icons/DateRange";
import MoneyIcon from "@material-ui/icons/Money";
import Axios from "axios";
import * as React from "react";
import { api } from "../../../config";
import Card from "../../Template/Card/Card";
import CardFooter from "../../Template/Card/CardFooter";
import CardHeader from "../../Template/Card/CardHeader";
import CardIcon from "../../Template/Card/CardIcon";
import GridContainer from "../../Template/Grid/GridContainer";
import GridItem from "../../Template/Grid/GridItem";
import { TableTitle } from "../ProductWise/FilterBox";
import summaryStyles2 from "../styles/summaryStyles2";
interface Props {}

const useStyles = makeStyles(summaryStyles2);

const OrdersForecast: React.FC<Props> = () => {
  const classes = useStyles();
  const [forecastData, setForecastData] = React.useState({ count: 0, sum: 0 });

  const getForecastData = async () => {
    const res = await Axios.get(`${api}/orderhs/orders-forecast`, {
      withCredentials: true,
    });

    setForecastData(res.data);
    // console.log(res.data);
  };
  React.useEffect(() => {
    getForecastData();
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: 20,
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <TableTitle title="Forecast" backgroundColorIndex={0} ColorIndex={0} />
        <div
          style={{
            padding: "20px",
          }}
        >
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} key={1}>
              <Card className={classes.box}>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <MoneyIcon />
                  </CardIcon>
                  <p className={classes.cardCategory}>Orders Forecast</p>
                  <h3 className={classes.cardTitle}>{forecastData.count}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Next 30 days
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} key={1}>
              <Card className={classes.box}>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <MoneyIcon />
                  </CardIcon>
                  <p className={classes.cardCategory}>Sales Forecast</p>
                  <h3 className={classes.cardTitle}>{forecastData.sum}</h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Next 30 days
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </>
  );
};

export default OrdersForecast;
