import { useQuery } from "@apollo/client";
import { Box, Button } from "@chakra-ui/react";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import FileDownload from "js-file-download";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { api } from "../../config";
import { datetimestampToString } from "../../utils/misc";
import OrderDateRangeFilter from "../Dashboard/OrderDateRangeFilter";
import MaterialTableCustomSort from "../Misc/MaterialTableCustomSort";
import { DISCOUNTS_QUERY } from "../Product/hooks/useDiscounts";

interface Props {}
const columns = [
  { id: "id", name: "ID" },
  {
    id: "name",
    name: "Discount Name",
  },
  {
    id: "isActive",
    name: "Is Activated",
  },
  {
    id: "start",
    name: "Start Date",
  },
  {
    id: "end",
    name: "End Date",
  },
  {
    id: "isValue",
    name: "Is Value",
  },
  {
    id: "value",
    name: "Value",
  },
  {
    id: "type",
    name: "Type",
  },
];
const ViewDiscounts: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryDiscount");
  const [sortQueryDiscount, setSortQueryDiscount] = useCounterState("name:asc");
  const [filterOrderDate, setFilterOrderDate] = React.useState([""]);
  const [isDateFilter, setIsDateFilter] = React.useState(false);
  const tableRef = React.useRef(null);

  const history = useHistory();
  const { refetch } = useQuery(DISCOUNTS_QUERY, {
    skip: true,
  });

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/Discount-form/${id}`,
    });
  };

  const handleAddClick = () => {
    history.push({
      pathname: "/admin/Discount-form",
    });
  };

  const downloadReport = async () => {
    const query = isDateFilter
      ? `?start_gte=${new Date(filterOrderDate[0])}&start_lte=${new Date(
          filterOrderDate[1]
        )}`
      : "";
    const res = await axios.get(`${api}/discounts/download-report${query}`, {
      withCredentials: true,
      responseType: "blob",
    });
    FileDownload(res.data, "discount-report.pdf");
  };

  const downloadDiscountOrdersReport = async () => {
    const query = isDateFilter
      ? `?created_at_gte=${new Date(
          filterOrderDate[0]
        )}&created_at_lte=${new Date(filterOrderDate[1])}`
      : "";
    const res = await axios.get(
      `${api}/discounts/download-orders-report${query}`,
      {
        withCredentials: true,
        responseType: "blob",
      }
    );
    FileDownload(res.data, "discount-orders-report.pdf");
  };

  return (
    <>
      <Box flexDirection="row" display="flex" justifyContent="space-between">
        <Button
          colorScheme="blue"
          onClick={handleAddClick}
          className="add-btn"
          m="3"
          size="sm"
          leftIcon={<AddIcon />}
          textTransform="uppercase"
        >
          Add Discount
        </Button>
        <Box
          flexDir="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box>Select a date range and download the report</Box>
          <Box display="flex" alignItems="center">
            <OrderDateRangeFilter
              setFilter={setFilterOrderDate}
              setIsFilter={setIsDateFilter}
              isFilter={isDateFilter}
              // materialTableRef={null}
              BtnPreText="Report "
            />
            <Button
              colorScheme="blue"
              onClick={downloadDiscountOrdersReport}
              className="add-btn"
              m="3"
              size="sm"
              textTransform="uppercase"
            >
              Download Report
            </Button>
          </Box>
        </Box>
      </Box>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Discount"
          columns={columns.map((col) => ({
            title: col.name,
            field: col.id
          }))}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryDiscount}
                  setSort={setSortQueryDiscount}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            )
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Discount",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id)
              }
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset"
            }
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                // where: {
                //   start_gte: isDateFilter
                //     ? new Date(filterOrderDate[0])
                //     : undefined,
                //   start_lte: isDateFilter
                //     ? new Date(filterOrderDate[1])
                //     : undefined,
                // },
                sort: sortQueryDiscount,
                start: query.page * query.pageSize,
                limit: query.pageSize
              })
                .then((res) => {
                  const {
                    data: {
                      discountsConnection: { values, aggregate }
                    }
                  } = res
                  const newData = values.map((item: any) => {
                    return {
                      id: item.id,
                      name: item.name,
                      start: datetimestampToString(item.start),
                      end: datetimestampToString(item.end),
                      isValue: item.isValue,
                      value: item.value,
                      type: item.type,
                      isActive: item.isActive ? "Yes" : "No"
                    }
                  })
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount
                  })
                })
                .catch((err) => console.log(err))
            })
          }
        />
      </div>
    </>
  )
};

export default ViewDiscounts;
