import { Input } from "@chakra-ui/input";
import { Box, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import * as React from "react";
import { api } from "../../config";
import useReviewStyles from "./hooks/useReviewStyles";

interface Props {
  review: {
    id: string;
    review: string;
    product: {
      id: string;
      productName: string;
    };
    rating: number;
    published_at: string;
    users_permissions_user: {
      id: string;
      username: string;
    };
    orderh: {
      id: string;
      orderCode: string;
    };
    created_at: string;
    updated_at: string;
  };
}

const ReviewForm: React.FC<Props> = ({ review }) => {
  const classes = useReviewStyles({});
  const createdat = new Date(review?.updated_at);
  const [active, setActive] = React.useState(
    review?.published_at === null ? false : true
  );
  const handleActive = async () => {
    try {
      setActive(!active);
      const published_at = !active ? new Date() : null;
      await axios.put(
        `${api}/reviews/${review.id}`,
        { published_at },
        { withCredentials: true }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Box className={classes.form}>
              <h3 className={classes.heading}>Review</h3>
              <Text marginTop="10" fontSize="16">
                Product Name
              </Text>
              <Input
                placeholder="Product Name"
                variant="outline"
                value={review?.product?.productName}
                readOnly
              />
              <Text marginTop="10" fontSize="16">
                Last Updated Date and time
              </Text>
              <Input
                placeholder="Date"
                variant="outline"
                value={
                  createdat.getFullYear() +
                  "-" +
                  createdat.getMonth() +
                  "-" +
                  createdat.getDate() +
                  "     " +
                  createdat.getHours() +
                  ":" +
                  createdat.getMinutes()
                }
                readOnly
              />
              <Text marginTop="10" fontSize="16">
                Reviewed by
              </Text>
              <Input
                placeholder="User"
                variant="outline"
                value={review?.users_permissions_user?.username}
                readOnly
              />
              <Text marginTop="10" fontSize="16">
                Order Code
              </Text>
              <Input
                placeholder="Order Code"
                variant="outline"
                value={review?.orderh?.orderCode}
                readOnly
              />
              <Text marginTop="10" fontSize="16">
                Review
              </Text>
              <Textarea
                placeholder="Review"
                variant="outline"
                value={review?.review}
                readOnly
                marginBottom={5}
              />
              <Button marginBottom={20} onClick={handleActive}>
                {active ? "Active" : "Disabled"}
              </Button>
            </Box>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default ReviewForm;
