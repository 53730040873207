import React from "react";
import ViewCategories from "../components/Category/ViewCategories";

interface Props {}

const CategoriesPage: React.FC<Props> = () => {
  return <ViewCategories />;
};

export default CategoriesPage;
