import { Box } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import createPersistedState from "use-persisted-state";
import { api } from "../../config";

interface Props {}
const ViewCustomers: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryContact");
  const [sortQueryContact, setSortQueryContact] =
    useCounterState("created_at:desc");

  const tableRef = React.useRef(null);

  const fetchCustomers = async ({ start, limit, search }: any) => {
    const results = await axios.get(
      `${api}/customers/list-all?_start=${start}&_limit=${limit}&_q=${search}`,
      {
        withCredentials: true,
      },
    );
    return results.data;
  };

  return (
    <>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Customers"
          columns={[
            { title: "Name", field: "name", sorting: false },
            {
              title: "Email",
              field: "email",
              sorting: false,
            },
            { title: "Phone", field: "phone", sorting: false },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                {/* <MaterialTableCustomSort
                  sort={sortQueryContact}
                  setSort={setSortQueryContact}
                  materialTableRef={tableRef}
                  columns={columns}
                /> */}
              </Box>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            exportButton: true,
            pageSize: 10,
            pageSizeOptions: [10, 50, 100, 1000],
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              fetchCustomers({
                start: query.page * query.pageSize,
                limit: query.pageSize,
                search: query.search,
              })
                .then((values) => {
                  const newData = values.data.map((item: any) => ({
                    name: item.firstName + " " + item.lastName,
                    email: item.email,
                    phone: item.tele1.substring(1),
                  }));
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: values.count,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewCustomers;
