import {
  Box,
  Heading,
  Stack,
  useRadio,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { default as Axios, default as axios } from "axios";
import React, { useEffect, useState } from "react";
import { api, apiLink, cloudApi } from "../../config";
import Loading from "../Misc/Loading";

interface Props {}

const ShopStatus: React.FC<Props> = () => {
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);

  const getStatus = async () => {
    const res = await Axios.get(`${apiLink}/stores/is-open`, {
      withCredentials: true,
    });

    // console.log(res.data);
    setStatus(res.data);
    setLoading(false);
  };

  useEffect(() => {
    getStatus();
  }, []);

  if (loading) return <Loading />;

  return <Radio defaultValue={status} />;
};

export default ShopStatus;

interface RadioProps {
  defaultValue: string;
}

const Radio: React.FC<RadioProps> = ({ defaultValue }) => {
  const toast = useToast();
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "status",
    defaultValue: defaultValue,
    onChange: (val: string) => handleChange(val),
  });

  const options = ["Open", "Close"];
  const group = getRootProps();

  const handleChange = async (val: string) => {
    const link = process.env.NODE_ENV === "development" ? api : cloudApi;
    const isOpen = val === "Open" ? true : false;
    try {
      await axios.post(
        `${link}/stores/update-open-status`,
        { isOpen },
        {
          withCredentials: true,
        }
      );
      toast({
        title: "Status Updated",
        status: "success",
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Failed",
        description: err.response.data.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="gray.50" shadow="md" w="100%" p="5">
      <Heading fontSize="3xl" mb="4">
        Shop Status
      </Heading>

      <Stack direction="column" defaultValue={"Open"} {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value } as any);
          return (
            <RadioStatus key={value} {...radio}>
              {value}
            </RadioStatus>
          );
        })}
      </Stack>
    </Box>
  );
};

const RadioStatus = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: props.children === "Open" ? "teal.600" : "red.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        fontSize="md"
        display="flex"
        alignItems="center"
      >
        <Box mx="2">
          {props.children === "Open" ? <LockOpenIcon /> : <LockIcon />}
        </Box>
        {props.children}
      </Box>
    </Box>
  );
};
