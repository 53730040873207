import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import DiscountForm from "../components/Discount/DiscountForm";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";

interface Props {}

const DiscountFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/discount-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(DISCOUNT_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { discount } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <DiscountForm discount={discount} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <DiscountForm />
      </div>
    );
  }
};

export default DiscountFormPage;

export const DISCOUNT_QUERY = gql`
  query DISCOUNT_QUERY($id: ID!) {
    discount(id: $id) {
      id
      name
      start
      end
      isValue
      value
      type
      products {
        id
        productName
      }
      colorCats {
        id
        colorCode
        product {
          id
          productName
        }
      }
    }
  }
`
