import { gql } from "@apollo/client";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AllRoles } from "./misc";

type Props = {
  component: any;
};

export const ProtectedRoute: React.FC<RouteProps & Props> = ({
  component: Component,
  path,
  ...rest
}) => {
  const role = sessionStorage.getItem("role");

  return (
    <Route
      {...rest}
      render={(props) => {
        if (role !== null && AllRoles(role)) {
          return <Component {...props} />;
        } else {
          return <Redirect to={{ pathname: "/login" }} />;
        }
      }}
    />
  );
};

const ME_QUERY = gql`
  query ME_QUERY {
    me {
      username
      role {
        name
      }
    }
  }
`;
