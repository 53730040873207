import { Heading } from "@chakra-ui/react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import { api, cloudApi } from "../../config";
import { Product } from "../../customHooks/useProduct";
import { CBRoles } from "../../utils/misc";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import CKTextEditor from "../Misc/FormComponents/CKTextEditor";
import ReactSelectFormField from "../Misc/FormComponents/ReactSelectFormField";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import Loading from "../Misc/Loading";
import ColorForm from "./ColorForm";
import useBrands, { mapBrands } from "./hooks/useBrands";
import useProductStyles from "./hooks/useProductStyles";
import useProductSubmit from "./hooks/useProductSubmit";
import useStores, { mapStores } from "./hooks/useStores";
import useWarranties, { mapWarranties } from "./hooks/useWarranties";
import prodValidationSchema from "./ProdValidationSchema";
const url = process.env.NODE_ENV === "development" ? api : cloudApi;

interface Props {
  product?: Product;
  nextCode?: string;
}

const ProductForm: React.FC<Props> = ({ product, nextCode }) => {
  const matches = useMediaQuery("(max-width:700px)");
  const match = useRouteMatch("/admin/product-form/:id/:colorid");

  const isEdit = typeof product !== "undefined";
  const prefix = `${sessionStorage.getItem("storeId")}T`;

  const role = sessionStorage.getItem("role");

  const classes = useProductStyles({});
  const { warrantyData, warrantyError, warrantyLoading } = useWarranties();
  const { brandData, brandError, brandLoading } = useBrands();
  const { storeData, storeError, storeLoading } = useStores();
  const {
    handleSubmit,
    setImages,
    setMainImage,
    setSpec,
    handleImageDelete,
    images,
  } = useProductSubmit(isEdit, product);

  const [colors, setColors] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);
  const [warranties, setWarranties] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [catLoading, setCatLoading] = React.useState(true);
  const [isVoucher, setIsVoucher] = React.useState(false);

  const link = process.env.NODE_ENV === "development" ? api : cloudApi;
  React.useEffect(() => {
    console.log(product);
    if (product?.isVoucher) {
      setIsVoucher(true);
    }
  }, [product]);
  const getMaterials = async () => {
    const res = await axios.get(`${link}/materials`, {
      withCredentials: true,
    });
    setMaterials(res.data);
  };
  const getColors = async () => {
    const res = await axios.get(`${link}/colors`, {
      withCredentials: true,
    });
    setColors(res.data);
  };
  const getCategories = async () => {
    const role = sessionStorage.getItem("role");
    let storeId;
    if (CBRoles(role)) {
      storeId = 1;
    } else {
      storeId = sessionStorage.getItem("storeId");
    }
    const res = await axios.get(`${link}/categories?_sort=categoryName:asc`, {
      withCredentials: true,
    });

    const mappedCategories = res.data.map((cat: any) => {
      return {
        id: cat.id,
        label: cat.categoryName,
        value: cat.categoryName,
      };
    });
    // setCategories(mappedCategories);
    if (role === "ShopOwner") {
      await getStoreCat(mappedCategories);
    } else {
      setCategories(mappedCategories);
    }

    setCatLoading(false);
  };

  const getStoreCat = async (mappedCategories: any) => {
    const categoryList: any = [];
    const role = sessionStorage.getItem("role");
    let storeId;
    if (CBRoles(role)) {
      storeId = 1;
    } else {
      storeId = sessionStorage.getItem("storeId");
    }
    const res = await axios.get(`${url}/storecats/?store=${storeId}`, {
      withCredentials: true,
    });
    // console.log(res.data);
    if (mappedCategories != null) {
      mappedCategories.map((cate: any) => {
        if (res.data.some((item: any) => item.category.id == cate.id)) {
          let obj = {
            id: cate.id,
            label: cate.label,
            value: cate.label,
          };
          categoryList.push(obj);
        }
      });
    }

    setCategories(categoryList);
    // setCategoriesList(categoryList);
  };
  const getWarranties = async () => {
    if (warrantyData) setWarranties(mapWarranties(warrantyData));
  };

  React.useEffect(() => {
    getCategories();
    getColors();
    getWarranties();
    getMaterials();
    console.log(product);
    // console.log(product);
    // console.log(warrantyData);
  }, [warrantyData]);

  // scroll to the color cat form in edit mode
  React.useEffect(() => {
    if (isEdit && !catLoading && !brandLoading && !storeLoading) {
      const colorid = (match?.params as any).colorid;
      document.getElementById(`colorcat-${colorid}`)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [catLoading, brandLoading, storeLoading]);

  // zog(newProduct);
  if (catLoading || brandLoading || storeLoading) return <Loading />;
  if (brandError || storeError) {
    console.log({ storeError });
    console.log({ brandError });
    return <div>Error</div>;
  }

  // const categories = mapCategories(categoryData);
  const brands = mapBrands(brandData);
  let stores = mapStores(storeData);
  if (role === "ShopOwner") {
    stores = stores.filter(
      (st: any) => st.id === sessionStorage.getItem("storeId")
    );
  } else {
    stores = stores.filter((st: any) => parseInt(st.id) === 1);
  }
  const onChangeIsVoucher = (
    setFieldValueFun: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void,
    e: any
  ) => {
    setIsVoucher(e.target.checked);
    const col = [
      {
        colorCode: "voucher",
        color: { id: "1", colorName: "" },
        colorPrice: 0,
        colorDiscount: 0,
        colorStock: 0,
        img: "",
        color_images: [{ id: "", image: "" }],
        warranty: { id: "1", warranty: "" },
        material: "",
        closure_type: "",
        lockable: "",
        laptop_com: "",
        laptop_size: "",
        p_length: 0,
        width: 0,
        height: 0,
        weight: 0,
        inActive: false,
        featured: true,
        included: "",
      },
    ];
    setFieldValueFun("color_cats", col);
    setFieldValueFun("material", "1");
    setFieldValueFun("brand", "5");
    setFieldValueFun("store", "1");
    setFieldValueFun("category", "18");
    setFieldValueFun("isVoucher", e.target.checked);
  };
  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values: any) => handleSubmit(values)}
              initialValues={{
                productCode: isEdit ? product?.productCode : nextCode,
                serialNo: isEdit ? product?.serialNo : nextCode,
                productName: isEdit ? product?.productName : "",
                material: isEdit ? product?.material?.id : "",
                brand: isEdit ? product?.brand?.id : "1",
                category: isEdit ? product?.category.id : "",
                // warranty: isEdit ? product?.warranty?.id : "",
                // subcategory: isEdit ? product?.subcategory?.id : "",
                inactive: isEdit
                  ? product?.inactive === null
                    ? false
                    : product?.inactive
                  : false,
                isVoucher: isEdit ? product?.isVoucher : false,
                featuredItem: isEdit ? product?.featuredItem : false,
                productDescription: isEdit ? product?.productDescription : "",
                store: isEdit ? product?.store?.id : "1",
                color_cats: isEdit
                  ? product?.color_cats
                  : [
                      {
                        colorCode: "",
                        color: { id: "", colorName: "" },
                        colorPrice: "",
                        colorDiscount: 0,
                        colorStock: 0,
                        img: "",
                        color_images: [],
                        warranty: { id: "", warranty: "" },
                        material: "",
                        closure_type: "",
                        lockable: "",
                        laptop_com: "",
                        laptop_size: "",
                        p_length: 0,
                        width: 0,
                        height: 0,
                        weight: 0,
                        inActive: false,
                        featured: false,
                        included: "",
                      },
                    ],
              }}
              validationSchema={prodValidationSchema}
              validateOnChange={false}
              // validateOnBlur={false}
            >
              {({ values, errors, isValid, validateForm, setFieldValue }) => (
                <Form>
                  <Grid>
                    <h3 className={classes.heading}>
                      {isEdit ? "Edit Product" : "Add Product"}
                    </h3>
                    {/* {JSON.stringify(values, null, 2)} */}
                    <Grid container spacing={3}>
                      <Grid item md={4} xs={12}>
                        <Field
                          label="Product Code"
                          name="productCode"
                          component={TextFormField}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={8} xs={12}>
                        <Field
                          label="Serial No"
                          name="serialNo"
                          component={TextFormField}
                          variant="outlined"
                          placeholder={role === "ShopOwner" ? `${prefix}` : ""}
                          fullWidth
                          autoFocus
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={8} xs={12}>
                        <Field
                          label="Product Name"
                          name="productName"
                          component={TextFormField}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      {!isVoucher && (
                        <Grid item md={4} xs={12}>
                          <Field
                            name={`material`}
                            component={ReactSelectFormField}
                            label="Material"
                            placeholder="Material"
                            options={materials.map((material: any) => ({
                              label: material.name,
                              value: material.id.toString(),
                            }))}
                            hidden={isVoucher}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <Field
                          label="Voucher"
                          name="isVoucher"
                          defaultChecked={
                            isEdit ? product?.isVoucher : isVoucher
                          }
                          onChange={(e: any) =>
                            onChangeIsVoucher(setFieldValue, e)
                          }
                          component={CheckboxFormField}
                        />
                      </Grid>
                      {!isVoucher && (
                        <Grid item md={6} xs={12}>
                          <Field
                            label="Store"
                            name="store"
                            component={ReactSelectFormField}
                            options={stores.map((store: any) => ({
                              label: store.label,
                              value: store.id,
                            }))}
                            placeholder="Stores"
                            // customOnChange={handleBrandChange}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {!isVoucher && (
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          <Field
                            label="Categories"
                            name="category"
                            component={ReactSelectFormField}
                            options={categories.map((category: any) => ({
                              label: category.label,
                              value: category.id.toString(),
                            }))}
                            placeholder="Categories"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            label="Brand"
                            name="brand"
                            component={ReactSelectFormField}
                            options={brands.map((brand: any) => ({
                              label: brand.label,
                              value: brand.id,
                            }))}
                            placeholder="Brands"
                            // customOnChange={handleBrandChange}
                          />
                          {/* <Field
                          label="Warranties"
                          name="warranty"
                          component={ReactSelectFormField}
                          options={warranties.map((war: any) => ({
                            label: war.label,
                            value: war.id.toString(),
                          }))}
                          placeholder="Warranties"
                        /> */}
                        </Grid>
                      </Grid>
                    )}

                    {/* <pre>{JSON.stringify(values.productattributes, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    <Field
                      label="Product Description"
                      name="productDescription"
                      component={TextFormField}
                      multiline
                      variant="outlined"
                    />
                    {!isVoucher && (
                      <Grid item xs={12}>
                        <div style={{ maxWidth: matches ? 200 : 700 }}>
                          <Heading
                            fontSize="md"
                            mt="3"
                            mb="1"
                            textTransform="uppercase"
                          >
                            Item Spec
                          </Heading>
                          <CKTextEditor
                            text={product?.spec}
                            setText={setSpec as any}
                          />
                        </div>
                      </Grid>
                    )}
                    <Field
                      label="Inactive"
                      name="inactive"
                      defaultChecked={
                        isEdit
                          ? product?.inactive === null
                            ? false
                            : product?.inactive
                          : false
                      }
                      component={CheckboxFormField}
                    />
                    <Field
                      label="Featured Item"
                      name="featuredItem"
                      defaultChecked={isEdit ? product?.featuredItem : false}
                      component={CheckboxFormField}
                    />
                    {/* <div style={{ width: 100 }}>
                      <pre>{JSON.stringify(values, null, 2)}</pre>
                    </div> */}
                    <ColorForm
                      colors={colors}
                      values={values}
                      isEdit={isEdit}
                      setFieldValue={setFieldValue}
                      warranties={warranties}
                      isVoucher={isVoucher}
                    />

                    <SubmitBtn
                      style={{ marginTop: 10 }}
                      loading={false}
                      disabled={!isValid}
                      w="100%"
                      mb="3"
                    />
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default ProductForm;
