import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import ProductForm from "../components/Product/ProductForm";
import { LAST_CODE_QUERY, PRODUCT_QUERY } from "../customHooks/useProduct";

interface Props {}

const ProductFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/product-form/:id/:colorid");

  // const { params } = useParams();
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
    //@ts-ignore
    // id = params.id;
  }

  const { data, loading, error } = useQuery(PRODUCT_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
  });
  const {
    data: lastCodeData,
    loading: lastCodeDataLoading,
    error: lastCodeError,
  } = useQuery(LAST_CODE_QUERY, {
    skip: id !== null,
  });

  if (error || lastCodeError) return <div>Error</div>;
  if (loading || lastCodeDataLoading) return <Loading />;

  if (id) {
    const { product } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <ProductForm product={product} />
      </div>
    );
  } else {
    const { getLastProductCode } = lastCodeData;
    return (
      <div>
        <GoBackButton />
        <ProductForm nextCode={getLastProductCode} />
      </div>
    );
  }
};

export default ProductFormPage;

export const CATEGORY_QUERY = gql`
  query CATEGORY_QUERY($id: ID!) {
    category(id: $id) {
      id
      categoryName
      inactive
    }
  }
`;
