import { gql, useQuery } from "@apollo/client";
import { Box, Button } from "@chakra-ui/react";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import MaterialTableCustomSort from "../Misc/MaterialTableCustomSort";

const WARRANTIES_QUERY = gql`
  query WARRANTIES_QUERY(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    warrantiesConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        warranty
      }
    }
  }
`;

interface Props {}
const columns = [
  {
    id: "warranty",
    name: "Warranty Name",
  },
];
const ViewWarranties: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryWarranty");
  const [sortQueryWarranty, setSortQueryWarranty] =
    useCounterState("warranty:asc");
  const tableRef = React.useRef(null);

  const history = useHistory();
  const { refetch } = useQuery(WARRANTIES_QUERY, {
    skip: true,
  });

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/warrenty-form/${id}`,
    });
  };

  const handleAddClick = () => {
    history.push({
      pathname: "/admin/warrenty-form",
    });
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Warranty
      </Button>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Warranty"
          columns={[{ title: "Warranty", field: "warranty", sorting: false }]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Warranty",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryWarranty}
                  setSort={setSortQueryWarranty}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  warranty_contains: query.search,
                },
                sort: sortQueryWarranty,
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      warrantiesConnection: { values, aggregate },
                    },
                  } = res;
                  const newData = values.map((item: any) => ({
                    id: item.id,
                    warranty: item.warranty,
                  }));
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewWarranties;
