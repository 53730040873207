import { gql } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import LoginForm from "../components/LoginPage";
import { AllRoles } from "../utils/misc";

interface Props {}

const Login: React.FC<Props> = () => {
  const history = useHistory();
  const role = sessionStorage.getItem("role");

  if (role !== null && AllRoles(role)) {
    history.push("/");
    return <></>;
  }

  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default Login;

const ME_QUERY = gql`
  query ME_QUERY {
    me {
      role {
        name
      }
    }
  }
`;
