import { Badge, Button } from "@chakra-ui/react";
import React from "react";
import { ProductStatusFilterTypes } from "./StatusFilters";

interface Props {
  text: ProductStatusFilterTypes;
  curFilter: ProductStatusFilterTypes;
  handleClick: (text: ProductStatusFilterTypes) => void;
  count: number;
}

const StatusFilterButton: React.FC<Props> = ({
  text,
  handleClick,
  curFilter,
  count,
}) => {
  return (
    <Button
      mx="2"
      boxShadow="md"
      color={curFilter === text ? "orange.500" : "gray.600"}
      onClick={() => handleClick(text)}
      rightIcon={
        <Badge borderRadius="full" colorScheme="blue" px="2" py="1">
          {count}
        </Badge>
      }
    >
      {text}
    </Button>
  );
};

export default StatusFilterButton;
