import React from "react";
import ViewDeliveryCharge from "../components/DeliveryCharge/ViewDeliveryCharge";

interface Props {}

const DeliveryChargesPage: React.FC<Props> = () => {
  return <ViewDeliveryCharge />;
};

export default DeliveryChargesPage;
