import Axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import * as React from "react";
import { Line } from "react-chartjs-2";
import { api } from "../../../config";
import { TableTitle } from "../ProductWise/FilterBox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const dummyData = {
  datasets: [
    {
      label: "Dataset 1",
      data: [1, 2],
    },
  ],
};
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Delivery Orders VS Pickup Orders",
      font: {
        size: 20,
      },
    },
  },
};

interface Props {
  fromDate: string;
  toDate: string;
  orderBy: string;
}
const OrdersCompareDelivery: React.FC<Props> = ({
  fromDate,
  toDate,
  orderBy,
}) => {
  const [chartData, setChartData] = React.useState(dummyData);

  const getChartData = async () => {
    const res = await Axios.get(
      `${api}/orderhs/order-type-compare-count?fromDate=${fromDate}&toDate=${toDate}&orderBy=${orderBy}`,
      {
        withCredentials: true,
      }
    );
    const dataArray = res.data;
    const alllabels = dataArray.map((item: any) => item.daily);
    const labels = [...new Set(alllabels)];
    // console.log(labels);
    let POs = dataArray.filter((item: any) => item.deliveryMethod === "PO");
    let PDs = dataArray.filter((item: any) => item.deliveryMethod === "PD");
    let PO = [];
    let PD = [];
    let PoIndex = 0;
    let PdIndex = 0;
    for (let i = 0; i < labels.length; i++) {
      const label = labels[i];
      if (POs.length > PoIndex && POs[PoIndex].daily === label) {
        PO.push(POs[PoIndex].count);
        PoIndex++;
      } else {
        PO.push(0);
      }
      if (PDs.length > PdIndex && PDs[PdIndex].daily === label) {
        PD.push(PDs[PdIndex].count);
        PdIndex++;
      } else {
        PD.push(0);
      }
    }
    // console.log(PO);
    // console.log(PD);
    const data = {
      labels,
      datasets: [
        {
          label: "Delivery Orders",
          data: PD,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
        },
        {
          label: "Pickup Orders",
          data: PO,
          backgroundColor: "rgba(236, 201, 75, 0.2)",
          borderColor: "rgba(236, 201, 75, 1)",
        },
      ],
    };
    setChartData(data);
    // console.log(res.data);
  };
  React.useEffect(() => {
    getChartData();
  }, [fromDate, toDate, orderBy]);

  return (
    <>
      <div
        style={{
          marginTop: 20,
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <TableTitle
          title="Delivery Orders VS Pickup Orders"
          backgroundColorIndex={2}
          ColorIndex={0}
        />
        <div
          style={{
            padding: "20px",
          }}
        >
          <div style={{ padding: "10px" }}></div>
          <Line options={options} data={chartData} height={100} />
        </div>
      </div>
    </>
  );
};

export default OrdersCompareDelivery;
