import {
  Box,
  Button,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react"
import React from "react"

interface Props {
  setFilter: React.Dispatch<React.SetStateAction<string[]>>
  setIsFilter: React.Dispatch<React.SetStateAction<boolean>>
  isFilter: boolean
  materialTableRef?: any
  BtnPreText?: string
}
//////////////////////////////////////////////////////////////
////////////// ********** this component is used with multiple components ********** //////////////
//////////////////////////////////////////////////////////////
const OrderDateRangeFilter: React.FC<Props> = ({
  setFilter,
  materialTableRef,
  setIsFilter,
  BtnPreText = "Order",
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [minOrderDate, setMinOrderDate] = React.useState("")
  const [maxOrderDate, setMaxOrderDate] = React.useState("")
  const minRef = React.useRef(null)
  const maxRef = React.useRef(null)
  const handleOrderDateChange = () => {
    // console.log(minOrderDate, maxOrderDate);
    if (minOrderDate === "" || maxOrderDate === "") {
      alert("Please select a date range")
      return
    }
    setFilter([minOrderDate, maxOrderDate])
    setIsFilter(true)
    if (materialTableRef?.current) materialTableRef.current.onQueryChange()
    onToggle()
  }
  const handleOrderDateClear = () => {
    setMinOrderDate("")
    setMaxOrderDate("")
    setFilter([minOrderDate, maxOrderDate])
    setIsFilter(false)
    if (materialTableRef?.current) materialTableRef.current.onQueryChange()
    onToggle()
  }
  return (
    <Box margin="10px">
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button colorScheme="facebook" onClick={onToggle}>
            {BtnPreText} Date Range {minOrderDate} to {maxOrderDate}
            {/* {OrderDateRange} */}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          _focus={{ outline: "none" }}
          boxShadow="2xl"
          maxW="unset"
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Select Date Range</PopoverHeader>
          <PopoverBody>
            <Stack direction="column">
              <Text fontSize="xl" textAlign="center">
                From
              </Text>

              <Input
                ref={minRef}
                placeholder="min"
                type="date"
                onChange={(e) => setMinOrderDate(e.target.value)}
              />
              <Text fontSize="xl" textAlign="center">
                To
              </Text>
              <Input
                ref={maxRef}
                placeholder="max"
                type="date"
                onChange={(e) => setMaxOrderDate(e.target.value)}
              />
              <Button onClick={handleOrderDateChange} fontSize="xs">
                OK
              </Button>
              <Button onClick={handleOrderDateClear} fontSize="xs">
                Clear
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
};

export default OrderDateRangeFilter;
