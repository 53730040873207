import React from "react";
import ViewBrands from "../components/Brand/ViewBrands";

interface Props {}

const BrandsPage: React.FC<Props> = () => {
  return <ViewBrands />;
};

export default BrandsPage;
