import axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../config";
interface Props {}

const ViewDeliveryCharge: React.FC<Props> = () => {
  const tableRef = React.useRef(null);
  const [deliveryData, setDeliveryData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();
  const getData = async () => {
    setLoading(true);
    const response = await axios.get(`${api}/districts?_sort=id:ASC`);
    setDeliveryData(response.data);
    setLoading(false);
  };
  React.useEffect(() => {
    getData();
  }, []);
  const handleEditCell = async (
    newValue: any,
    oldValue: any,
    rowData: any,
    columnDef: any
  ) => {
    console.log(newValue, oldValue, rowData, columnDef);
    const response = await axios.put(
      `${api}/districts/${rowData.id}`,
      {
        [columnDef.field]: newValue,
      },
      { withCredentials: true }
    );
    // console.log(response);
    getData();
  };

  return (
    <>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Update Delivery Charges"
          isLoading={loading}
          columns={[
            { title: "ID", field: "id", editable: "never" },
            {
              title: "District",
              field: "districtName",
              sorting: false,
              editable: "never",
            },
            {
              title: "Delivery Charge (<=1kg)",
              field: "deliveryCharge1",
              sorting: false,
            },
            {
              title: "Delivery Charge 2 (1-2kg)",
              field: "deliveryCharge2",
              sorting: false,
            },
            {
              title: "Delivery Charge 3 (2-3kg)",
              field: "deliveryCharge3",
              sorting: false,
            },
            {
              title: "Delivery Charge 4 (>3kg)",
              field: "deliveryCharge4",
              sorting: false,
            },
          ]}
          options={{
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
            pageSize: 25,
            pageSizeOptions: [10, 25, 50, 100],
          }}
          data={deliveryData.map((district: any) => {
            let newDistrict = { ...district };
            if (newDistrict.deliveryCharge1 === null)
              newDistrict.deliveryCharge1 = 0;
            if (newDistrict.deliveryCharge2 === null)
              newDistrict.deliveryCharge2 = 0;
            if (newDistrict.deliveryCharge3 === null)
              newDistrict.deliveryCharge3 = 0;
            if (newDistrict.deliveryCharge4 === null)
              newDistrict.deliveryCharge4 = 0;
            return newDistrict;
          })}
          cellEditable={{
            cellStyle: {},
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
              handleEditCell(newValue, oldValue, rowData, columnDef),
          }}
        />
      </div>
    </>
  );
};

export default ViewDeliveryCharge;
