import React from "react";
import ViewCustomers from "../components/Customers/ViewCustomers";

interface Props {}

const CustomersPage: React.FC<Props> = () => {
  return <ViewCustomers />;
};

export default CustomersPage;
