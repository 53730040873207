//Client, Backoffice Roles
export const CBRoles = (role: string | null) => {
  return ["Client", "Backoffice"].includes(role as string);
};

//Client, Backoffice, Marketing Roles
export const CBMRoles = (role: string | null) => {
  return ["Client", "Backoffice", "Marketing"].includes(role as string);
};

//Client, Backoffice, Cashier Roles
export const CBCRoles = (role: string | null) => {
  return ["Client", "Backoffice", "Cashier"].includes(role as string);
};

//Client, Backoffice, Cashier Roles
export const CBCMRoles = (role: string | null) => {
  return ["Client", "Backoffice", "Cashier", "Marketing"].includes(
    role as string,
  );
};

//Client Role
export const CRole = (role: string | null) => role === "Client";

//Client, DeliveryPerson, Cashier Roles
export const AllRoles = (role: string | null) => {
  return [
    "Client",
    "DeliveryPerson",
    "Cashier",
    "Backoffice",
    "Marketing",
  ].includes(role as string);
};

export const dateTimeSQLtoJS = (dateTime: string | undefined) => {
  if (!dateTime) return new Date().toISOString();
  else {
    //https://stackoverflow.com/questions/12413243/javascript-date-format-like-iso-but-local
    const date = new Date(dateTime).toLocaleString("sv").replace(" ", "T");
    console.log(date);
    return date;
  }
};

export const datetimestampToString = (timestamp: string | undefined) => {
  if (!timestamp) return "";
  else {
    const date = new Date(timestamp).toLocaleDateString();
    const time = new Date(timestamp).toLocaleTimeString();
    return date + " " + time;
  }
};
