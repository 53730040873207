import { useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import MaterialTableCustomSort from "../Misc/MaterialTableCustomSort";
import { CONTACTS_QUERY } from "../Product/hooks/useContacts";

interface Props {}
const columns = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "email",
    name: "Email",
  },
  {
    id: "created_at",
    name: "Date",
  },
];
const ViewContacts: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryContact");
  const [sortQueryContact, setSortQueryContact] =
    useCounterState("created_at:desc");

  const history = useHistory();
  const { refetch } = useQuery(CONTACTS_QUERY, {
    skip: true,
  });
  const tableRef = React.useRef(null);

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/contact-form/${id}`,
    });
  };
  return (
    <>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Inqueries"
          columns={[
            { title: "Contact Name", field: "name", sorting: false },
            {
              title: "Email",
              field: "email",
              sorting: false,
            },
            { title: "Date and Time", field: "created_at", sorting: false },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryContact}
                  setSort={setSortQueryContact}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            ),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Contact",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  name_contains: query.search,
                },
                sort: sortQueryContact,
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      contactsConnection: { values, aggregate },
                    },
                  } = res;
                  const newData = values.map((item: any) => ({
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    created_at: item.created_at,
                  }));
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewContacts;
