import Axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import * as React from "react";
import { Line } from "react-chartjs-2";
import Select from "react-select";
import { api } from "../../../config";
import { TableTitle } from "./FilterBox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const dummyData = {
  datasets: [
    {
      label: "Dataset 1",
      data: [1, 2],
    },
  ],
};
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "",
    },
  },
};

interface Props {}

const defaultOption = {
  label: "Ansell Men's Wallet  - PG153GWL-BR",
  value: 1395,
  product: 99,
  color: 8,
};

const PurchaseGrowth: React.FC<Props> = () => {
  const [products, setProducts] = React.useState([]);
  const [chartData, setChartData] = React.useState(dummyData);

  const onSelectChange = async (selectedOption: any) => {
    // console.log(selectedOption);
    const res = await Axios.get(
      `${api}/products/purchase-growth?product=${selectedOption.product}&color=${selectedOption.color}`,
      {
        withCredentials: true,
      }
    );
    const dataArray = res.data.reverse();
    const labels = dataArray.map((item: any) => item.to_char);
    const data = {
      labels,
      datasets: [
        {
          label: "Purchase",
          data: dataArray.map((item: any) => item.sum),
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
        },
      ],
    };
    setChartData(data);
    // console.log(res.data);
  };

  const getProducts = async () => {
    const res = await Axios.get(`${api}/color-cats?_limit=1000`, {
      withCredentials: true,
    });
    // console.log(res.data);
    const data = res.data.map((item: any) => ({
      label: item.product.productName + " - " + item.colorCode,
      value: item.id,
      product: item.product.id,
      color: item.color.id,
    }));
    setProducts(data);
    onSelectChange(defaultOption);
  };
  React.useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      <div
        style={{
          marginTop: 20,
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <TableTitle
          title="Product Purchases Growth"
          backgroundColorIndex={2}
          ColorIndex={0}
        />
        <div
          style={{
            padding: "20px",
          }}
        >
          <div style={{ padding: "10px" }}></div>
          <Select
            options={products}
            onChange={onSelectChange}
            defaultValue={defaultOption}
          />
          <Line options={options} data={chartData} />
        </div>
      </div>
    </>
  );
};

export default PurchaseGrowth;
