import { useQuery } from "@apollo/client";
import { Box, Button } from "@chakra-ui/react";
import AddIcon from "@material-ui/icons/Add";
import Axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { apiLink } from "../../config";
import MaterialTableCustomSort from "../Misc/MaterialTableCustomSort";
import { NEWSLETTERS_QUERY } from "../Product/hooks/useNewsletters";
interface Props {}
const columns = [
  {
    id: "email",
    name: "Email",
  },
];
const ViewNewsletters: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryNews");
  const [sortQueryNews, setSortQueryNews] = useCounterState("email:asc");
  const tableRef = React.useRef(null);

  const history = useHistory();
  const { refetch } = useQuery(NEWSLETTERS_QUERY, {
    skip: true,
  });

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/newsletter-form/${id}`,
    });
  };

  const handleAddClick = () => {
    history.push({
      pathname: "/admin/newsletter-form",
    });
  };
  const handleDeleteClick = async (row: any) => {
    await Axios.delete(`${apiLink}/newsletters/${row.id}`, {
      withCredentials: true,
    });
    //@ts-ignore
    tableRef.current?.onQueryChange();
  };
  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Newsletter
      </Button>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Newsletter"
          columns={[{ title: "Email", field: "email", sorting: false }]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Newsletter email",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
            {
              icon: "delete",
              tooltip: "Delete Newsletter email",
              onClick: (event, rowData: any) => {
                handleDeleteClick(rowData);
              },
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryNews}
                  setSort={setSortQueryNews}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  email_contains: query.search,
                },
                sort: sortQueryNews,
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      newslettersConnection: { values, aggregate },
                    },
                  } = res;
                  const newData = values.map((item: any) => ({
                    id: item.id,
                    email: item.email,
                  }));
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewNewsletters;
