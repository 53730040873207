/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import CategoryWise from "../components/Dashboard/CategoryWise/CategoryWise";
import CustomerWise from "../components/Dashboard/CustomerWise/CustomerWise";
import DashboardPage from "../components/Dashboard/index";
import MarketingDashboard from "../components/Dashboard/MarketingDashboard";
import Orders from "../components/Dashboard/Orders";
import OrderWise from "../components/Dashboard/OrderWise/OrderWise";
import ProductWise from "../components/Dashboard/ProductWise/ProductWise";
import ReturnOrder from "../components/ReturnOrder/ReturnOrder";
import AlocationFormPage from "../pages/alocation-form";
import BlogsPage from "../pages/blog";
import BlogFormPage from "../pages/blog-form";
import BrandFormPage from "../pages/brand-form";
import BrandsPage from "../pages/brands";
import CategoriesPage from "../pages/categories";
import CategoryFormPage from "../pages/category-form";
import ColorFormPage from "../pages/color-form";
import ColorsPage from "../pages/colors";
import ContactsPage from "../pages/contacts";
import ContactFormPage from "../pages/contacts-form";
import CustomersPage from "../pages/customers";
import DeliveryChargesPage from "../pages/delivery-charge";
import DeliveryUsersPage from "../pages/delivery-users";
import DiscountFormPage from "../pages/discount-form";
import DiscountsPage from "../pages/discounts";
import NewslettersPage from "../pages/newsletter";
import NewsletterFormPage from "../pages/newsletter-form";
import NotifyStockPage from "../pages/notify-stock";
import OrderPage from "../pages/order";
import ProductFormPage from "../pages/product-form";
import ProductUploadPage from "../pages/product-upload";
import ProductsPage from "../pages/products";
import ReturnOrderPage from "../pages/return-order";
import ReviewsPage from "../pages/reviews";
import ReviewFormPage from "../pages/reviews-form";
import SettingsPage from "../pages/settings";
import StoreFormPage from "../pages/store-form";
import StoresPage from "../pages/stores";
import VoucherFormPage from "../pages/voucher-form";
import VouchersPage from "../pages/vouchers";
import WarrantiesPage from "../pages/warrenties";
import WarrantyFormPage from "../pages/warrenty-form";
import WebContentPage from "../pages/web-content";
import WebContentFormPage from "../pages/web-content-form";
import LoyaltyPoints from "../pages/loyalty-points";
import AbundantCarts from "../pages/abundant-carts";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/orders",
    name: "My Orders",
    rtlName: "",
    icon: "content_paste",
    component: Orders,
    layout: "/admin",
    show: true,
  },
  {
    path: "/returnorders",
    name: "Return Orders",
    rtlName: "",
    icon: "content_paste",
    component: ReturnOrderPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/categories",
    name: "Sub Categories",
    rtlName: "",
    icon: "content_paste",
    component: CategoriesPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/delivery-charge",
    name: "Delivery Charges",
    rtlName: "",
    icon: "content_paste",
    component: DeliveryChargesPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/category-form",
    name: "Category Form",
    rtlName: "",
    icon: "content_paste",
    component: CategoryFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "",
    icon: "content_paste",
    component: ProductsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/product-form",
    name: "Product Form",
    rtlName: "",
    icon: "content_paste",
    component: ProductFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/product-upload",
    name: "Product Upload",
    rtlName: "",
    icon: "content_paste",
    component: ProductUploadPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/brands",
    name: "Brands",
    rtlName: "",
    icon: "content_paste",
    component: BrandsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/brand-form",
    name: "Brand Form",
    rtlName: "",
    icon: "content_paste",
    component: BrandFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/discounts",
    name: "Discounts",
    rtlName: "",
    icon: "content_paste",
    component: DiscountsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/discount-form",
    name: "Discount Form",
    rtlName: "",
    icon: "content_paste",
    component: DiscountFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/contacts",
    name: "Inqueries",
    rtlName: "",
    icon: "content_paste",
    component: ContactsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/contact-form",
    name: "Inqueries Form",
    rtlName: "",
    icon: "content_paste",
    component: ContactFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/reviews",
    name: "Reviews",
    rtlName: "",
    icon: "content_paste",
    component: ReviewsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/review-form",
    name: "Reviews Form",
    rtlName: "",
    icon: "content_paste",
    component: ReviewFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/colors",
    name: "Colors",
    rtlName: "",
    icon: "content_paste",
    component: ColorsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/color-form",
    name: "Color Form",
    rtlName: "",
    icon: "content_paste",
    component: ColorFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/newsletters",
    name: "Newsletters",
    rtlName: "",
    icon: "content_paste",
    component: NewslettersPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/newsletter-form",
    name: "Newsletter Form",
    rtlName: "",
    icon: "content_paste",
    component: NewsletterFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/warrenties",
    name: "Warrenties",
    rtlName: "",
    icon: "content_paste",
    component: WarrantiesPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/warrenty-form",
    name: "Warrenty Form",
    rtlName: "",
    icon: "content_paste",
    component: WarrantyFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/web-content",
    name: "Web Content",
    rtlName: "",
    icon: "content_paste",
    component: WebContentPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/web-content-form",
    name: "Web Content Form",
    rtlName: "",
    icon: "content_paste",
    component: WebContentFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/stores",
    name: "Stores",
    rtlName: "",
    icon: "content_paste",
    component: StoresPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/store-form",
    name: "Store Form",
    rtlName: "",
    icon: "content_paste",
    component: StoreFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/delivery-users",
    name: "Delivery Users",
    rtlName: "",
    icon: "content_paste",
    component: DeliveryUsersPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/alocation-form",
    name: "Alocation Form",
    rtlName: "",
    icon: "content_paste",
    component: AlocationFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/settings",
    name: "Settings",
    rtlName: "",
    icon: "content_paste",
    component: SettingsPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/order",
    name: "Order Page",
    rtlName: "",
    icon: "content_paste",
    component: OrderPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/returnorder",
    name: "View Return Order",
    rtlName: "",
    icon: "content_paste",
    component: ReturnOrder,
    layout: "/admin",
    show: false,
  },
  {
    path: "/voucher-form",
    name: "View Voucher",
    rtlName: "",
    icon: "content_paste",
    component: VoucherFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    rtlName: "",
    icon: "content_paste",
    component: VouchersPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/product-wise",
    name: "Products analysis",
    rtlName: "",
    icon: "content_paste",
    component: ProductWise,
    layout: "/admin",
    show: false,
  },
  {
    path: "/category-wise",
    name: "Product Category analysis",
    rtlName: "",
    icon: "content_paste",
    component: CategoryWise,
    layout: "/admin",
    show: false,
  },
  {
    path: "/customer-wise",
    name: "Customers analysis",
    rtlName: "",
    icon: "content_paste",
    component: CustomerWise,
    layout: "/admin",
    show: false,
  },
  {
    path: "/order-wise",
    name: "Orders analysis",
    rtlName: "",
    icon: "content_paste",
    component: OrderWise,
    layout: "/admin",
    show: false,
  },
  {
    path: "/customers",
    name: "Customers",
    rtlName: "",
    icon: "content_paste",
    component: CustomersPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/notifystock",
    name: "Notify Stock",
    rtlName: "",
    icon: "content_paste",
    component: NotifyStockPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/blogs",
    name: "Blogs",
    rtlName: "",
    icon: "content_paste",
    component: BlogsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/blog-form",
    name: "Blog Form",
    rtlName: "",
    icon: "content_paste",
    component: BlogFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/loyalty-points",
    name: "Loyalty Points",
    rtlName: "",
    icon: "content_paste",
    component: LoyaltyPoints,
    layout: "/admin",
    show: true,
  },
  {
    path: "/abundant-carts",
    name: "Abundant Carts",
    rtlName: "",
    icon: "content_paste",
    component: AbundantCarts,
    layout: "/admin",
    show: true,
  },
];

export const cashierRoutes = [
  {
    path: "/orders",
    name: "My Orders",
    rtlName: "",
    icon: "content_paste",
    component: Orders,
    layout: "/admin",
    show: true,
  },
  {
    path: "/returnorders",
    name: "Return Orders",
    rtlName: "",
    icon: "content_paste",
    component: ReturnOrderPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/order",
    name: "Order Page",
    rtlName: "",
    icon: "content_paste",
    component: OrderPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/returnorder",
    name: "View Return Order",
    rtlName: "",
    icon: "content_paste",
    component: ReturnOrder,
    layout: "/admin",
    show: false,
  },
  {
    path: "/voucher-form",
    name: "View Voucher",
    rtlName: "",
    icon: "content_paste",
    component: VoucherFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    rtlName: "",
    icon: "content_paste",
    component: VouchersPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/contacts",
    name: "Inqueries",
    rtlName: "",
    icon: "content_paste",
    component: ContactsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "",
    icon: "content_paste",
    component: ProductsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/product-form",
    name: "Product Form",
    rtlName: "",
    icon: "content_paste",
    component: ProductFormPage,
    layout: "/admin",
    show: false,
  },
];

export const marketingRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: Dashboard,
    component: MarketingDashboard,
    layout: "/admin",
    show: true,
  },
  {
    path: "/web-content",
    name: "Web Content",
    rtlName: "",
    icon: "content_paste",
    component: WebContentPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/web-content-form",
    name: "Web Content Form",
    rtlName: "",
    icon: "content_paste",
    component: WebContentFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/blogs",
    name: "Blogs",
    rtlName: "",
    icon: "content_paste",
    component: BlogsPage,
    layout: "/admin",
    show: true,
  },
  {
    path: "/blog-form",
    name: "Blog Form",
    rtlName: "",
    icon: "content_paste",
    component: BlogFormPage,
    layout: "/admin",
    show: false,
  },
  {
    path: "/loyalty-points",
    name: "Loyalty Points",
    rtlName: "",
    icon: "content_paste",
    component: LoyaltyPoints,
    layout: "/admin",
    show: true,
  },

  {
    path: "/orders",
    name: "My Orders",
    rtlName: "",
    icon: "content_paste",
    component: Orders,
    layout: "/admin",
    show: true,
  },
  {
    path: "/order",
    name: "Order Page",
    rtlName: "",
    icon: "content_paste",
    component: OrderPage,
    layout: "/admin",
    show: false,
  },
];

export default dashboardRoutes;
