import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { Box } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../config";
import stringify from "qs-stringify";

interface Props {}

const LoyaltyPoints: React.FC<Props> = () => {
  const [loyaltyUser, setLoyaltyUser] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${api}/loyaltypoints`, {
        withCredentials: true,
      });
      setLoyaltyUser(response.data);
    };
    fetchData();
  }, []);

  const columns = [
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Phone Number", field: "phoneNumber" },
    { title: "Points", field: "points" },
    { title: "Points Category", field: "pointsCategory" },
  ];

  return (
    <MaterialTable
      title="Loyalty Points"
      data={(query) =>
        new Promise(async (resolve, reject) => {
          const filterQuery = stringify({
            _start: query.page,
            _limit: query.pageSize,
            _q: query.search,
            // _where: {
            //   _or: [
            //     [{ name_contains: query.search }],
            //     [{ email_contains: query.search }],
            //   ],
            // },
          });

          const results = await axios.get(
            `${api}/loyaltypoints?${filterQuery}`,
            {
              withCredentials: true,
            },
          );
          const totalCount = await axios.get(
            `${api}/loyaltypoints/count?${filterQuery}`,
            {
              withCredentials: true,
            },
          );

          resolve({
            data: results.data,
            page: query.page,
            totalCount: totalCount.data,
          });
        })
      }
      columns={columns}
      options={{
        sorting: true,
        search: true,
        paging: true,
        pageSize: 50,
        pageSizeOptions: [10, 20, 50],
      }}
    />
  );
};

export default LoyaltyPoints;
