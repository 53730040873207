import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useWarranties = () => {
  const { data, loading, error } = useQuery(WARRENTIES_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  return {
    warrantyData: data,
    warrantyLoading: loading,
    warrantyError: error,
  };
};

export default useWarranties;

export const mapWarranties = (warrantyData: any) => {
  const mappedWarranty = warrantyData.warrantiesConnection.values.map(
    (warranty: { warranty: string; __typename: string; id: number }) => ({
      id: warranty.id,
      label: warranty.warranty,
      value: warranty.warranty,
    })
  );
  return mappedWarranty;
};

export const WARRENTIES_QUERY = gql`
  query WARRENTIES_QUERY(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    warrantiesConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        warranty
      }
    }
  }
`;
