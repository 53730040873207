import {
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../../config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    buttonProgress: {
      color: grey[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
}

const ForgotPasswordDialog: React.FC<Props> = ({ open, setOpen }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${api}/auth/forgot-password`,
        // { email: email },
        { email: email, admin: true },
        { withCredentials: true }
      );
      setOpen(false);
      setEmail("");
      alert(
        "Email has been Submitted Successfully. We will send you an email shortly"
      );
      setLoading(false);
    } catch (err) {
      alert("Please provide a valid email address");
      setLoading(false);
    }
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To reset your password to this website, please enter your email
            address here. We will send you an email with the password reset
            link. Thank you!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            Send
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ForgotPasswordDialog;
