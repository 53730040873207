import React from "react";
import ViewNewsletters from "../components/Newsletter/ViewNewsletters";

interface Props {}

const NewslettersPage: React.FC<Props> = () => {
  return <ViewNewsletters />;
};

export default NewslettersPage;
