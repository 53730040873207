import { Box } from "@chakra-ui/layout";
import { Paper } from "@material-ui/core";
import React from "react";
import { Orderh, Status } from "../../generated/graphql";
import useStore from "../../utils/store";
import useStyles from "./hooks/useStyles";
import Line from "./Line";

interface Props {
  orderCode?: Orderh["orderCode"];
  created_at: Orderh["created_at"];
  netAmt?: Orderh["netAmt"];
  paidAmount?: Orderh["paidAmount"];
  status: Status["type"];
  id: Orderh["id"];
}

const MainDetails: React.FC<Props> = ({
  created_at,
  netAmt,
  paidAmount,
  orderCode,
  status,
  id,
}) => {
  const classes = useStyles();
  const role = useStore((state) => state.role);

  return (
    <Paper className={classes.mainDetails}>
      <Line name="Order ID" value={id} />
      <Line name="Order Code" value={orderCode} />
      <Line
        name="Order Date"
        value={JSON.stringify(created_at).substring(1, 11)}
      />
      {role !== "DeliveryPerson" && (
        <>
          <Line name="Amount" value={netAmt} format="CURRENCY" />
          <Line name="Paid Amount" value={paidAmount || 0} format="CURRENCY" />
        </>
      )}
      <Box
        padding="3px"
        color={
          status == "Order Delivered / Completed" || status == "Order Rejected"
            ? "white"
            : "black"
        }
        bg={
          status == "Order Delivered / Completed"
            ? "green.400"
            : status == "Order Rejected"
            ? "tomato"
            : "white"
        }
      >
        <Line name="Status" value={status || ""} />
      </Box>
    </Paper>
  );
};

export default MainDetails;
