import { Heading } from "@chakra-ui/react";
import { Paper } from "@material-ui/core";
import React from "react";
import { OrderQuery } from "../../generated/graphql";
import useStyles from "./hooks/useStyles";
import Line from "./Line";

interface Props {
  order: OrderQuery["orderh"];
}

const BillingDetails: React.FC<Props> = ({ order }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.midSecBox}>
      <Heading
        fontSize="xl"
        textTransform="uppercase"
        // fontWeight="thin"
        borderBottom="2px"
        borderColor="yellow.700"
        mb="1"
      >
        Billing Details
      </Heading>
      <Line
        name="Name"
        value={`${order?.billingdetails?.[0]?.firstName} ${order?.billingdetails?.[0]?.lastName}`}
      />
      <Line
        name="Address Line 1"
        value={order?.billingdetails?.[0]?.address1}
      />
      <Line
        name="Address Line 2"
        value={order?.billingdetails?.[0]?.address2}
      />
      <Line name="City" value={order?.billingdetails?.[0]?.city?.cityName} />
      <Line name="Email" value={order?.billingdetails?.[0]?.email} />
      <Line name="Telephone 1" value={order?.billingdetails?.[0]?.tele1} />
      <Line
        name="Telephone 2"
        value={order?.billingdetails?.[0]?.tele2 || "-"}
      />
    </Paper>
  );
};

export default BillingDetails;
