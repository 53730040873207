import { useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { Chip } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import MaterialTableCustomSort from "../Misc/MaterialTableCustomSort";
import { REVIEWS_QUERY } from "../Product/hooks/useReviews";
interface Props {}
const columns = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "email",
    name: "Email",
  },
  {
    id: "created_at",
    name: "Date",
  },
];
const ViewReviews: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryReview");
  const [sortQueryReview, setSortQueryReview] =
    useCounterState("created_at:desc");

  const history = useHistory();
  const { refetch } = useQuery(REVIEWS_QUERY, {
    skip: true,
  });
  const tableRef = React.useRef(null);

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/review-form/${id}`,
    });
  };
  return (
    <>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Reviews"
          columns={[
            { title: "Product Name", field: "productName", sorting: false },
            {
              title: "Rating",
              field: "rating",
              sorting: false,
              render: (rowData) => {
                return (
                  <div>
                    <Rating
                      name="simple-controlled"
                      value={rowData.rating}
                      readOnly
                    />
                  </div>
                );
              },
            },
            {
              title: "Active",
              field: "active",
              sorting: false,
              render: (rowData) => (
                <Chip
                  label={rowData.active ? "Active" : "Disabled"}
                  disabled={!rowData.active}
                />
              ),
            },
            { title: "Date and Time", field: "created_at", sorting: false },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryReview}
                  setSort={setSortQueryReview}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            ),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Review",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  review_contains: query.search,
                  _publicationState: "preview",
                },
                sort: sortQueryReview,
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      reviewsConnection: { values, aggregate },
                    },
                  } = res;
                  const newData = values.map((item: any) => ({
                    id: item.id,
                    productName: item.product.productName,
                    rating: item.rating,
                    created_at: item.created_at,
                    active: item.published_at === null ? false : true,
                  }));
                  console.log(newData);
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewReviews;
