import { TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import axios from "axios";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SubmitBtn from "../components/Misc/FormComponents/SubmitBtn";
import { api } from "../config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "60vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      margin: 20,
    },
    paperBox: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        // margin: theme.spacing(1),
        width: "100%",

        // height: theme.spacing(16),
      },
      padding: 40,
    },
    homeText: {
      color: "#3d8891",
      fontFamily: "KotoriRoseBold",
      textTransform: "uppercase",
      // width: "80%",
      // margin: "0 0",
      textAlign: "center",
      marginBottom: 10,
      fontSize: 30,
      borderBottom: "2px solid #0f3057",
      // [theme.breakpoints.down("sm")]: {
      //   width: "90%",
      // },
    },
  })
);

interface Props {}

const ResetPassword: React.FC<Props> = () => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const code = new URLSearchParams(useLocation().search).get("code");

  const handleSubmit = async () => {
    try {
      if (password.length < 6) {
        throw new Error("Password Length minimum must be 6");
      }
      if (password !== confirmPassword) {
        throw new Error("Passwords Don't match");
      }
      // const code = params.code;
      setLoading(true);
      const res = await axios.post(
        `${api}/auth/reset-password`,
        { code, password, passwordConfirmation: confirmPassword },
        { withCredentials: true }
      );
      setPassword("");
      setConfirmPassword("");
      alert(
        "You Have Successfully Resetted your password. You can log into the website now."
      );

      history.push({ pathname: "/login" });
      setLoading(false);
    } catch (err) {
      console.log(err);
      alert(err.message);

      setLoading(false);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paperBox} elevation={4}>
          <h3 className={classes.homeText}>Reset Your Password</h3>
          <TextField
            id="password"
            label="Password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <SubmitBtn onClick={handleSubmit} loading={loading} />
        </Paper>
      </div>
    </>
  );
};

export default ResetPassword;
