import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    disc: {
      //@ts-ignore
      textDecoration: ({ isDisc }) => (isDisc ? "line-through" : "none"),
      display: "block",
      //@ts-ignore
      fontSize: ({ isDisc, discFS, notDiscFS }) => (isDisc ? discFS : notDiscFS)
    },
    notDisc: {
      //@ts-ignore
      fontSize: ({ notDiscFS }) => notDiscFS,
      display: "block"
    }
  })
);

interface Props {
  preText?: string;
  value: number;
  discount?: number;
  discFS?: string;
  notDiscFS?: string;
}

const Money: React.FC<Props> = ({
  preText = "",
  value,
  discount,
  discFS = "1rem",
  notDiscFS = "1.2rem"
}) => {
  const classes = useStyles({ isDisc: discount, discFS, notDiscFS });
  return (
    <>
      <span className={classes.disc}>
        {`${preText}${value.toLocaleString("en-us", {
          style: "currency",
          currency: "LKR"
        })}`}
      </span>
      {typeof discount !== "undefined" && discount !== 0 && (
        <span className={classes.notDisc}>
          {`${preText}${(value - (value * discount) / 100).toLocaleString(
            "en-us",
            {
              style: "currency",
              currency: "LKR"
            }
          )}
          `}
        </span>
      )}
    </>
  );
};

export default Money;
