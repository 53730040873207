import { useQuery } from "@apollo/client";
import { Box, Button } from "@chakra-ui/react";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import { CRole } from "../../utils/misc";
import MaterialTableCustomSort from "../Misc/MaterialTableCustomSort";
import { VOUCHERS_QUERY } from "../Product/hooks/useVouchers";
interface Props {}
const columns = [
  {
    id: "id",
    name: "ID",
  },
  {
    id: "code",
    name: "Code",
  },
  {
    id: "active",
    name: "State",
  },
  {
    id: "orderh",
    name: "Order Purchased ID",
  },
  {
    id: "returnorderh",
    name: "Returned Order ID",
  },
];

const ViewVouchers: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryVoucher");
  const [sortQueryVoucher, setSortQueryVoucher] =
    useCounterState("created_at:desc");
  const tableRef = React.useRef(null);
  const role = sessionStorage.getItem("role");

  const history = useHistory();
  const { refetch } = useQuery(VOUCHERS_QUERY, {
    skip: true,
  });

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/voucher-form/${id}`,
    });
  };

  const handleAddClick = async () => {
    history.push({
      pathname: `/admin/voucher-form`,
    });
  };

  return (
    <>
      {CRole(role) && (
        <Button
          colorScheme="blue"
          onClick={handleAddClick}
          className="add-btn"
          m="3"
          size="sm"
          leftIcon={<AddIcon />}
          textTransform="uppercase"
        >
          Add Voucher
        </Button>
      )}
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Voucher"
          columns={[
            {
              field: "id",
              title: "ID",
              sorting: false,
            },
            {
              field: "code",
              title: "Code",
              sorting: false,
            },
            {
              field: "price",
              title: "Price",
              sorting: false,
            },
            {
              field: "percentage",
              title: "Percentage",
              sorting: false,
            },
            {
              field: "active",
              title: "Is Active",
              sorting: false,
            },
            {
              field: "purchasedFrom",
              title: "Purchased From",
              sorting: false,
            },
            {
              field: "orderh",
              title: "Used On",
              sorting: false,
            },
            {
              field: "returnorderh",
              title: "For Returned Order",
              sorting: false,
            },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Voucher",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryVoucher}
                  setSort={setSortQueryVoucher}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  code_contains: query.search,
                },
                sort: sortQueryVoucher,
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      vouchersConnection: { values, aggregate },
                    },
                  } = res;
                  const newData = values.map((item: any) => ({
                    id: item.id,
                    code: item.code,
                    price: item.isPercentage ? 0 : item.price,
                    percentage: item.percentage || 0,
                    active: item.active,
                    orderh: item.orderh?.orderCode,
                    returnorderh: item.returnorderh?.orderCode,
                    purchasedFrom: item.orderd?.orderh?.orderCode,
                  }));
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewVouchers;
