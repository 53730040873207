import { Heading } from "@chakra-ui/layout";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Paper } from "@material-ui/core";
import Axios from "axios";
import React from "react";
import { api } from "../../config";
import { OrderQuery } from "../../generated/graphql";
import useStyles from "./hooks/useStyles";

interface Props {
  order: OrderQuery["orderh"];
}

const OrderHistory: React.FC<Props> = ({ order }) => {
  const [history, setHistory] = React.useState([]);
  const getHistory = async () => {
    if (order?.id) {
      const res = await Axios.get(`${api}/orderhistories?orderh=${order?.id}`, {
        withCredentials: true,
      });
      setHistory(res.data);
      console.log(res.data);
    }
  };
  React.useEffect(() => {
    getHistory();
  }, [order]);
  const classes = useStyles();
  return (
    <Paper className={classes.midSecBox}>
      <Heading
        fontSize="xl"
        textTransform="uppercase"
        // fontWeight="thin"
        borderBottom="2px"
        borderColor="yellow.700"
        mb="1"
      >
        Order History
      </Heading>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {history?.map((item: any) => {
            return (
              <Tr>
                <Td>
                  {JSON.stringify(item.created_at).substring(1, 11) +
                    " " +
                    JSON.stringify(item.created_at).substring(12, 20)}
                </Td>
                <Td>{item.status.type}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Paper>
  );
};

export default OrderHistory;
