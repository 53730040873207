import Axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { api } from "../../../config";
import { TableTitle } from "../ProductWise/FilterBox";

interface Props {
  fromDate: string;
  toDate: string;
  orderBy: string;
}

const IncompleteCheckouts: React.FC<Props> = ({
  fromDate,
  toDate,
  orderBy,
}) => {
  const [orders, setOrders] = React.useState([]);
  const getOrders = async () => {
    const res = await Axios.get(
      `${api}/orderhs/incomplete-checkouts?updated_at_gte=${fromDate}&updated_at_lte=${toDate}&status_in=1&status_in=2&status_in=3&status_in=5&_sort=updated_at:${orderBy}`,
      {
        withCredentials: true,
      },
    );
    // console.log(res.data);
    const data = res.data.map((order: any) => {
      return {
        id: order.id,
        username: order.user.username,
        email: order.user.email,
        number: order.user.customer.phoneNumber,
        date: order.updated_at.substring(0, 10),
        products: order.orderds.map(
          (orderd: any) => orderd.product.productName,
        ),
      };
    });
    // console.log(data);
    setOrders(data);
  };
  React.useEffect(() => {
    getOrders();
  }, [fromDate, toDate, orderBy]);
  return (
    <>
      <div style={{ marginTop: 20, backgroundColor: "white" }}>
        <TableTitle
          title="Incomplete Checkouts"
          backgroundColorIndex={4}
          ColorIndex={0}
        />
        <MaterialTable
          columns={[
            { title: "Customer Name", field: "username" },
            { title: "Customer Email", field: "email" },
            { title: "Phone Number", field: "number" },
            { title: "Date", field: "date" },
            {
              title: "Products",
              field: "products",
              render: (rowData: any) => {
                return (
                  <div>
                    {rowData.products.map((product: any) => (
                      <li>{product}</li>
                    ))}
                  </div>
                );
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            debounceInterval: 500,
            exportButton: true,
            pageSize: 10,
            pageSizeOptions: [10, 50, 100, 1000],
          }}
          data={orders}
        />
      </div>
    </>
  );
};

export default IncompleteCheckouts;
