import Axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { api } from "../../../config";
import { TableTitle } from "../ProductWise/FilterBox";

interface Props {}

const NewCustomers: React.FC<Props> = () => {
  // const today = new Date(Date.now()).toISOString().split("T")[0];
  const days_ago = new Date(Date.now() - 604800000).toISOString(); //7days ago
  const [customers, setCustomers] = React.useState([]);
  const getCustomers = async () => {
    const res = await Axios.get(`${api}/users?created_at_gte=${days_ago}`, {
      withCredentials: true,
    });
    // console.log(res.data);
    setCustomers(res.data);
  };
  React.useEffect(() => {
    getCustomers();
  }, []);
  return (
    <>
      <div style={{ marginTop: 20, backgroundColor: "white" }}>
        <TableTitle
          title="New Customers (within 7 days)"
          backgroundColorIndex={3}
          ColorIndex={0}
        />
        <MaterialTable
          columns={[
            { title: "Customer Name", field: "username" },
            { title: "Email", field: "email" },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            debounceInterval: 500,
            toolbar: false,
          }}
          data={customers}
        />
      </div>
    </>
  );
};

export default NewCustomers;
