import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useProduct = (
  productId: string | undefined,
  QUERY: any = PRODUCT_QUERY,
) => {
  const { data, loading, error, refetch } = useQuery(QUERY, {
    variables: {
      ...(productId && { id: productId }),
    },
    ...(!Boolean(productId) && { skip: true }),
  });

  return {
    data: data as {
      product: Product;
    },
    loading,
    error,
    refetch,
  };
};

export default useProduct;

export const PRODUCT_QUERY = gql`
  query PRODUCT_QUERY($id: ID!) {
    product(id: $id) {
      id
      productCode
      productName
      productDescription
      inactive
      featuredItem
      serialNo
      spec
      isVoucher
      store {
        id
        storeName
      }
      material {
        id
        name
      }
      brand {
        id
        brandName
      }
      warranty {
        id
        warranty
      }
      # productspecs {
      #   id
      #   spec
      #   title
      # }

      category {
        id
        categoryName
      }
      color_cats {
        id
        colorCode
        colorPrice
        colorDiscount
        colorStock
        color {
          id
          colorName
        }
        img
        color_images {
          id
          image
        }
        material
        closure_type
        lockable
        laptop_com
        laptop_size
        warranty {
          id
          warranty
        }
        p_length
        width
        height
        weight
        inActive
        featured
        included
        discount {
          id
        }
      }
    }
  }
`;

export const LAST_CODE_QUERY = gql`
  query LAST_CODE_QUERY {
    getLastProductCode
  }
`;

export type Product = {
  id?: string;
  image?: string;
  price?: string;
  discount?: number;
  productCode?: string;
  productDescription?: string;
  productName?: string;
  inactive?: boolean;
  featuredItem?: boolean;
  serialNo?: string;
  spec?: string;
  stock?: number;
  isVoucher?: boolean;
  specs?: {
    id: string;
    spec: string;
    title: string;
  }[];
  productimages?: {
    id: string;
    image: string;
    __typename: string;
  }[];
  material?: {
    id: string;
    name: string;
  };
  brand?: {
    id: string;
    brandName: string;
  };
  warranty?: {
    id: string;
    warranty: string;
  };
  store?: {
    id: string;
    storeName: string;
  };
  productspecs?: { id?: string; spec?: string }[];

  category: { id: string; categoryName: string };
  productattributes: {
    id: string;
    combination: string;
    stock: number;
    discount: number;
    price: string;
    combDesp: string;
  }[];
  color_cats?: any;
  // {
  //   id: string;
  //   colorCode: string;
  //   color: { id: string; colorName: string };
  //   colorPrice: number;
  //   colorStock: number;
  //   img: string;
  //   color_images: {
  //     id: string;
  //     image: string;
  //   }[];
  // }[];
};
