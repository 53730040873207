import { Button } from "@chakra-ui/react";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { useHistory } from "react-router-dom";
import StatusFilters from "../components/Product/ViewProducts/StatusFilters";
import ViewProducts from "../components/Product/ViewProducts/ViewProducts";

interface Props {}

const ProductsPage: React.FC<Props> = (props: any) => {
  const history = useHistory();

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={() => history.push("/admin/product-form")}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Product
      </Button>
      {/* <Button
        colorScheme="green"
        onClick={() => history.push("/admin/product-upload")}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Products bulk upload
      </Button> */}
      <StatusFilters />
      <ViewProducts />
    </>
  );
};

export default ProductsPage;
