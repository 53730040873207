import * as React from "react";
import GoBackButton from "../../Misc/GoBackButton";
import FilterBox from "./../ProductWise/FilterBox";
import HitCount from "./HitCount";
import PurchaseCount from "./PurchaseCount";

interface Props {}

const CategoryWise: React.FC<Props> = () => {
  const today = new Date(Date.now()).toISOString().split("T")[0];
  const [fromDate, setFromDate] = React.useState(
    localStorage.getItem("CategoryWiseFromDate") ||
      new Date(today.slice(0, 7)).toISOString().split("T")[0]
  );
  const [toDate, setToDate] = React.useState(
    localStorage.getItem("CategoryWiseToDate") || today
  );
  const [orderBy, setOrderBy] = React.useState(
    localStorage.getItem("CategoryWiseOrderBy") || "DESC"
  );

  React.useEffect(() => {
    localStorage.setItem("CategoryWiseFromDate", fromDate);
    localStorage.setItem("CategoryWiseToDate", toDate);
    localStorage.setItem("CategoryWiseOrderBy", orderBy);
  }, [fromDate, toDate, orderBy]);

  return (
    <>
      <GoBackButton />
      <FilterBox
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "50% 50%",
          gridColumnGap: "20px",
        }}
      >
        <HitCount fromDate={fromDate} toDate={toDate} orderBy={orderBy} />
        <PurchaseCount fromDate={fromDate} toDate={toDate} orderBy={orderBy} />
      </div>
    </>
  );
};

export default CategoryWise;
