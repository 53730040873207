import { FieldProps } from "formik";
import React from "react";
import Select, { OptionsType } from "react-select";
import AsyncSelect from "react-select/async";

interface Option {
  label: string
  value: string
  isFixed?: boolean
}

interface Props extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  label: string;
  customOnChange?: (option: any) => void;
  emptyFields?: string[]; //pass the other field value name
  async?: boolean;
  loadOptions?: any;
}

const ReactSelectFormField: React.FC<Props> = React.memo(
  ({
    field,
    form,
    options,
    isMulti = false,
    customOnChange,
    emptyFields,
    async,
    loadOptions,
    ...props
  }) => {
    const onChange = async (option: any) => {
      form.setFieldValue(
        field.name,
        isMulti
          ? ((option as Option[]) || []).map((item: Option) => item.value)
          : (option as Option).value
      )
      if (typeof customOnChange !== "undefined") {
        customOnChange(option);
      }

      if (typeof emptyFields !== "undefined") {
        emptyFields.forEach((field) => form.setFieldValue(field, ""));
        // form.setFieldValue(setOtherFieldValue, "");
      }
      // form.setFieldTouched(field.name, true, true);
      // form.setFieldTouched(field.name);
      // setTimeout(() => { }, 1000);
      // console.log('touched', form.touc)
    };

    const getValue = () => {
      if (options) {
        if (isMulti) {
          const values = options.filter(
            (option) => field.value.indexOf(option.value) >= 0
          );
          return values;
        } else {
          const value = options.find((option) => option.value === field.value);
          return value ? value : "";
        }
        // return isMulti
        //   ? options.filter(option => field.value.indexOf(option.value) >= 0)
        //   : options.find(option => option.value === field.value);
      } else {
        return isMulti ? [] : ("" as any);
      }
    };

    return (
      <div style={{ padding: 1 }}>
        <label htmlFor={field.name}>{props.label}</label>
        {async ? (
          <AsyncSelect
            cacheOptions
            defaultOptions={options}
            loadOptions={loadOptions}
            {...field}
            {...props}
            name={field.name}
            value={getValue()}
            onChange={async (e) => {
              // console.log("on chagne");
              await onChange(e)
              form.setFieldTouched(field.name)
            }}
            // options={options}
            isMulti={isMulti}
            onBlur={() => {
              form.setFieldTouched(field.name)
            }}
            styles={{
              input: () => ({ padding: 10 }),
              container: () => ({
                marginTop: 10,
                marginBottom: 10,
                position: "relative"
              }),
              menu: () => ({
                zIndex: 10
              }),
              multiValueRemove: (base, state) => {
                return state.data.isFixed ? { ...base, display: "none" } : base
              }
            }}
          />
        ) : (
          <Select
            {...field}
            {...props}
            name={field.name}
            value={getValue()}
            onChange={async (e) => {
              await onChange(e)
              form.setFieldTouched(field.name)
            }}
            options={options}
            isMulti={isMulti}
            onBlur={() => {
              form.setFieldTouched(field.name)
            }}
            styles={{
              input: () => ({ padding: 10 }),
              container: () => ({
                marginTop: 10,
                marginBottom: 10,
                position: "relative"
              }),
              menu: () => ({
                zIndex: 10
              }),
              multiValueRemove: (base, state) => {
                return state.data.isFixed ? { ...base, display: "none" } : base
              }
            }}
          />
        )}

        {form.touched[field.name] && form.errors[field.name] && (
          <div style={{ color: "red" }}>{form.errors[field.name]}</div>
        )}
      </div>
    )
  }
);

export default ReactSelectFormField;
