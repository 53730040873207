import React from "react";
import ViewBlogs from "../components/Blog/ViewBlogs";

interface Props {}

const BlogsPage: React.FC<Props> = () => {
  return <ViewBlogs />;
};

export default BlogsPage;
