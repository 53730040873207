import { Box } from "@chakra-ui/layout";
import React from "react";
import GoBackButton from "../components/Misc/GoBackButton";
import ReturnOrderTablePage from "../components/ReturnOrder/ReturnTable";

interface Props {}

const ReturnOrderPage: React.FC<Props> = () => {
  return (
    <Box>
      <GoBackButton />
      <ReturnOrderTablePage />
    </Box>
  );
};
export default ReturnOrderPage;
