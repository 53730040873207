import { Box } from "@chakra-ui/layout";
import Axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useHistory } from "react-router-dom";
import { api, cloudApi } from "../../config";
import Loading from "../Misc/Loading";
import MaterialTableCustomFilterButton from "../Misc/MaterialTableCustomFilterButton";

const url = process.env.NODE_ENV === "development" ? api : cloudApi;

const statusEnum = {
  damage: "Damaged Product",
  product: "Wrong Product",
  color: "Wrong Color",
  quality: "Bad Quality",
};
const statuses = [
  "Return Requested",
  "Return Accepted",
  "Return Rejected",
  "Return Received",
  "Return Settled",
];
interface Props {}

const ReturnOrderTable: React.FC<Props> = () => {
  const [statusFilter, setStatusFilter] = React.useState("");
  const tableRef = React.useRef(null);
  const history = useHistory();
  const [returnOrders, setReturnOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getReturns = async () => {
    const res = await Axios.get(
      `${api}/returnorderhs?_where[status.type_contains]=${statusFilter}`,
      {
        withCredentials: true,
      }
    );
    setReturnOrders(res.data);
    setLoading(false);
    console.log(res.data);
  };

  React.useEffect(() => {
    getReturns();
  }, [statusFilter]);
  const handleViewClick = (id: string) => {
    history.push({
      pathname: `/admin/returnorder/${id}`,
    });
  };
  if (loading) return <Loading />;
  return (
    <Box marginTop="10px">
      <Box flexDirection="row" display="flex" flexWrap="wrap">
        <MaterialTableCustomFilterButton
          materialTableRef={tableRef}
          setFilter={setStatusFilter}
          filter={statusFilter}
          name="All"
          value=""
        />
        {statuses.map((status) => {
          return (
            <MaterialTableCustomFilterButton
              materialTableRef={tableRef}
              setFilter={setStatusFilter}
              filter={statusFilter}
              name={status}
              value={status}
            />
          );
        })}
      </Box>
      <MaterialTable
        // tableRef={tableRef}
        title="Returns"
        columns={[
          { title: "Return ID", field: "id" },
          { title: "Order Code", field: "orderCode" },
          {
            title: "Return Order Date",
            field: "created_at",
            type: "date",
          },
          {
            title: "Reason",
            field: "reasonType",
          },
          {
            title: "Net Amount",
            field: "netAmt",
            type: "currency",
            currencySetting: { currencyCode: "LKR" },
          },
          { title: "Return Status", field: "status.type", filtering: false },
        ]}
        actions={[
          {
            icon: "more",
            tooltip: "View More",
            onClick: (event, rowData: any) => {
              handleViewClick(rowData.id);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            zIndex: "unset",
          },
        }}
        data={returnOrders.map((item: any) => {
          let tabledata = {
            ...item,
            //@ts-ignore
            reasonType: statusEnum[item.reason],
          };
          return tabledata;
        })}
      />
    </Box>
  );
};
export default ReturnOrderTable;
