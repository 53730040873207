import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import ColorForm from "../components/Color/ColorForm";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";

interface Props {}

const ColorFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/color-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(COLOR_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { color } = data;
    // console.log(color);
    return (
      <div>
        <GoBackButton />
        <ColorForm color={color} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <ColorForm />
      </div>
    );
  }
};

export default ColorFormPage;

export const COLOR_QUERY = gql`
  query COLOR_QUERY($id: ID!) {
    color(id: $id) {
      id
      colorName
      colorHex
      isTwoTone
      colorHex2
    }
  }
`;
