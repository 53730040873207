import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useVouchers = () => {
  const { data, loading, error } = useQuery(VOUCHERS_QUERY);
  return {
    voucherData: data,
    voucherLoading: loading,
    voucherError: error,
  };
};

export default useVouchers;

export const VOUCHERS_QUERY = gql`
  query VOUCHERS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    vouchersConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        code
        price
        active
        percentage
        isPercentage
        orderh {
          id
          orderCode
        }
        returnorderh {
          id
          orderCode
        }

        orderd {
          id
          orderh {
            orderCode
          }
        }
      }
    }
  }
`;
