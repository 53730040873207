import { useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import { REVIEWS_QUERY } from "../components/Product/hooks/useReviews";
import ReviewForm from "../components/Review/ReviewForm";

interface Props {}

const ReviewFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/review-form/:id");
  const isEdit = match !== null;
  let id: string = "";

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error, refetch } = useQuery(REVIEWS_QUERY, {
    // variables: {
    //   id,
    // },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (isEdit) {
      refetch({
        where: {
          _publicationState: "preview",
          id,
        },
      });
    }
  }, [isEdit, refetch]);

  // console.log(data);
  // console.log(aaaa);
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const review = data.reviewsConnection.values[0];
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <ReviewForm review={review} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
      </div>
    );
  }
};

export default ReviewFormPage;

// export const REVIEW_QUERY = gql`
//   query REVIEW_QUERY($id: ID!) {
//     review(id: $id) {
//       id
//       review
//       product {
//         id
//         productName
//       }
//       rating
//       users_permissions_user {
//         id
//         username
//       }
//       orderh {
//         id
//         orderCode
//       }
//       created_at
//       updated_at
//       published_at
//     }
//   }
// `;
