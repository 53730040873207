import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import VoucherForm from "../components/Voucher/VoucherForm";

interface Props {}

const VoucherFormPage: React.FC<Props> = () => {
  console.log("object");
  const match = useRouteMatch("/admin/voucher-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }
  const { data, loading, error } = useQuery(VOUCHER_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { voucher } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <VoucherForm voucher={voucher} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <VoucherForm />
      </div>
    );
  }
};

export default VoucherFormPage;

export const VOUCHER_QUERY = gql`
  query VOUCHER_QUERY($id: ID!) {
    voucher(id: $id) {
      id
      code
      price
      active
      email
      validMonth
      updated_at
      percentage
      isPercentage
      voucherhistories {
        desp
        users_permissions_user {
          id
          username
        }
        created_at
      }
    }
  }
`;
