import { useMutation } from "@apollo/client";
import { Box, Heading, Text } from "@chakra-ui/layout";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import * as yup from "yup";
import { api, cloudApi } from "../../config";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import GoBackButton from "../Misc/GoBackButton";
import Loading from "../Misc/Loading";
import useVoucherStyles from "../Voucher/hooks/useVoucherStyles";
import { CREATE_VOUCHER_MUTATION } from "../Voucher/VoucherForm";
const url = process.env.NODE_ENV === "development" ? api : cloudApi;

const statusEnum = {
  damage: "Damaged Product",
  product: "Wrong Product",
  color: "Wrong Color",
  quality: "Bad Quality",
};
const schema = yup.object({
  // voucherName: yup.string().required("Voucher Name is Required"),
  email: yup.string().email().required("Please enter a valid email address"),
  price: yup.number().required("Price is required"),
  validMonth: yup.number().required("Please select a valid month"),
});
interface Props {}

const ReturnOrder: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/returnorder/:id");
  //@ts-ignore
  const returnOrderhID = match?.params.id;
  const [returnOrders, setReturnOrders] = React.useState<any>([]);
  const [returnOrderds, setReturnOrderds] = React.useState([]);
  const [submitForm] = useMutation(CREATE_VOUCHER_MUTATION);
  const classes = useVoucherStyles({});
  const { displayPopMessage } = useMessageState();

  const [loading, setLoading] = React.useState(true);
  const issueVoucher = async (values: any) => {
    const data = {
      active: true,
      price: values.price,
      returnorderh: returnOrderhID,
      validMonth: values.validMonth,
      email: values.email,
    };
    try {
      setLoading(true);
      await submitForm({
        variables: {
          input: {
            data,
          },
        }, // pass data
      });
      setLoading(false);
      displayPopMessage(`Voucher Added Successfully`);
      getReturns();
    } catch (err) {
      console.log(err);
    }
  };
  const getReturns = async () => {
    const res = await Axios.get(`${api}/returnorderhs?id=${returnOrderhID}`, {
      withCredentials: true,
    });
    console.log(res.data);
    setReturnOrders(res.data);
    const res2 = await Axios.get(
      `${api}/returnorderds?returnorderh=${returnOrderhID}`,
      {
        withCredentials: true,
      }
    );
    setReturnOrderds(res2.data);
    setLoading(false);
  };
  React.useEffect(() => {
    getReturns();
  }, []);
  const handleStatusChange = async (id: number) => {
    if (window.confirm("Are You Sure?")) {
      try {
        await Axios.post(
          `${url}/returnorderhs/set-order-status`,
          { returnOrderhID: returnOrderhID, status: id },
          { withCredentials: true }
        );

        alert("Successfully updated");
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    }
    getReturns();
  };
  if (loading) return <Loading />;
  return (
    <Box>
      <GoBackButton />
      <Box position="relative" marginTop="10px">
        <Box position="absolute" right="10px" top="10px">
          {returnOrders[0].status.id == 13 && (
            <Button
              marginLeft="5px"
              onClick={() => handleStatusChange(14)}
              colorScheme="green"
            >
              Accept Return
            </Button>
          )}

          {returnOrders[0].status.id == 14 && (
            <Button
              marginLeft="5px"
              onClick={() => handleStatusChange(16)}
              colorScheme="yellow"
            >
              Return Received
            </Button>
          )}
          {returnOrders[0].status.id == 16 && (
            <Button
              marginLeft="5px"
              onClick={() => handleStatusChange(17)}
              colorScheme="purple"
            >
              Return Settled
            </Button>
          )}

          {returnOrders[0].status.id == 16 &&
          returnOrders[0].voucher == null ? (
            <Popover>
              <PopoverTrigger>
                <Button marginLeft="5px" colorScheme="blue">
                  Issue Voucher
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Enter Coupon Value</PopoverHeader>
                <PopoverBody>
                  <Formik
                    onSubmit={(values) => issueVoucher(values)}
                    initialValues={{
                      price: 0,
                      validMonth: 0,
                      email: "",
                    }}
                    validationSchema={schema}
                  >
                    {({ values }) => (
                      <Form className={classes.form}>
                        {/* {JSON.stringify(values)} */}
                        <Field
                          name="validMonth"
                          component={TextFormField}
                          label="No. of valid months from effective date"
                          fullWidth
                          variant="outlined"
                          type="number"
                        />
                        <Field
                          name="price"
                          component={TextFormField}
                          label="Price"
                          fullWidth
                          variant="outlined"
                          type="number"
                        />
                        <Field
                          name="email"
                          component={TextFormField}
                          label="Email"
                          fullWidth
                          variant="outlined"
                          type="email"
                        />

                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                        <SubmitBtn loading={loading} w="100%" mb="3" />
                      </Form>
                    )}
                  </Formik>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : (
            <></>
          )}
          <Button
            marginLeft="5px"
            colorScheme="red"
            onClick={() => handleStatusChange(15)}
          >
            Reject Return
          </Button>
        </Box>
        <Box bgColor="white" p="10px">
          <Heading fontSize="xl">Return ID: </Heading>
          <Text>{returnOrders[0].id}</Text>
          <Heading fontSize="xl">Order Code: </Heading>
          <Text>{returnOrders[0].orderCode}</Text>
          <Heading fontSize="xl">Reason: </Heading>
          <Text>
            {
              //@ts-ignore
              statusEnum[returnOrders[0].reason]
            }
          </Text>
          <Heading fontSize="xl">Return Status: </Heading>
          <Text>{returnOrders[0].status.type}</Text>
          <Heading fontSize="xl">User: </Heading>
          <Text>{returnOrders[0].user.username}</Text>
          <Heading fontSize="xl">User Email: </Heading>
          <Text>{returnOrders[0].user.email}</Text>
          <Heading fontSize="xl">Issued Coupon: </Heading>
          {returnOrders[0].voucher ? (
            <Text>
              {returnOrders[0].voucher.id +
                " => " +
                returnOrders[0].voucher.code +
                " => " +
                returnOrders[0].voucher.active}
            </Text>
          ) : (
            <Text>No Coupons Issues</Text>
          )}
        </Box>
        <Box marginTop="10px" bgColor="white" p="10px">
          <Heading fontSize="xl">Returned Items</Heading>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Product</Th>
                <Th>Color</Th>
                <Th isNumeric>Qty</Th>
                <Th isNumeric>Discount</Th>
                <Th isNumeric>Price</Th>
              </Tr>
            </Thead>
            <Tbody>
              {returnOrderds.map((item: any) => {
                return (
                  <Tr>
                    <Td>{item.product.productName}</Td>
                    <Td>{item.color.colorName}</Td>
                    <Td isNumeric>{item.qty}</Td>
                    <Td isNumeric> {item.discount} </Td>
                    <Td isNumeric> {item.price} </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};
export default ReturnOrder;
