import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { apiLink } from "../../config";
import Loading from "../Misc/Loading";

interface Props {}

type Option = "PHD" | "PPS" | "COD";

const DeliveryOptions: React.FC<Props> = () => {
  const toast = useToast();

  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(true);

  const getDelOptions = async () => {
    const res = await Axios.get(`${apiLink}/stores/delivery-options`, {
      withCredentials: true,
    });

    // console.log(res.data);
    setOptions(res.data.map((opt: any) => opt.option));

    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!options.length) {
      toast({
        title: "Please select at least one option",
        status: "warning",
        isClosable: true,
      });
      return;
    }

    const data = {
      options,
    };

    try {
      const res = await Axios.post(
        `${apiLink}/deloptions/update-options`,
        data,
        { withCredentials: true }
      );
      toast({
        title: "Delivery Options Updated",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getDelOptions();
  }, []);

  if (loading) return <Loading />;

  return (
    <Box bg="gray.50" shadow="md" w="100%" p="5">
      <Heading fontSize="3xl" mb="4">
        Delivery Options
      </Heading>
      <CheckboxGroup
        colorScheme="blue"
        defaultValue={options}
        onChange={(values: Option[]) => setOptions(values)}
      >
        <Stack spacing="3">
          <Checkbox textColor="gray.600" value="PHD">
            Pay & Home Delivery
          </Checkbox>
          <Checkbox textColor="gray.600" value="PPS">
            Pick From Store
          </Checkbox>
          <Checkbox textColor="gray.600" value="COD">
            Cash On Delivery
          </Checkbox>
        </Stack>
      </CheckboxGroup>
      <Button colorScheme="teal" size="sm" my="3" onClick={handleSubmit}>
        Update
      </Button>
    </Box>
  );
};

export default DeliveryOptions;
