import { ApolloClient } from "@apollo/client";

export default (regex: RegExp, client: ApolloClient<object>) => {
  //@ts-ignore
  Object.keys(client.cache.data.data).forEach(key => {
    if (key.match(regex)) {
      //@ts-ignore
      client.cache.data.delete(key);
    }
  });
};
