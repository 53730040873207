import * as React from "react";
import GoBackButton from "../../Misc/GoBackButton";
import FilterBox from "../ProductWise/FilterBox";
import AbundantCarts from "./AbundantCarts";
import DeliveryExceed from "./DeliveryExceed";
import IncompleteCheckouts from "./IncompleteCheckouts";
import OrdersCompareDelivery from "./OrdersCompareDelivery";
import OrdersDistrict from "./OrdersDistrict";
import OrdersForecast from "./OrdersForecast";
import OrdersGrowth from "./OrdersGrowth";

interface Props {}

const OrderWise: React.FC<Props> = () => {
  const today = new Date(Date.now()).toISOString().split("T")[0];
  const [fromDate, setFromDate] = React.useState(
    localStorage.getItem("OrderWiseFromDate") ||
      new Date(today.slice(0, 7)).toISOString().split("T")[0]
  );
  const [toDate, setToDate] = React.useState(
    localStorage.getItem("OrderWiseToDate") || today
  );
  const [orderBy, setOrderBy] = React.useState(
    localStorage.getItem("OrderWiseOrderBy") || "DESC"
  );

  React.useEffect(() => {
    localStorage.setItem("OrderWiseFromDate", fromDate);
    localStorage.setItem("OrderWiseToDate", toDate);
    localStorage.setItem("OrderWiseOrderBy", orderBy);
  }, [fromDate, toDate, orderBy]);
  return (
    <>
      <GoBackButton />
      <FilterBox
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "50% 50%",
          gridColumnGap: "20px",
        }}
      >
        <OrdersForecast />
        <OrdersDistrict fromDate={fromDate} toDate={toDate} orderBy={orderBy} />
      </div>
      <div>
        <OrdersCompareDelivery
          fromDate={fromDate}
          toDate={toDate}
          orderBy={orderBy}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "50% 50%",
          gridColumnGap: "20px",
        }}
      >
        <DeliveryExceed fromDate={fromDate} toDate={toDate} orderBy={orderBy} />
        <IncompleteCheckouts
          fromDate={fromDate}
          toDate={toDate}
          orderBy={orderBy}
        />
        <AbundantCarts fromDate={fromDate} toDate={toDate} orderBy={orderBy} />
        <OrdersGrowth />
      </div>
    </>
  );
};

export default OrderWise;
