import { gql, useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { Tree } from "antd";
import type { DataNode, TreeProps } from "antd/es/tree";
import * as React from "react";
import { useHistory } from "react-router-dom";

interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: any;
}

const ProductTreeView: React.FC<Props> = ({ values, setFieldValue }) => {
  const history = useHistory();
  const { refetch: brandRefetch, data: brandData } = useQuery(
    BRANDS_PRODUCTS_QUERY,
    {}
  );
  const { refetch: categoryRefetch, data: CategoryData } = useQuery(
    CATEGORY_PRODUCTS_QUERY,
    {}
  );
  const [productTree, setProductTree] = React.useState<DataNode[]>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>(
    values.products
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    setLoading(true);
    if (values.type === "brand" && brandData) {
      const treeData: DataNode[] = brandData.brandsConnection.values.map(
        (brand: any) => {
          return {
            title: brand.brandName,
            key: "B" + brand.id,
            children: brand.products.map((product: any) => {
              return {
                title: product.productName,
                key: product.id.toString(),
              };
            }),
          };
        }
      );
      setProductTree(treeData);
    }
    if (values.type === "category" && CategoryData) {
      const treeData: DataNode[] = CategoryData.categoriesConnection.values.map(
        (category: any) => {
          return {
            title: category.categoryName,
            key: "C" + category.id,
            children: category.products.map((product: any) => {
              return {
                title: product.productName,
                key: product.id.toString(),
              };
            }),
          };
        }
      );
      setProductTree(treeData);
    }
    setLoading(false);
  }, [brandData, CategoryData, values.type]);

  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  const onCheck: TreeProps["onCheck"] = (checkedKeys, info) => {
    console.log("onCheck", checkedKeys, info);
    const checkKeys = checkedKeys as string[];
    const productsSet = checkKeys.filter(
      (key: string) => key.toString()[0] !== "B" && key.toString()[0] !== "C"
    );
    setSelectedProducts(productsSet);
    setFieldValue("products", productsSet);
  };
  if (loading) return <Box>Loading...</Box>;
  else
    return (
      <Box my="3">
        {/* {selectedProducts} */}
        <Tree
          checkable
          // defaultExpandedKeys={["0-0-0", "0-0-1"]}
          // defaultSelectedKeys={["0-0-0", "0-0-1"]}
          checkedKeys={selectedProducts}
          onSelect={onSelect}
          onCheck={onCheck}
          treeData={productTree}
          // checkedKeys={selectedProducts}
        />
      </Box>
    );
};

export default ProductTreeView;

export const BRANDS_PRODUCTS_QUERY = gql`
  query BRANDS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    brandsConnection(start: $start, limit: $limit, sort: $sort, where: $where) {
      aggregate {
        totalCount
      }
      values {
        id
        brandName
        products {
          id
          productName
        }
      }
    }
  }
`;

export const CATEGORY_PRODUCTS_QUERY = gql`
  query CATEGORY_PRODUCTS_QUERY(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    categoriesConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        categoryName
        products {
          id
          productName
        }
      }
    }
  }
`;
