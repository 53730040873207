import { Heading } from "@chakra-ui/layout";
import React from "react";
import { Orderh, OrderQuery } from "../../generated/graphql";
import BillingDetails from "./BillingDetails";
import useStyles from "./hooks/useStyles";
import MainDetails from "./MainDetails";
import OrderItems from "./OrderItems";
import PaymentSelection from "./PaymentSelection";
import ShippingDetails from "./ShippingDetails";

interface Props {
  order: OrderQuery["orderh"];
}

const Order: React.FC<Props> = ({ order }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Heading fontSize="2xl" className={classes.heading}>
        Order No: {order?.orderCode} ({order?.user?.email})
      </Heading>

      <div className={classes.header}>
        <MainDetails
          orderCode={order?.orderCode}
          created_at={order?.created_at}
          netAmt={order?.netAmt}
          paidAmount={order?.paidAmount}
          status={order?.status?.type}
          id={order?.id ?? ""}
        />
        <OrderItems orderds={order?.orderds as Orderh["orderds"]} />
      </div>
      <div className={classes.midSec}>
        <ShippingDetails order={order} />
        <BillingDetails order={order} />
        <PaymentSelection order={order} />
      </div>
    </div>
  );
};

export default Order;
