import { Box, Container } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const MarketingDashboard = () => {
  const role = sessionStorage.getItem("role");
  const username = sessionStorage.getItem("username");
  const history = useHistory();

  return (
    <>
      <Container centerContent>Welcome</Container>
      <Box></Box>
    </>
  );
};

export default MarketingDashboard;
