import Axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import * as React from "react";
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { api } from "../../../config";
import { TableTitle } from "../ProductWise/FilterBox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const dummyData = {
  datasets: [
    {
      label: "Dataset 1",
      data: [1, 2],
    },
  ],
};
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Orders Growth (last 5 days)",
      font: {
        size: 20,
      },
    },
  },
};

interface Props {}

const OrdersGrowth: React.FC<Props> = () => {
  const history = useHistory();
  const [chartData, setChartData] = React.useState(dummyData);

  const getChartData = async () => {
    const res = await Axios.get(`${api}/orderhs/order-growth`, {
      withCredentials: true,
    });
    const dataArray = res.data.reverse();
    const labels = dataArray.map((item: any) => item.daily);
    const data = {
      labels,
      datasets: [
        {
          label: "Number of orders",
          data: dataArray.map((item: any) => item.coalesce),
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
        },
      ],
    };
    setChartData(data);
    // console.log(res.data);
  };
  React.useEffect(() => {
    getChartData();
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: 20,
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <TableTitle
          title="Orders Growth (last 5 days)"
          backgroundColorIndex={3}
          ColorIndex={0}
        />
        <div
          style={{
            padding: "20px",
          }}
        >
          <div style={{ padding: "10px" }}></div>
          <Line options={options} data={chartData} />
        </div>
      </div>
    </>
  );
};

export default OrdersGrowth;
