import Axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { api } from "../../../config";
import { TableTitle } from "../ProductWise/FilterBox";

interface Props {
  fromDate: string;
  toDate: string;
  orderBy: string;
}

const AbundantCarts: React.FC<Props> = ({ fromDate, toDate, orderBy }) => {
  const [orders, setOrders] = React.useState<any>([]);
  const getOrders = async () => {
    const res = await Axios.get(
      `${api}/orderhs/abundant-carts?updated_at_gte=${fromDate}&updated_at_lte=${toDate}&user_null=f&_sort=user:${orderBy}`,
      {
        withCredentials: true,
      },
    );
    // console.log(res.data);
    const data = res.data;
    let tempElement: any;
    let finalData = [];
    if (data.length > 0) {
      tempElement = {
        user: data[0].user.id,
        username: data[0].user.username,
        email: data[0].user.email,
        date: data[0].updated_at.substring(0, 10),
        dateMilli: new Date(data[0].updated_at).getTime(),
        product: [data[0].product.productName],
      };
      if (data.length === 1) finalData.push(tempElement);
    }
    for (let index = 1; index < data.length; index++) {
      const element = data[index];
      if (element.user.id === tempElement.user) {
        tempElement.product.push(element.product.productName);
      } else {
        finalData.push(tempElement);
        tempElement = {
          user: element.user.id,
          username: element.user.username,
          email: element.user.email,
          number: element.user.customer.phoneNumber,
          date: element.updated_at.substring(0, 10),
          dateMilli: new Date(element.updated_at).getTime(),
          product: [element.product.productName],
        };
      }
      if (index === data.length - 1) finalData.push(tempElement);
      // console.log(finalData);
    }
    // console.log(finalData);
    finalData.sort((a, b) => a.dateMilli - b.dateMilli);
    if (orderBy === "DESC") finalData.reverse();
    // console.log(finalData);
    setOrders(finalData);
  };
  React.useEffect(() => {
    getOrders();
  }, [fromDate, toDate, orderBy]);
  return (
    <>
      <div style={{ marginTop: 20, backgroundColor: "white" }}>
        <TableTitle
          title="Abundant Carts"
          backgroundColorIndex={1}
          ColorIndex={0}
        />
        <MaterialTable
          columns={[
            { title: "Customer Name", field: "username" },
            { title: "Customer Email", field: "email" },
            { title: "Phone Number", field: "number" },
            { title: "Date", field: "date" },
            {
              title: "Products",
              field: "products",
              render: (rowData: any) => {
                return (
                  <div>
                    {rowData.product.map((product: any) => (
                      <li>{product}</li>
                    ))}
                  </div>
                );
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            exportButton: true,
            debounceInterval: 500,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50],
            // toolbar: false,
          }}
          data={orders}
        />
      </div>
    </>
  );
};

export default AbundantCarts;