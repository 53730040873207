import AccessTime from "@material-ui/icons/AccessTime";
import React from "react";
import {
  dailySalesChart,
  monthlySalesChart,
} from "../../../variables/charts.js";

export type ChartData = {
  color: string;
  type: string;
  options: any;
  objName: string;
  listener: any;
  title: string;
  footerIcon: React.ReactNode;
  footerTitle: string;
};

const chartsData: ChartData[] = [
  {
    color: "success",
    type: "Line",
    options: { ...dailySalesChart.options },
    listener: dailySalesChart.animation,
    objName: "salesChartData",
    title: "Daily Sales",
    footerIcon: <AccessTime />,
    footerTitle: "This Week",
  },
  {
    color: "danger",
    type: "Bar",
    options: { ...monthlySalesChart.options },
    listener: monthlySalesChart.animation,
    objName: "monthlySalesData",
    title: "Monthly Sales",
    footerIcon: <AccessTime />,
    footerTitle: "This Year",
  },
];

export default chartsData;
