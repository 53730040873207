import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

interface Category {
  id: string;
  categoryName: string;
}

const useCategories = () => {
  const { data, loading, error } = useQuery(CATEGORIES_QUERY);
  return {
    categoryData: data as {
      categoriesConnection: {
        values: Category[];
      };
    },
    categoryLoading: loading,
    categoryError: error,
  };
};

export default useCategories;

export const mapCategories = (categoryData: {
  categoriesConnection: { values: Category[] };
}) => {
  const mappedCategories = categoryData.categoriesConnection.values.map(
    (category) => ({
      id: category.id,
      label: category.categoryName,
      value: category.categoryName,
    })
  );
  return mappedCategories;
};

export const CATEGORIES_QUERY = gql`
  query CATEGORIES_QUERY(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    categoriesConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        categoryName
        surcharge
        inactive
      }
    }
  }
`;
