import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useContacts = () => {
  const { data, loading, error } = useQuery(CONTACTS_QUERY);
  return {
    contactData: data,
    contactLoading: loading,
    contactError: error,
  };
};

export default useContacts;

export const mapContacts = (contactData: any) => {
  const mappedContacts = contactData.contactsConnection.values.map(
    (contact: { name: string; __typename: string; id: number }) => ({
      id: contact.id,
      label: contact.name,
      value: contact.name,
    })
  );
  return mappedContacts;
};

export const CONTACTS_QUERY = gql`
  query CONTACTS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    contactsConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        name
        email
        phone
        message
        created_at
      }
    }
  }
`;
