import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useBrands = () => {
  const { data, loading, error } = useQuery(BRANDS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  return {
    brandData: data,
    brandLoading: loading,
    brandError: error,
  };
};

export default useBrands;

export const mapBrands = (brandData: any) => {
  const mappedBrands = brandData.brandsConnection.values.map(
    (brand: { brandName: string; __typename: string; id: number }) => ({
      id: brand.id,
      label: brand.brandName,
      value: brand.brandName,
    })
  );
  return mappedBrands;
};

export const BRANDS_QUERY = gql`
  query BRANDS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    brandsConnection(start: $start, limit: $limit, sort: $sort, where: $where) {
      aggregate {
        totalCount
      }
      values {
        id
        brandName
      }
    }
  }
`;
