import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import BlogForm from "../components/Blog/BlogForm";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";

interface Props {}

const BlogFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/blog-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(BLOG_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { blog } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <BlogForm blog={blog} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <BlogForm />
      </div>
    );
  }
};

export default BlogFormPage;

export const BLOG_QUERY = gql`
  query BLOG_QUERY($id: ID!) {
    blog(id: $id) {
      id
      title
      abstract
      content
      slug
      is_active
      image
    }
  }
`;
