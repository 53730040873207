import { Input } from "@chakra-ui/input";
import { Box, Text } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import * as React from "react";
import useContactStyles from "./hooks/useContactStyles";

interface Props {
  contact: {
    id: string;
    name: string;
    email: string;
    phone: string;
    message: string;
    created_at: string;
  };
}

const ContactForm: React.FC<Props> = ({ contact }) => {
  const classes = useContactStyles({});
  const createdat = new Date(contact?.created_at);
  console.log(contact);
  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Box className={classes.form}>
              <h3 className={classes.heading}>Inquery</h3>
              <Text marginTop="10" fontSize="16">
                Contact Name
              </Text>
              <Input
                placeholder="Contact Name"
                variant="outline"
                value={contact?.name}
                readOnly
              />
              <Text marginTop="10" fontSize="16">
                Date and time
              </Text>
              <Input
                placeholder="Date"
                variant="outline"
                value={
                  createdat.getFullYear() +
                  "-" +
                  createdat.getMonth() +
                  "-" +
                  createdat.getDate() +
                  "     " +
                  createdat.getHours() +
                  ":" +
                  createdat.getMinutes()
                }
                readOnly
              />
              <Text marginTop="10" fontSize="16">
                Contact Email
              </Text>
              <Input
                placeholder="Email"
                variant="outline"
                value={contact?.email}
                readOnly
              />
              <Text marginTop="10" fontSize="16">
                Phone
              </Text>
              <Input
                placeholder="Phone"
                variant="outline"
                value={contact?.phone}
                readOnly
              />
              <Text marginTop="10" fontSize="16">
                Message
              </Text>
              <Textarea
                placeholder="Message"
                variant="outline"
                value={contact?.message}
                readOnly
                marginBottom="20"
              />
            </Box>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default ContactForm;
