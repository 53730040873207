import React from "react";
import ViewStores from "../components/Store/ViewStores";

interface Props {}

const StoresPage: React.FC<Props> = () => {
  return <ViewStores />;
};

export default StoresPage;
