import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { apiLink } from "../../config";
import Loading from "../Misc/Loading";

interface Props {}

const DeliveryLocations: React.FC<Props> = () => {
  const toast = useToast();

  const [limitedCats, setLimitedCats] = useState([]);
  const [limitedIds, setLimitedIds] = useState([]);

  const [otherCats, setOtherCats] = useState([]);
  const [otherIds, setOtherIds] = useState([]);

  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDelLocations = async () => {
    const res = await Axios.get(`${apiLink}/stores/delivery-locations`, {
      withCredentials: true,
    });

    // console.log(res.data);
    setLimitedCats(res.data.limited.cats);
    setLimitedIds(res.data.limited.disIds);

    setOtherCats(res.data.other.cats);
    setOtherIds(res.data.other.disIds);

    setDistricts(res.data.districts);
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!limitedIds.length) {
      toast({
        title: "Please select at least one District for each Category",
        status: "warning",
        isClosable: true,
      });
      return;
    }

    if (!otherIds.length) {
      toast({
        title: "Please select at least one District for each Category",
        status: "warning",
        isClosable: true,
      });
      return;
    }

    const data = {
      limitedIds,
      otherIds,
    };

    try {
      const res = await Axios.post(
        `${apiLink}/dellocations/update-locations`,
        data,
        { withCredentials: true }
      );
      toast({
        title: "Locations Updated",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed",
        description: error.response.data.message,
        status: "error",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getDelLocations();
  }, []);

  if (loading) return <Loading />;

  return (
    <Box bg="gray.50" shadow="md" w="100%" p="5">
      <Heading fontSize="3xl" mb="4">
        Delivery Locations
      </Heading>
      <Flex w="100%">
        <Box flex="1">
          <Heading fontSize="lg" mb="3" fontWeight="medium">
            {limitedCats.join(",")}
          </Heading>
          <CheckboxGroup
            defaultValue={limitedIds}
            // value={limitedIds}
            onChange={(values: any) => setLimitedIds(values)}
          >
            {districts.map((dis: any) => (
              <Box>
                <Checkbox value={dis.id.toString()} color="gray.500" my="1">
                  {dis.districtName}
                </Checkbox>
              </Box>
            ))}
          </CheckboxGroup>
        </Box>
        <Divider orientation="vertical" />
        <Box flex="1">
          <Heading fontSize="lg" mb="3" fontWeight="medium">
            {otherCats.join(",")}
          </Heading>
          <CheckboxGroup
            defaultValue={otherIds}
            onChange={(values: any) => {
              setOtherIds(values);
            }}
          >
            {districts.map((dis: any) => (
              <Box>
                <Checkbox value={dis.id.toString()} color="gray.500" my="1">
                  {dis.districtName}
                </Checkbox>
              </Box>
            ))}
          </CheckboxGroup>
        </Box>
      </Flex>
      <Button colorScheme="blue" w="100%" mt="2" onClick={handleSubmit}>
        Update
      </Button>
    </Box>
  );
};

export default DeliveryLocations;
