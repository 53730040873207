import { Box } from "@chakra-ui/layout";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import FileDownload from "js-file-download";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import Loading2 from "../components/Misc/Loading2";
import Order from "../components/Order";
import { api, cloudApi } from "../config";
import { Orderh, useOrderQuery } from "../generated/graphql";
import { CBRoles } from "../utils/misc";

const url = process.env.NODE_ENV === "development" ? api : cloudApi;

interface Props {}

const OrderPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/order/:id");
  const history = useHistory();
  const [loading2, setLoading2] = React.useState(false);
  //@ts-ignore
  const id = match?.params.id;
  const role = sessionStorage.getItem("role");

  const { data, loading, error, refetch } = useOrderQuery({
    variables: {
      id: id,
    },
  });

  const handleClick = () => {
    history.push({
      pathname: `/admin/alocation-form/${id}`,
    });
  };

  const handleStatusChange = async (id: number) => {
    if (window.confirm("Are You Sure?")) {
      try {
        await Axios.post(
          `${url}/orderhs/set-order-status`,
          { orderId: data?.orderh?.id, status: id },
          { withCredentials: true }
        );

        await refetch({ id: data?.orderh?.id });
        alert("Successfully updated");
      } catch (err: any) {
        console.log(err);
        alert(err.message);
      }
    }
  };

  const handleCompleteClick = async () => {
    if (window.confirm("Are You Sure?(Completed)")) {
      try {
        await fetch(`${url}/orderhs/set-order-complete-reject`, {
          body: JSON.stringify({
            status: "COMPLETED",
            orderId: data?.orderh?.id,
          }),
          method: "POST",
          credentials: "include",
        });
        await refetch({ id: data?.orderh?.id });
      } catch (err: any) {
        console.log(err);
        alert(err.message);
      }
    }
  };

  const handleRejectClick = async () => {
    if (window.confirm("Are You Sure?(Rejected)")) {
      try {
        await fetch(`${url}/orderhs/set-order-complete-reject`, {
          body: JSON.stringify({
            status: "REJECTED",
            orderId: data?.orderh?.id,
          }),
          method: "POST",
          credentials: "include",
        });
        await refetch({ id: data?.orderh?.id });
      } catch (err: any) {
        console.log(err);
        alert(err.message);
      }
    }
  };

  const downloadPDF = async (order: any) => {
    setLoading2(true);
    const res = await Axios.get(
      `${api}/orderhs/download-dispatch?id=${order}`,
      {
        responseType: "blob",
        withCredentials: true,
      }
    );
    FileDownload(res.data, `dispatchNote-${data?.orderh?.orderCode}.pdf`);
    setLoading2(false);
    // console.log(res);
  };
  const downloadStoreRecipt = async (order: any) => {
    setLoading2(true);
    const res = await Axios.get(
      `${api}/orderhs/download-store-receipt?id=${order}`,
      {
        responseType: "blob",
        withCredentials: true,
      }
    );
    FileDownload(res.data, `customer-receipt-${data?.orderh?.orderCode}.pdf`);
    setLoading2(false);
    // console.log(res);
  };
  const downloadInvoice = async (order: any) => {
    setLoading2(true);
    const res = await Axios.get(`${api}/orderhs/download-invoice?id=${order}`, {
      responseType: "blob",
      withCredentials: true,
    });
    FileDownload(res.data, `invoice-${data?.orderh?.orderCode}.pdf`);
    setLoading2(false);
    // console.log(res);
  };

  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  const selection = data?.orderh?.paymentselections?.[0];
  const status = data?.orderh?.status?.id;
  const orderItems = data?.orderh?.orderds;
  let showType = "NONE";

  if (selection?.method === "PPS") {
    if (![9, 12].includes(parseInt(status as any))) {
      showType = "COMPREJ";
    }
  } else {
    if (![9, 12].includes(parseInt(status as any))) {
      showType = "ALO";
    }
  }

  if (!id) {
    return <div>No Id Specified</div>;
  } else {
    return (
      <div>
        <GoBackButton />
        {CBRoles(role) && (
          <Box>
            {status != "12" && (
              <Button
                variant="contained"
                color="secondary"
                style={{ float: "right", marginLeft: "10px" }}
                onClick={() => handleStatusChange(12)}
              >
                Cancel Order
              </Button>
            )}
            {status == "10" || status == "11" ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right", marginLeft: "10px" }}
                  onClick={() => downloadStoreRecipt(id)}
                >
                  Download Customer Receipt
                </Button>
              </>
            ) : (
              <></>
            )}
            {status == "11" ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right", marginLeft: "10px" }}
                  onClick={() => handleStatusChange(18)}
                >
                  Ready to pickup from store
                </Button>
              </>
            ) : (
              <></>
            )}
            {status == "8" || status == "18" ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                  onClick={() => handleStatusChange(9)}
                >
                  Delivered
                </Button>
              </>
            ) : status == "7" ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right" }}
                  onClick={() => handleStatusChange(8)}
                >
                  Shipped
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: "right", marginRight: "10px" }}
                  onClick={() => downloadPDF(id)}
                >
                  Download Dispatch
                </Button>
              </>
            ) : (
              <></>
            )}
            {status == "12" || status == "6" ? (
              <Button
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={handleClick}
              >
                Allocate
              </Button>
            ) : (
              <></>
            )}
          </Box>
        )}
        {role === "Cashier" && (status == "8" || status == "11") && (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={() => handleStatusChange(9)}
            >
              Delivered
            </Button>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ float: "right", marginRight: "10px" }}
          onClick={() => downloadInvoice(id)}
        >
          Download Invoice
        </Button>
        <div style={{ clear: "both" }} />
        <Order order={data?.orderh} />;
        <Loading2 loading={loading2} />
      </div>
    );
  }
};

export default OrderPage;

function areAllItemsAlocated(items: Orderh["orderds"]) {
  let alocated = false;
  // let alocated = true;
  // for (const item of items || []) {
  //   if (!item?.deliveryPerson) {
  //     alocated = false;
  //     break;
  //   }
  // }
  return alocated;
}
