import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useBlogs = () => {
  const { data, loading, error } = useQuery(BLOGS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  return {
    blogData: data,
    blogLoading: loading,
    blogError: error,
  };
};

export default useBlogs;

export const BLOGS_QUERY = gql`
  query BLOGS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    blogsConnection(start: $start, limit: $limit, sort: $sort, where: $where) {
      aggregate {
        totalCount
      }
      values {
        id
        title
        slug
        content
        abstract
        is_active
      }
    }
  }
`;
