import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import NewsletterForm from "../components/Newsletter/NewsletterForm";

interface Props {}

const NewsletterFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/newsletter-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(NEWSLETTER_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { newsletter } = data;
    // console.log(newsletter);
    return (
      <div>
        <GoBackButton />
        <NewsletterForm newsletter={newsletter} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <NewsletterForm />
      </div>
    );
  }
};

export default NewsletterFormPage;

export const NEWSLETTER_QUERY = gql`
  query NEWSLETTER_QUERY($id: ID!) {
    newsletter(id: $id) {
      id
      email
    }
  }
`;
