import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import WarrentyForm from "../components/Warrenty/WarrentyForm";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";

interface Props {}

const WarrantyFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/warrenty-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(WARRANTY_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { warranty } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <WarrentyForm warranty={warranty} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <WarrentyForm />
      </div>
    );
  }
};

export default WarrantyFormPage;

export const WARRANTY_QUERY = gql`
  query WARRANTY_QUERY($id: ID!) {
    warranty(id: $id) {
      id
      warranty
      inactive
    }
  }
`;
