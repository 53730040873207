import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import CheckboxFormField from "../components/Misc/FormComponents/CheckboxFormField";
import ReactSelectFormField from "../components/Misc/FormComponents/ReactSelectFormField";
import SubmitBtn from "../components/Misc/FormComponents/SubmitBtn";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import Money from "../components/Misc/Money";
import {
  OrderDocument,
  OrderItemsDocument,
  useAlocateDeliveriesMutation,
  useDeliveryUsersQuery,
  useOrderItemsQuery,
} from "../generated/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "0 auto",
    },
    table: {
      "& th, td": {
        padding: 5,
      },
      "& th": {
        textAlign: "left",
      },
    },
  })
);

interface Props {}

const AlocationFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/alocation-form/:id");
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  //@ts-ignore
  const id = match?.params.id;

  const {
    data: items,
    loading: itemsLoading,
    error: itemsError,
    refetch: itemsRefetch,
  } = useOrderItemsQuery({
    variables: {
      where: {
        orderh: id,
        // deliveryPerson_null: true,
      },
    },
    skip: !id,
  });

  const {
    data: delUsers,
    loading: delUsersLoading,
    error: delUsersError,
  } = useDeliveryUsersQuery({
    variables: {
      where: {
        role: {
          name: "DeliveryPerson",
        },
      },
    },
  });

  const [alocateDeliveries] = useAlocateDeliveriesMutation({
    refetchQueries: [
      {
        query: OrderItemsDocument,
        variables: {
          where: {
            orderh: id,
            deliveryPerson_null: true,
          },
        },
      },
      {
        query: OrderDocument,
        variables: {
          id: id,
        },
      },
    ],
  });

  const handleSubmit = async (values: any) => {
    if (!values.deliveryPerson) {
      alert("Please select a delivery person");
      return;
    }

    if (
      values.orderItems.filter((item: any) => item.selected === true).length ===
      0
    ) {
      alert("Please select products");
      return;
    }

    console.log(values);

    try {
      setLoading(true);
      await alocateDeliveries({
        variables: {
          deliveryPerson: values.deliveryPerson,
          orderItems: values.orderItems,
          orderh: id,
        },
      });
      alert("Successfully updated");
      setLoading(false);
    } catch (err) {
      alert(err.message + id);
      setLoading(false);
    }
    await itemsRefetch();
  };

  if (delUsersError || itemsError) return <div>Error</div>;
  if (itemsLoading || delUsersLoading) return <Loading />;
  if (!id) {
    return <div>No Id Specified</div>;
  } else {
    const initValues = {
      deliveryPerson: "",
      orderItems: items?.orderds?.map((d) => {
        let newProductName: any = "";
        if (d?.product) {
          newProductName = d?.product.productName;
          if (d?.color) {
            d?.product?.color_cats?.forEach((item) => {
              if (item?.color?.id === d?.color?.id) {
                newProductName = `${newProductName} (${item?.color?.colorName})`;
              }
            });
          }
        }

        return {
          id: d?.id,
          itemName: newProductName,
          selected: false,
        };
      }),
    };
    return (
      <div className={classes.root}>
        <GoBackButton />
        <h3>Allocate Delivery Person</h3>

        <Formik
          initialValues={initValues}
          enableReinitialize={true}
          onSubmit={(values: any) => {
            handleSubmit(values);
            values.resetForm(initValues);
          }}
        >
          {({ isSubmitting, values, errors, setFieldValue }) => (
            <Form>
              <Field
                component={ReactSelectFormField}
                options={delUsers?.usersConnection?.values?.map((user) => ({
                  value: user?.id,
                  label: user?.username,
                }))}
                placeholder="Select Delivery Person"
                name="deliveryPerson"
              />

              <Field
                label="Select All"
                name="selectAll"
                // defaultChecked={isEdit ? product?.featuredItem : false}
                component={CheckboxFormField}
                onChange={(e: any) => {
                  values.orderItems?.forEach((item, index) =>
                    setFieldValue(
                      `orderItems.${index}.selected`,
                      e.target.checked
                    )
                  );
                }}
              />
              <br />
              <FieldArray name="orderItems">
                {(arrayHelpers) => (
                  <Paper>
                    <table className={classes.table}>
                      <thead>
                        <tr>
                          <th>Product/Package Name</th>
                          <th>Qty</th>
                          <th>Price</th>
                          <th>Value</th>
                          <th>Category</th>
                          <th>Current Delivery Person</th>
                          {/* <th>Select</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {items?.orderds?.map((d, i) => {
                          let newProductName = "";
                          if (d?.product) {
                            newProductName = d?.product.productName;
                            if (d.color) {
                              d?.product?.color_cats?.forEach((item) => {
                                if (item?.color?.id === d?.color?.id) {
                                  newProductName = `${newProductName} (${item?.color?.colorName})`;
                                }
                              });
                            }
                          }
                          return (
                            <tr key={d?.id + " " + i}>
                              <td>{newProductName}</td>
                              <td>{d?.qty}</td>
                              <td>
                                <Money
                                  notDiscFS="1rem"
                                  value={d?.price as number}
                                />
                              </td>
                              <td>
                                <Money
                                  notDiscFS="1rem"
                                  value={
                                    (d?.qty as number) * (d?.price as number)
                                  }
                                />
                              </td>
                              <td>{d?.product?.category?.categoryName}</td>
                              <td>{d?.deliveryPerson?.username}</td>
                              {/* <td>
                                <Field
                                  label=""
                                  name={`orderItems.${i}.selected`}
                                  type="checkbox"
                                  component={CheckboxFormField}
                                />
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Paper>
                )}
              </FieldArray>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}

              <SubmitBtn loading={loading} />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
};

export default AlocationFormPage;
