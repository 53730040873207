import { useApolloClient, useMutation } from "@apollo/client";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { apiLink } from "../../config";
import deleteCache from "../../utils/deleteCache";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import CKTextEditor from "../Misc/FormComponents/CKTextEditor";
import FileUpload from "../Misc/FormComponents/FileUpload";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import useBlogStyles from "./hooks/useBlogStyles";

// form validations
const schema = yup.object({
  title: yup.string().required("Blog Name is Required"),
});

interface Props {
  blog?: {
    id: string;
    title: string;
    is_active: boolean;
    abstract: string;
    content: string;
    slug: string;
    image: string;
  };
}

const BlogForm: React.FC<Props> = ({ blog }) => {
  const isEdit = typeof blog !== "undefined";
  const history = useHistory();
  const client = useApolloClient();
  const { displayPopMessage } = useMessageState();
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");

  const [submitForm] = useMutation(
    isEdit ? UPDATE_BLOG_MUTATION : CREATE_BLOG_MUTATION
  );

  const classes = useBlogStyles({});

  const handleSubmit = async (values: any) => {
    const data = {
      title: values.title,
      is_active: values.is_active,
      abstract: values.abstract,
      content: content,
      slug: values.slug,
      image: values.image,
    };
    try {
      setLoading(true);
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: blog?.id } }),
            data,
          },
        }, // pass data
      });
      setLoading(false);
      displayPopMessage(`Blog ${!isEdit ? "Added" : "Updated"} Succefully`);

      if (isEdit) {
        const regex = RegExp(`^Blog:${blog?.id}`);
        deleteCache(regex, client);
      }

      history.push({
        pathname: "/admin/blogs",
      });
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (isEdit) {
      setContent(blog?.content || "");
    }
  }, [blog, isEdit]);

  const onChangeTitle = (e: any, setFieldValue: any) => {
    const title = e.target.value;
    const slug = title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    setFieldValue("slug", slug);
    setFieldValue("title", title);
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Container
          component="main"
          maxWidth="lg"
          style={{ marginBottom: "60px" }}
        >
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                title: isEdit ? blog?.title : "",
                abstract: isEdit ? blog?.abstract : "",
                content: isEdit ? blog?.content : "",
                slug: isEdit ? blog?.slug : "",
                is_active: isEdit ? blog?.is_active : false,
                image: isEdit ? blog?.image : "",
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} <mark></mark> Blog Name
                  </h3>
                  <Field
                    name="title"
                    component={TextFormField}
                    label="Blog Title"
                    fullWidth
                    variant="outlined"
                    autoFocus
                    onChange={(e: any) => onChangeTitle(e, setFieldValue)}
                  />
                  <Field
                    name="slug"
                    component={TextFormField}
                    label="Blog URL"
                    disabled
                    fullWidth
                    variant="outlined"
                  />
                  <Field
                    name="is_active"
                    component={CheckboxFormField}
                    defaultChecked={isEdit ? blog?.is_active : false}
                    label="Publish blog"
                  />
                  <Field
                    name="abstract"
                    component={TextFormField}
                    label="Abstract"
                    fullWidth
                    variant="outlined"
                  />
                  {values.image && (
                    <div>
                      <h4>Current Main Image</h4>
                      <img
                        alt={values.image}
                        height={200}
                        width={200}
                        src={`${apiLink}${values.image}`}
                      />
                    </div>
                  )}
                  <FileUpload
                    handleSetFieldValue={setFieldValue}
                    handleFieldName={"image"}
                    text="Upload Main Image"
                    folder="blogs"
                  />
                  <CKTextEditor setText={setContent} text={content} isImage />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" mb="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default BlogForm;

export const CREATE_BLOG_MUTATION = gql`
  mutation CREATE_BLOG_MUTATION($input: createBlogInput) {
    createBlog(input: $input) {
      blog {
        title
      }
    }
  }
`;

export const UPDATE_BLOG_MUTATION = gql`
  mutation UPDATE_BLOG_MUTATION($input: updateBlogInput) {
    updateBlog(input: $input) {
      blog {
        title
      }
    }
  }
`;
