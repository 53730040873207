import React from "react";
import ViewVouchers from "../components/Voucher/ViewVouchers";

interface Props {}

const VouchersPage: React.FC<Props> = () => {
  return <ViewVouchers />;
};

export default VouchersPage;
