import { useMutation } from "@apollo/client";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Axios from "axios";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { api, apiLink } from "../../config";
import { CATEGORY_QUERY } from "../../pages/category-form";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import FileUpload from "../Misc/FormComponents/FileUpload";
import SelectFormField from "../Misc/FormComponents/SelectFormField";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import useCategoryStyles from "./hooks/useCategoryStyles";

// form validations
const schema = yup.object({
  categoryName: yup.string().required("Category Name is Required"),
  surcharge: yup.number().required("Surcharge is Required"),
  cat_type_tags: yup.array().required("At least 1 tag is Required"),
});

interface Props {
  category?: {
    id: string;
    categoryName: string;
    inactive: boolean;
    image: string;
    cat_type_tags: [
      {
        id: string;
      },
    ];
    surcharge: number;
  };
}

const CategoryForm: React.FC<Props> = ({ category }) => {
  const history = useHistory();
  const { displayPopMessage } = useMessageState();
  // check if edit mode is on
  const isEdit = typeof category !== "undefined";
  // to set loading when submitting the form
  const [loading, setLoading] = useState<boolean>(false);
  const [tags, setTags] = React.useState([]);
  // form submit mutation
  const [submitForm] = useMutation(
    isEdit ? UPDATE_CATEGORY_MUTATION : CREATE_CATEGORY_MUTATION,
    {
      ...(isEdit && {
        refetchQueries: [
          { query: CATEGORY_QUERY, variables: { id: category?.id } },
        ],
      }),
    },
  );

  // element refs
  const classes = useCategoryStyles({});

  // form onSubmit handling function
  const handleSubmit = async (values: any) => {
    const data = {
      categoryName: values.categoryName,
      inactive: values.inactive,
      image: values.image,
      cat_type_tags: values.cat_type_tags,
      surcharge: values.surcharge,
    };
    try {
      setLoading(true);
      // submit the values to the server throught mutation
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: category?.id } }),
            data,
          },
        }, // pass data
      });
      setLoading(false);

      displayPopMessage(`Category ${!isEdit ? "Added" : "Updated"} Succefully`);

      // navigate to categories view page
      history.push({ pathname: "/admin/categories" });
    } catch (err) {
      // throws an error if it failed to upadate a category
      console.log(err);
    }
  };
  const getTag = async () => {
    const res = await Axios.get(`${api}/cat-type-tags`, {
      withCredentials: true,
    });
    setTags(res.data);
  };
  React.useEffect(() => {
    getTag();
  }, []);
  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                categoryName: isEdit ? category?.categoryName : "",
                inactive: isEdit ? category?.inactive : false,
                image: isEdit ? category?.image : "",
                cat_type_tags: isEdit
                  ? category?.cat_type_tags.map((a: any) => a.id)
                  : [],
                surcharge: isEdit ? category?.surcharge : 0,
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} Sub Category
                  </h3>
                  <Field
                    name="categoryName"
                    component={TextFormField}
                    label="Category Name"
                    fullWidth
                    variant="outlined"
                  />
                  <Field
                    name="inactive"
                    component={CheckboxFormField}
                    label="Inactive"
                    variant="outlined"
                    checked={values.inactive}
                  />
                  <Field
                    name="cat_type_tags"
                    component={SelectFormField}
                    label="Tags"
                    placeholder="Tags"
                    options={tags.map((tag: any) => ({
                      label: tag.tagName,
                      value: tag.id.toString(),
                    }))}
                    multiple
                  />
                  <Field
                    name="surcharge"
                    component={TextFormField}
                    label="Surcharge"
                    fullWidth
                    type="number"
                    variant="outlined"
                  />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  {values.image && (
                    <div>
                      <h4>Current Main Image</h4>
                      <img
                        alt={values.image}
                        height={200}
                        width={200}
                        src={`${apiLink}${values.image}`}
                      />
                    </div>
                  )}
                  <FileUpload
                    handleSetFieldValue={setFieldValue}
                    handleFieldName={"image"}
                    text="Upload Main Color Image"
                    folder="products"
                  />
                  <SubmitBtn loading={loading} w="100%" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default CategoryForm;

export const CREATE_CATEGORY_MUTATION = gql`
  mutation CREATE_CATEGORY_MUTATION($input: createCategoryInput) {
    createCategory(input: $input) {
      category {
        categoryName
        image
      }
    }
  }
`;

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation UPDATE_CATEGORY_MUTATION($input: updateCategoryInput) {
    updateCategory(input: $input) {
      category {
        categoryName
        image
      }
    }
  }
`;
