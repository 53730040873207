import { grayColor } from "../../../assets/jss/material-dashboard-react";

const summaryStyles2 = {
  stats: {
    color: "white",
    display: "inline-flex",
    fontSize: "16px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  cardCategory: {
    color: "white",
    margin: "0",
    fontSize: "20px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
    fontFamily: "fantasy",
    // fontWeight: "bold",
  },
  cardTitle: {
    color: "white",
    marginTop: "0px",
    minHeight: "auto",
    // fontWeight: "300",
    fontSize: "24px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      // fontWeight: "400",
      fontSize: "24px",
      lineHeight: "1",
    },
  },
  box: {
    backgroundColor: "#363333",
  },
};

export default summaryStyles2;
