import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useDiscounts = () => {
  const { data, loading, error } = useQuery(DISCOUNTS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  return {
    discountData: data,
    discountLoading: loading,
    discountError: error,
  };
};

export default useDiscounts;

export const mapDiscounts = (discountData: any) => {
  const mappedDiscounts = discountData.discountsConnection.values.map(
    (discount: { discountName: string; __typename: string; id: number }) => ({
      id: discount.id,
      label: discount.discountName,
      value: discount.discountName,
    })
  );
  return mappedDiscounts;
};

export const DISCOUNTS_QUERY = gql`
  query DISCOUNTS_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    discountsConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        name
        start
        end
        isValue
        value
        type
        products {
          id
          productName
        }
        isActive
      }
    }
  }
`;
