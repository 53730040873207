import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  useDeliveryUsersQuery,
  useUpdateDeliveryPersonMutation,
} from "../generated/graphql";

interface Props {}

const DeliveryUsersPage: React.FC<Props> = () => {
  const history = useHistory();
  const { refetch } = useDeliveryUsersQuery({ skip: true });
  const [setDeliveryPerson] = useUpdateDeliveryPersonMutation();

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/category-form/${id}`,
    });
  };

  return (
    <>
      <div>
        <MaterialTable
          title="Delivery Users"
          columns={[
            { title: "Id", field: "id", editable: "never", hidden: true },
            { title: "Name", field: "username", editable: "never" },
            { title: "Contact Number", field: "tele" },
          ]}
          options={{
            actionsColumnIndex: -1,
          }}
          editable={{
            onRowUpdate: async (newData, oldData) => {
              await setDeliveryPerson({
                variables: {
                  input: {
                    where: { id: oldData?.id },
                    data: {
                      tele: newData.tele,
                    },
                  },
                },
              });
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  username_contains: query.search,
                  role: {
                    name: "DeliveryPerson",
                  },
                },
                sort: "username:asc",
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const values = res.data?.usersConnection?.values;
                  const aggregate = res.data?.usersConnection?.aggregate;

                  const newData = values?.map((item) => ({
                    id: item?.id,
                    username: item?.username,
                    tele: item?.tele,
                  }));
                  resolve({
                    data: newData as any,
                    page: query.page, //- 1,
                    totalCount: aggregate?.totalCount as number,
                  });
                })
                .catch((err) => {
                  console.log("error", err);
                });
            })
          }
        />
      </div>
    </>
  );
};

export default DeliveryUsersPage;
