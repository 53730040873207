import { useApolloClient, useMutation } from "@apollo/client";
import { Box, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { apiLink } from "../../config";
import deleteCache from "../../utils/deleteCache";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import FileUpload from "../Misc/FormComponents/FileUpload";
import ReactSelectFormField from "../Misc/FormComponents/ReactSelectFormField";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import useWebContentStyles from "./hooks/useWebContentStyles";

// form validations
const schema = yup.object({
  // title: yup.string().required("Title is Required"),
});

interface Props {
  webContent?: {
    id: string;
    title: string;
    link: string;
    linkText: string;
    image: string;
    type: string;
    desp: string;
    mobileImage: string;
    isLeft: boolean;
    color: string;
    mobileColor: string;
    imageOnly: boolean;
    heroButtonBackgroundColor: string;
    heroButtonHoverBackgroundColor: string;
    heroButtonTextColor: string;
    heroButtonHoverTextColor: string;
  };
}

const WebContentForm: React.FC<Props> = ({ webContent }) => {
  const isEdit = typeof webContent !== "undefined";
  const history = useHistory();
  const client = useApolloClient();
  const { displayPopMessage } = useMessageState();
  const [loading, setLoading] = useState<boolean>(false);

  const [heroButtonBackgroundColor, setHeroButtonBackgroundColor] =
    useState("");

  const [submitForm] = useMutation(
    isEdit ? UPDATE_WEBCONTENT_MUTATION : CREATE_WEBCONTENT_MUTATION,
  );

  const classes = useWebContentStyles({});

  const handleSubmit = async (values: any) => {
    const data = {
      title: values.title,
      // title: "ghhhh",
      link: values.link,
      linkText: values.linkText,
      image: values.image,
      mobileImage: values.mobileImage,
      desp: values.desp,
      type: values.type,
      isLeft: values.isLeft,
      inactive: values.inactive,
      color: values.color,
      mobileColor: values.mobileColor,
      imageOnly: values.imageOnly,
      heroButtonBackgroundColor: values.heroButtonBackgroundColor,
      heroButtonHoverBackgroundColor: values.heroButtonHoverBackgroundColor,
      heroButtonTextColor: values.heroButtonTextColor,
      heroButtonHoverTextColor: values.heroButtonHoverTextColor,
    };
    console.log(data);
    try {
      setLoading(true);
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: webContent?.id } }),
            data,
          },
        }, // pass data
      });
      setLoading(false);
      displayPopMessage(
        `Web Content ${!isEdit ? "Added" : "Updated"} Succefully`,
      );
      setHeroButtonBackgroundColor(values.heroButtonBackgroundColor);

      if (isEdit) {
        const regex = RegExp(`^WebContent:${webContent?.id}`);
        deleteCache(regex, client);
      }

      history.push({
        pathname: "/admin/web-content",
      });
    } catch (err) {
      console.log(err);
    }
  };
  const setPosition = (setFieldValue: any, e: any) => {
    // console.log(e);
    if (e === "1") {
      setFieldValue("isLeft", true);
    } else if (e === "3") {
      setFieldValue("isLeft", false);
    } else {
      setFieldValue("isLeft", null);
    }
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                title: isEdit ? webContent?.title : "",
                link: isEdit ? webContent?.link : "",
                linkText: isEdit ? webContent?.linkText : "",
                image: isEdit ? webContent?.image : "",
                mobileImage: isEdit ? webContent?.mobileImage : "",
                desp: isEdit ? webContent?.desp : "",
                type: isEdit ? webContent?.type : "top_slide",
                isLeft: isEdit ? webContent?.isLeft : true,
                color: isEdit ? webContent?.color : "",
                mobileColor: isEdit ? webContent?.mobileColor : "",
                imageOnly: isEdit ? webContent?.imageOnly : true,
                heroButtonBackgroundColor: isEdit
                  ? webContent?.heroButtonBackgroundColor
                  : heroButtonBackgroundColor,
                heroButtonHoverBackgroundColor: isEdit
                  ? webContent?.heroButtonHoverBackgroundColor
                  : "",
                heroButtonTextColor: isEdit
                  ? webContent?.heroButtonTextColor
                  : "",
                heroButtonHoverTextColor: isEdit
                  ? webContent?.heroButtonHoverTextColor
                  : "",
                // inactive: isEdit ? color?.inactive : false,
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue, initialValues }) => {
                const isTopSlide = values.type === "top_slide";
                const isMiddleSlide = values.type === "middle_slide";
                const isBottomSlide = values.type === "bottom_slide";

                if (isTopSlide && !values.imageOnly) {
                  setFieldValue("imageOnly", true);
                }

                return (
                  <Form className={classes.form}>
                    <h3 className={classes.heading}>
                      {isEdit ? "Update" : "Add"} <mark></mark> Content
                    </h3>
                    <Field
                      label="Type"
                      name="type"
                      component={ReactSelectFormField}
                      options={[
                        { label: "Top Slide", value: "top_slide" },
                        { label: "Middle Slide", value: "middle_slide" },
                        { label: "Promo Banner", value: "promo_banner" },
                        { label: "Bottom Slide", value: "bottom_slide" },
                      ]}
                      placeholder="Type"
                      // customOnChange={handleBrandChange}
                    />
                    {isMiddleSlide && (
                      <Field
                        name="imageOnly"
                        component={CheckboxFormField}
                        defaultChecked={isEdit ? webContent?.imageOnly : true}
                        label="Display image only"
                      />
                    )}
                    {!values.imageOnly && (
                      <Field
                        name="title"
                        component={TextFormField}
                        label="Title"
                        fullWidth
                        variant="outlined"
                        autoFocus
                      />
                    )}
                    {!values.imageOnly && (
                      <Field
                        name="desp"
                        component={TextFormField}
                        label="Description"
                        fullWidth
                        variant="outlined"
                        multiline
                      />
                    )}
                    <Field
                      name="link"
                      component={TextFormField}
                      label="Link"
                      fullWidth
                      variant="outlined"
                    />
                    {isMiddleSlide && !values.imageOnly && (
                      <Field
                        name="linkText"
                        component={TextFormField}
                        label="Link Text"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                    {!values.imageOnly && isMiddleSlide && (
                      <>
                        <Field
                          name="color"
                          component={TextFormField}
                          label="Text Color"
                          fullWidth
                          variant="outlined"
                        />
                        <Field
                          name="mobileColor"
                          component={TextFormField}
                          label="Mobile View Text Color"
                          fullWidth
                          variant="outlined"
                        />
                      </>
                    )}
                    {isMiddleSlide && !values.imageOnly && (
                      <RadioGroup
                        onChange={(e) => setPosition(setFieldValue, e)}
                        // value={preisLeft}
                        defaultValue={
                          initialValues.isLeft == true
                            ? "1"
                            : initialValues.isLeft == false
                            ? "3"
                            : "2"
                        }
                      >
                        <Stack direction="row">
                          <Radio value="1">Left</Radio>
                          <Radio value="2">Middle</Radio>
                          <Radio value="3">Right</Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                    {values.image && (
                      <div>
                        <h4>Current Image</h4>
                        <img
                          alt={values.image}
                          height={200}
                          width={200}
                          src={`${apiLink}${values.image}`}
                        />
                      </div>
                    )}
                    <FileUpload
                      handleSetFieldValue={setFieldValue}
                      handleFieldName={"image"}
                      text="Upload Image"
                      folder="products"
                    />
                    {values.mobileImage && !isBottomSlide && (
                      <div>
                        <h4>Current Mobile Image</h4>
                        <img
                          alt={values.image}
                          height={200}
                          width={200}
                          src={`${apiLink}${values.mobileImage}`}
                        />
                      </div>
                    )}
                    {!isBottomSlide && (
                      <FileUpload
                        handleSetFieldValue={setFieldValue}
                        handleFieldName={"mobileImage"}
                        text="Upload Image for Mobile"
                        folder="products"
                      />
                    )}
                    {isTopSlide && (
                      <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr"
                        gridGap={"5px"}
                      >
                        <Field
                          name="heroButtonBackgroundColor"
                          component={TextFormField}
                          label="Button Background Color"
                          fullWidth
                          variant="outlined"
                          multiline
                        />
                        <Field
                          name="heroButtonHoverBackgroundColor"
                          component={TextFormField}
                          label="Hover Button Background Color"
                          fullWidth
                          variant="outlined"
                          multiline
                        />
                        <Field
                          name="heroButtonTextColor"
                          component={TextFormField}
                          label="Button Text Color"
                          fullWidth
                          variant="outlined"
                          multiline
                        />
                        <Field
                          name="heroButtonHoverTextColor"
                          component={TextFormField}
                          label="Hover Button Text Color"
                          fullWidth
                          variant="outlined"
                          multiline
                        />
                      </Box>
                    )}

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    <SubmitBtn loading={loading} w="100%" mb="3" />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default WebContentForm;

export const CREATE_WEBCONTENT_MUTATION = gql`
  mutation CREATE_WEBCONTENT_MUTATION($input: createWebContentInput) {
    createWebContent(input: $input) {
      webContent {
        title
        link
        linkText
        image
        type
        desp
        isLeft
        imageOnly
        heroButtonBackgroundColor
        heroButtonHoverBackgroundColor
        heroButtonTextColor
        heroButtonHoverTextColor
      }
    }
  }
`;

export const UPDATE_WEBCONTENT_MUTATION = gql`
  mutation UPDATE_WEBCONTENT_MUTATION($input: updateWebContentInput) {
    updateWebContent(input: $input) {
      webContent {
        title
        link
        linkText
        image
        type
        desp
        isLeft
        imageOnly
        heroButtonBackgroundColor
        heroButtonHoverBackgroundColor
        heroButtonTextColor
        heroButtonHoverTextColor
      }
    }
  }
`;
