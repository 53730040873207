import { useApolloClient, useMutation } from "@apollo/client";
import { Box, Grid } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Paper from "@material-ui/core/Paper"
import { Field, Form, Formik } from "formik"
import gql from "graphql-tag"
import * as React from "react"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import * as yup from "yup"
import deleteCache from "../../utils/deleteCache"
import { dateTimeSQLtoJS } from "../../utils/misc"
import { useMessageState } from "../LocalStateProviders/MessageStateProvider"
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField"
import ReactSelectFormField from "../Misc/FormComponents/ReactSelectFormField"
import SubmitBtn from "../Misc/FormComponents/SubmitBtn"
import TextFormField from "../Misc/FormComponents/TextFormField"
import ProductTreeView from "./ProductTreeView"
import useDiscountStyles from "./hooks/useDiscountStyles"
import ItemCodeSelection from "./ItemCodeSelection"
// form validations
const schema = yup.object({
  name: yup.string().required("Discount Name is Required")
})

interface Props {
  discount?: {
    id: string
    name: string
    start: string
    end: string
    isValue: boolean
    value: number
    type: string
    products: {
      id: string
      productName: string
    }[]
    colorCats: {
      id: string
      colorCode: string
      product: {
        id: string
        productName: string
      }
    }[]
  }
}

const DiscountForm: React.FC<Props> = ({ discount }) => {
  const isEdit = typeof discount !== "undefined"
  const history = useHistory()
  const client = useApolloClient()
  const { displayPopMessage } = useMessageState()
  const [loading, setLoading] = useState<boolean>(false)

  const [submitForm] = useMutation(
    isEdit ? UPDATE_DISCOUNT_MUTATION : CREATE_DISCOUNT_MUTATION
  )

  const classes = useDiscountStyles({})

  const handleSubmit = async (values: any) => {
    if (
      !window.confirm(
        "Are you sure you want to continue? Make sure the all the details are correct"
      )
    ) {
      return
    }

    if (!values.start || !values.end) {
      displayPopMessage("Start and End Date is Required")
      return
    }

    if (values.end < values.start) {
      displayPopMessage("End Date cannot be less than Start Date")
      return
    }

    // if (values.end < new Date().toISOString()) {
    //   displayPopMessage("End Date cannot be less than Today")
    //   return
    // }

    if (values.value === 0) {
      displayPopMessage("Discount Percentage/Amount is Required")
      return
    }

    if (values.type === "itemcode" && values.colorCats.length === 0) {
      displayPopMessage("Item Codes are Required")
      return
    }

    if (["category", "brand"].includes(values.type)) {
      if (values.products.length === 0) {
        displayPopMessage("Products are Required")
        return
      }
    }

    const data = {
      name: values.name,
      start: new Date(values.start),
      end: new Date(values.end),
      isValue: values.isValue,
      value: values.value,
      type: values.type,
      products: values.type !== "itemcode" ? values.products : [],
      isActive: false,
      colorCats: values.type === "itemcode" ? values.colorCats : []
    }
    try {
      setLoading(true)
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: discount?.id } }),
            data
          }
        } // pass data
      })
      setLoading(false)
      displayPopMessage(`Discount ${!isEdit ? "Added" : "Updated"} Succefully`)

      if (isEdit) {
        const regex = RegExp(`^Discount:${discount?.id}`)
        deleteCache(regex, client)
      }

      history.push({
        pathname: "/admin/discounts"
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                name: isEdit ? discount?.name : "",
                start: isEdit ? dateTimeSQLtoJS(discount?.start) : "",
                end: isEdit ? dateTimeSQLtoJS(discount?.end) : "",
                isValue: isEdit ? discount?.isValue : false,
                value: isEdit ? discount?.value : 0,
                type: isEdit ? discount?.type : "category",
                products: isEdit ? discount?.products.map((p) => p.id) : [],
                colorCats: isEdit ? discount?.colorCats.map((c) => +c.id) : []
              }}
              validationSchema={schema}
            >
              {({ values, setFieldValue }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} <mark></mark> Discount Name
                  </h3>

                  <Box color="red">
                    <ul>
                      <li>
                        Once you save this discount you cannot remove the items
                        later, but you can add more items
                      </li>
                      <li>
                        You can only update the discount name, value, dates and
                        add new items.
                      </li>
                      <li>
                        If you want to deactivate the discount you can put an
                        end date that is smaller than today
                      </li>
                    </ul>
                  </Box>
                  <Field
                    name="name"
                    component={TextFormField}
                    label="Discount Name"
                    fullWidth
                    variant="outlined"
                    autoFocus
                  />
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="start"
                        component={TextFormField}
                        label="Start Date"
                        fullWidth
                        variant="outlined"
                        type="datetime-local"
                        focused
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Field
                        name="end"
                        component={TextFormField}
                        focused
                        label="End Date"
                        fullWidth
                        variant="outlined"
                        type="datetime-local"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="isValue"
                        component={CheckboxFormField}
                        label="Is value"
                        fullWidth
                        variant="outlined"
                        checked={values.isValue}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="value"
                        component={TextFormField}
                        label={values.isValue === true ? "Value" : "Percentage"}
                        fullWidth
                        variant="outlined"
                        type="number"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Field
                        name="type"
                        component={ReactSelectFormField}
                        label="Type"
                        fullWidth
                        options={[
                          // {
                          //   label: "Category wise",
                          //   value: "category"
                          // },
                          // {
                          //   label: "Brand wise",
                          //   value: "brand"
                          // },
                          {
                            label: "Item Code wise",
                            value: "itemcode"
                          }
                        ]}
                      />
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  {["category", "brand"].includes(values.type as string) && (
                    <ProductTreeView
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {values.type === "itemcode" && (
                    <ItemCodeSelection
                      setFieldValue={setFieldValue}
                      values={values}
                      oldColorCats={
                        isEdit ? discount?.colorCats.map((c) => +c.id) : []
                      }
                    />
                  )}
                  <SubmitBtn loading={loading} w="100%" mb="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  )
}

export default DiscountForm;

export const CREATE_DISCOUNT_MUTATION = gql`
  mutation CREATE_DISCOUNT_MUTATION($input: createDiscountInput) {
    createDiscount(input: $input) {
      discount {
        name
        start
        end
        isValue
        value
        type
      }
    }
  }
`;

export const UPDATE_DISCOUNT_MUTATION = gql`
  mutation UPDATE_DISCOUNT_MUTATION($input: updateDiscountInput) {
    updateDiscount(input: $input) {
      discount {
        name
        start
        end
        isValue
        value
        type
      }
    }
  }
`;
