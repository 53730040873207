import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useLoginStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // height: "100%",
      [theme.breakpoints.up("md")]: {
        maxWidth: "50%",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
      margin: "0 auto",
    },
    image: {
      backgroundImage: "url(/static/login_bg.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      margin: theme.spacing(5, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      paddingTop: "0px",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    heading: {
      fontSize: "1.5rem",
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        height: 200,
        width: 200,
      },
      [theme.breakpoints.up("sm")]: {
        height: 300,
        width: 300,
      },
    },
  })
);

export default useLoginStyles;
