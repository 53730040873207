import Axios from "axios";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { api } from "../../../config";
import { TableTitle } from "../ProductWise/FilterBox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const dummyData = {
  datasets: [
    {
      label: "Dataset 1",
      data: [1, 2],
    },
  ],
};
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Orders Distribution Over Districts",
      font: {
        size: 20,
      },
    },
  },
};

interface Props {
  fromDate: string;
  toDate: string;
  orderBy: string;
}

const OrdersDistrict: React.FC<Props> = ({ fromDate, toDate, orderBy }) => {
  const history = useHistory();
  const [chartData, setChartData] = React.useState(dummyData);

  const getChartData = async () => {
    const res = await Axios.get(
      `${api}/orderhs/order-district?fromDate=${fromDate}&toDate=${toDate}&orderBy=${orderBy}`,
      {
        withCredentials: true,
      }
    );
    const dataArray = res.data;
    const labels = dataArray.map((item: any) => item.districtName);
    const data = {
      labels,
      datasets: [
        {
          label: "Number of orders",
          data: dataArray.map((item: any) => item.count),
          backgroundColor: "rgba(236, 201, 75, 1)",
        },
      ],
    };
    setChartData(data);
    // console.log(res.data);
  };
  React.useEffect(() => {
    getChartData();
  }, [fromDate, toDate, orderBy]);

  return (
    <>
      <div
        style={{
          marginTop: 20,
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <TableTitle
          title="Orders Distribution Over Districts"
          backgroundColorIndex={1}
          ColorIndex={0}
        />
        <div
          style={{
            padding: "20px",
          }}
        >
          <div style={{ padding: "10px" }}></div>
          <Bar options={options} data={chartData} />
        </div>
      </div>
    </>
  );
};

export default OrdersDistrict;
