import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  LAST_CODE_QUERY,
  Product,
  PRODUCT_QUERY,
} from "../../../customHooks/useProduct";
import { useMessageState } from "../../LocalStateProviders/MessageStateProvider";

const CREATE_PRODUCT_MUTATION = gql`
  mutation CREATE_PRODUCT_MUTATION($input: createProductInputExtend!) {
    createProductExtend(input: $input) {
      product {
        id
        productCode
        productName
      }
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UPDATE_PRODUCT_MUTATION($input: updateProductInputExtend!) {
    updateProductExtend(input: $input) {
      product {
        id
        productCode
        productName
      }
    }
  }
`;

const useProductSubmit = (isEdit: boolean, product: Product | undefined) => {
  const { displayPopMessage } = useMessageState();
  const history = useHistory();

  const [images, setImages] = React.useState<any>(
    isEdit ? product?.productimages?.map((img) => img.image) : []
  );
  const [mainImage, setMainImage] = React.useState(
    isEdit ? product?.image : ""
  );
  const [spec, setSpec] = React.useState(isEdit ? product?.spec : "");

  let refetchQueries = [{ query: LAST_CODE_QUERY }];
  if (isEdit) {
    refetchQueries.push({
      query: PRODUCT_QUERY,
      //@ts-ignore
      variables: { id: product?.id },
    });
  }

  const [submitForm] = useMutation(
    !isEdit ? CREATE_PRODUCT_MUTATION : UPDATE_PRODUCT_MUTATION,
    {
      refetchQueries,
    }
  );

  const handleImageDelete = (image: string) => {
    setImages((prevImages: string[]) => {
      const index = prevImages.indexOf(image);
      const newImages = [...prevImages];
      newImages.splice(index, 1);
      return newImages;
    });
  };

  const handleSubmit = async ({
    productCode,
    serialNo,
    productName,
    price,
    discount,
    isVoucher,
    material,
    brand,
    category,
    inactive,
    featuredItem,
    productDescription,
    stock,
    store,
    warranty,
    color_cats,
  }: Product) => {
    console.log(color_cats);
    const data = {
      productCode,
      serialNo,
      productName,
      material,
      brand,
      category,
      inactive,
      isVoucher,
      spec,
      featuredItem,
      productDescription,
      // productimages: images.map((image: string) => ({ image: image })),
      store,
      color_cats,
      warranty,
    };

    let isThumbnailSelected = false;
    for (const c of color_cats) {
      if (c.featured === true && c.inActive === false) {
        isThumbnailSelected = true;
        break;
      }
    }

    if (isThumbnailSelected === false) {
      displayPopMessage("Please select a thumbnail for an Active Color");
      return;
    }
    console.log("data", data);
    // return;

    try {
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: product?.id } }),
            data,
          },
        },
      });

      displayPopMessage(`Product ${!isEdit ? "Added" : "Updated"} Succefully`);

      if (isEdit) {
        // const regex = RegExp(`^Product:${product?.id}`);
        // deleteCache(regex, client);
      }

      // history.push({
      //   pathname: "/admin/products",
      // });
      history.goBack();
    } catch (err) {
      console.log(err);
      displayPopMessage(err.message);
    }
  };
  return {
    handleSubmit,
    setImages,
    setMainImage,
    spec,
    setSpec,
    handleImageDelete,
    images,
  };
};
export default useProductSubmit;
