import React from "react";
import ViewWebContent from "../components/WebContent/ViewWebContent";

interface Props {}

const WebContentPage: React.FC<Props> = () => {
  return <ViewWebContent />;
};

export default WebContentPage;
