import FilterBox from "../components/Dashboard/ProductWise/FilterBox";
import * as React from "react";
import { api } from "../config";
import axios from "axios";
import AbundantCartTable from "../components/Dashboard/AbundantCartTable";

function AbundantCarts() {
  const [abItems, setAbItems] = React.useState([]);
  let currentDate = new Date();
  let firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );
  const firstDayOfMonthFormatted = firstDayOfMonth.toISOString().split("T")[0];

  const today = new Date(Date.now()).toISOString().split("T")[0];

  const [fromDate, setFromDate] = React.useState(firstDayOfMonthFormatted);
  const [toDate, setToDate] = React.useState(today);
  const [orderBy, setOrderBy] = React.useState(
    localStorage.getItem("OrderWiseOrderBy") || "DESC",
  );
  React.useEffect(() => {
    const fetchItems = async () => {
      const result = await axios.get(
        `${api}/cartfollowups/get-all-cart-followups?updated_at_gte=${fromDate}&updated_at_lte=${toDate}&user_null=f&_sort=user:${orderBy}`,
        {
          withCredentials: true,
        },
      );
      setAbItems(result.data);
    };
    fetchItems();
  }, [fromDate, toDate, orderBy]);
  console.log(fromDate, toDate, orderBy);
  return (
    <div>
      <FilterBox
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <AbundantCartTable abItems={abItems} />
    </div>
  );
}

export default AbundantCarts;
