import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import axios from "axios";
import React, { useState } from "react";
import GoBackButton from "../components/Misc/GoBackButton";
import { api, cloudApi } from "../config";

const url = process.env.NODE_ENV === "development" ? api : cloudApi;

interface Props {}

const ProductUploadPage: React.FC<Props> = () => {
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setLoading] = useState(true);

  const submitForm = async () => {
    // console.log(selectedFile);
    const formData = new FormData();
    // formData.append("name", name);
    formData.append("files", selectedFile);
    setLoading(true);
    const res = await axios.post(`${url}/products/uploadfile`, formData, {
      withCredentials: true,
    });
    const data = res.data;
    if (data.status === "success") {
      alert(data.msg);
    } else {
      alert(data.msg);
    }
    setLoading(false);
  };
  const handleFileInput = (e: any) => {
    // handle validations
    setSelectedFile(e.target.files[0]);
    setLoading(false);
  };
  return (
    <div>
      <GoBackButton />
      <Container>
        <Paper style={{ padding: 20 }}>
          <br></br>
          <h3 style={{ fontWeight: "bold" }}>
            Upload products details via Excel file
          </h3>
          <h5 style={{ color: "red" }}>
            Please upload a .xlsx file less than 500 rows here.
          </h5>
          <br></br>
          <input type="file" onChange={handleFileInput} />
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => submitForm()}
            disabled={loading}
          >
            Upload
          </Button>
        </Paper>
      </Container>
    </div>
  );
};

export default ProductUploadPage;
