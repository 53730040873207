import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import {
  useUpdateDeliveryStatusMutation,
  OrderDocument,
  DeliveryPersonOrdersDocument,
  useStoresQuery,
} from "../../generated/graphql";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import Loading from "../Misc/Loading";
import Select from "react-select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 10,
      marginBottom: 10,
    },
    deliveredBtn: {
      backgroundColor: "#03fc5e",
      "&:hover": {
        backgroundColor: "#03fc5e",
      },
    },
    store: {
      marginTop: 10,
      marginBottom: 10,
    },
  })
);

interface Props {
  orderId?: string;
  status?: "SMS_RECEIVED" | "PICKED" | "DELIVERED" | null;
}

const DeliveryPersonButtons: React.FC<Props> = ({ orderId, status }) => {
  const classes = useStyles();

  const [store, setStore] = React.useState(null);
  const { displayPopMessage } = useMessageState();

  const { data: storesData, loading: storesLoading } = useStoresQuery();

  const [
    updateDeliveryStatusMutation,
    { data, loading, error },
  ] = useUpdateDeliveryStatusMutation({
    refetchQueries: [
      { query: OrderDocument, variables: { id: orderId } },
      { query: DeliveryPersonOrdersDocument },
    ],
  });

  const handleSmsClicked = async () => {
    if (window.confirm("Did you get the SMS?")) {
      try {
        await updateDeliveryStatusMutation({
          variables: {
            status: "SMS RECEIVED",
            orderId: orderId as string,
          },
        });
        displayPopMessage("Status Updated Successfully");
      } catch (err) {
        console.log(err);
        displayPopMessage(err.message);
      }
    }
  };

  const handlePickClicked = async () => {
    if (!store) {
      alert("Please Select the Store");
      return;
    }
    if (window.confirm("Did you PICK the items from the shop?")) {
      try {
        await updateDeliveryStatusMutation({
          variables: {
            status: "PRODUCTS PICKED",
            orderId: orderId as string,
            store: (store as unknown) as string,
          },
        });
        displayPopMessage("Status Updated Successfully");
      } catch (err) {
        console.log(err);
        displayPopMessage(err.message);
      }
    }
  };

  const handleDeliveryClicked = async () => {
    if (window.confirm("Did you DELIVER the items successfully?")) {
      try {
        await updateDeliveryStatusMutation({
          variables: {
            status: "ORDER DELIVERED",
            orderId: orderId as string,
            store: (store as unknown) as string,
          },
        });
        displayPopMessage("Status Updated Successfully");
      } catch (err) {
        console.log(err);
        displayPopMessage(err.message);
      }
    }
  };
  console.log(status);

  return (
    <>
      <div className={classes.store}>
        {storesLoading ? (
          <Loading />
        ) : (
          <>
            {status === "SMS_RECEIVED" && (
              <>
                <strong>Select Store</strong>
                <Select
                  options={storesData?.stores?.map((s) => ({
                    value: s?.id,
                    label: s?.storeName,
                  }))}
                  placeholder="Select Store"
                  onChange={(val: any) => setStore(val.value)}
                />
              </>
            )}
          </>
        )}
      </div>
      <div className={classes.root}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {!status && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSmsClicked}
              >
                SMS Received
              </Button>
            )}
            {status === "SMS_RECEIVED" && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePickClicked}
              >
                Order Items Picked
              </Button>
            )}
            {status === "PICKED" && (
              <Button
                className={classes.deliveredBtn}
                onClick={handleDeliveryClicked}
              >
                Order Delivered
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DeliveryPersonButtons;
