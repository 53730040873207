import Axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { api } from "../../../config";
import { TableTitle } from "../ProductWise/FilterBox";

interface Props {
  fromDate: string;
  toDate: string;
  orderBy: string;
}

const DeliveryExceed: React.FC<Props> = ({ fromDate, toDate, orderBy }) => {
  const [orders, setOrders] = React.useState([]);
  const getOrders = async () => {
    //date 5 days before
    let exceedDate = new Date();
    exceedDate.setDate(exceedDate.getDate() - 5);
    const toDatee = new Date(toDate);
    const exceedDateString = (exceedDate < toDatee ? exceedDate : toDatee)
      .toISOString()
      .split("T")[0];
    const res = await Axios.get(
      `${api}/orderhs/incomplete-checkouts?invoiceDate_gte=${fromDate}&invoiceDate_lte=${exceedDateString}&status_nin=1&status_nin=2&status_nin=3&status_nin=5&status_nin=9&_sort=invoiceDate:${orderBy}`,
      {
        withCredentials: true,
      }
    );
    // console.log(res.data);
    const data = res.data.map((order: any) => {
      return {
        id: order.id,
        username: order.user.username,
        email: order.user.email,
        date: order.invoiceDate.substring(0, 10),
        products: order.orderds.map(
          (orderd: any) => orderd.product.productName
        ),
        status: order.status.type,
      };
    });
    // console.log(data);
    setOrders(data);
  };
  React.useEffect(() => {
    getOrders();
  }, [fromDate, toDate, orderBy]);
  return (
    <>
      <div style={{ marginTop: 20, backgroundColor: "white" }}>
        <TableTitle
          title="Delivery Dates Exceeded"
          backgroundColorIndex={3}
          ColorIndex={0}
        />
        <MaterialTable
          columns={[
            { title: "Customer Name", field: "username" },
            { title: "Customer Email", field: "email" },
            { title: "Invoice Date", field: "date" },
            { title: "Status", field: "status" },
            {
              title: "Products",
              field: "products",
              render: (rowData: any) => {
                return (
                  <div>
                    {rowData.products.map((product: any) => (
                      <li>{product}</li>
                    ))}
                  </div>
                );
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            debounceInterval: 500,
            toolbar: false,
          }}
          data={orders}
        />
      </div>
    </>
  );
};

export default DeliveryExceed;
