import { useApolloClient, useMutation } from "@apollo/client";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import deleteCache from "../../utils/deleteCache";
import { useMessageState } from "../LocalStateProviders/MessageStateProvider";
import CheckboxFormField from "../Misc/FormComponents/CheckboxFormField";
import SubmitBtn from "../Misc/FormComponents/SubmitBtn";
import TextFormField from "../Misc/FormComponents/TextFormField";
import useWarrantyStyles from "./hooks/useWarrentyStyles";

// form validations
const schema = yup.object({
  warranty: yup.string().required("Warranty is Required"),
});

interface Props {
  warranty?: {
    id: string;
    warranty: string;
    inactive: boolean;
  };
}

const WarrantyForm: React.FC<Props> = ({ warranty }) => {
  const isEdit = typeof warranty !== "undefined";
  const history = useHistory();
  const client = useApolloClient();
  const { displayPopMessage } = useMessageState();
  const [loading, setLoading] = useState<boolean>(false);

  const [submitForm] = useMutation(
    isEdit ? UPDATE_WARRANTY_MUTATION : CREATE_WARRANTY_MUTATION
  );

  const classes = useWarrantyStyles({});

  const handleSubmit = async (values: any) => {
    const data = {
      warranty: values.warranty,
      inactive: values.inactive,
    };
    try {
      setLoading(true);
      await submitForm({
        variables: {
          input: {
            ...(isEdit && { where: { id: warranty?.id } }),
            data,
          },
        }, // pass data
      });
      setLoading(false);
      displayPopMessage(`Warranty ${!isEdit ? "Added" : "Updated"} Succefully`);

      if (isEdit) {
        const regex = RegExp(`^Warranty:${warranty?.id}`);
        deleteCache(regex, client);
      }

      history.push({
        pathname: "/admin/warrenties",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <div className={classes.paper}>
            <Formik
              onSubmit={(values) => handleSubmit(values)}
              initialValues={{
                warranty: isEdit ? warranty?.warranty : "",
                inactive: isEdit ? warranty?.inactive : false,
              }}
              validationSchema={schema}
            >
              {({ values }) => (
                <Form className={classes.form}>
                  <h3 className={classes.heading}>
                    {isEdit ? "Update" : "Add"} <mark></mark> Warrenty Period
                  </h3>
                  <Field
                    name="warranty"
                    component={TextFormField}
                    label="Warrenty"
                    fullWidth
                    variant="outlined"
                    autoFocus
                  />
                  <Field
                    name="inactive"
                    component={CheckboxFormField}
                    defaultChecked={isEdit ? warranty?.inactive : false}
                    label="Inactive"
                  />
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SubmitBtn loading={loading} w="100%" mb="3" />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </Paper>
    </div>
  );
};

export default WarrantyForm;

export const CREATE_WARRANTY_MUTATION = gql`
  mutation CREATE_WARRANTY_MUTATION($input: createWarrantyInput) {
    createWarranty(input: $input) {
      warranty {
        warranty
      }
    }
  }
`;

export const UPDATE_WARRANTY_MUTATION = gql`
  mutation UPDATE_WARRANTY_MUTATION($input: updateWarrantyInput) {
    updateWarranty(input: $input) {
      warranty {
        warranty
      }
    }
  }
`;
