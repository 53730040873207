import { gql, useQuery } from "@apollo/client";
import { Button } from "@chakra-ui/react";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";

interface Props {}

const ViewStores: React.FC<Props> = () => {
  const history = useHistory();
  const { refetch } = useQuery(STORES_QUERY, {
    skip: true,
  });

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/store-form/${id}`,
    });
  };

  const handleAddClick = () => {
    history.push({
      pathname: "/admin/store-form",
    });
  };

  const handleCreateUserClick = () => {
    history.push({
      pathname: "/admin/create-user-form",
    });
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Store
      </Button>
      <div>
        <MaterialTable
          title="Store"
          columns={[{ title: "Store Name", field: "storeName" }]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Charge",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  storeName_contains: query.search,
                },
                sort: "storeName:asc",
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      storesConnection: { values, aggregate },
                    },
                  } = res;
                  const newData = values.map((item: any) => ({
                    id: item.id,
                    storeName: item.storeName,
                  }));
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewStores;

export const STORES_QUERY = gql`
  query STORES_QUERY($sort: String, $limit: Int, $start: Int, $where: JSON) {
    storesConnection(start: $start, limit: $limit, sort: $sort, where: $where) {
      aggregate {
        totalCount
      }
      values {
        id
        storeName
      }
    }
  }
`;
