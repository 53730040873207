import React from "react";
import useStyles from "./hooks/useStyles";

function Line({
  name,
  value,
  format = "NORMAL",
}: {
  name: string;
  value: string | number | undefined;
  format?: "NORMAL" | "CURRENCY";
}) {
  const classes = useStyles();

  let val = value;
  if (format === "CURRENCY") {
    val = (value as number).toLocaleString("en-us", {
      style: "currency",
      currency: "LKR",
    });
  }

  return (
    <div className={classes.lineRoot}>
      <div className={classes.lineName}>{name}</div>
      <div>{val}</div>
    </div>
  );
}

export default Line;
