import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Switch } from "@chakra-ui/react";
import axios from "axios";
import { api } from "../../config";

function AbundantCartTable({ abItems }: any) {
  const [data, setData] = useState(abItems);

  useEffect(() => {
    setData(abItems);
  }, [abItems]);

  const modifiedData = data.map((item) => ({
    name: item.name,
    email: item.email,
    phonenumber: item.phonenumber,
    items: item.products.map(
      (product) => `${product.productName} (Qty: ${product.qty}) \n`,
    ),
    // .join(", "),
    note: item.note,
    followup: item.followup,
  }));

  const handleToggle = async (id: any, e: any) => {
    let sendRowData;
    const newData = data.map((item: any, index: number) => {
      if (index === id) {
        const changedRowData = { ...item, followup: e.target.checked };
        sendRowData = changedRowData;
        return changedRowData;
      } else {
        return item;
      }
    });
    setData(newData);
    await axios.post(`${api}/cartfollowups/update-followups`, sendRowData, {
      withCredentials: true,
    });
    // console.log("Handle Toggle", rowData);
  };

  const handleEdit = async (rowData: any) => {
    await axios.post(`${api}/cartfollowups/update-followups`, rowData, {
      withCredentials: true,
    });
    console.log("Row Data", rowData);
  };
  console.log("Data", data);

  return (
    <MaterialTable
      columns={[
        { title: "Name", field: "name", editable: "never" },
        { title: "Email", field: "email", editable: "never" },
        { title: "Phone Number", field: "phonenumber", editable: "never" },
        { title: "Items", field: "items", editable: "never" },
        { title: "Note", field: "note", editable: "always" },
        {
          title: "Follow Up",
          field: "followup",
          editable: "never",
          render: (rowData: any) => (
            <Switch
              colorScheme="red"
              size="lg"
              isChecked={rowData.followup}
              value={rowData.followup}
              onChange={(e) => handleToggle(rowData.tableData.id, e)}
            />
          ),
          filtering: false,
          sorting: false,
        },
      ]}
      options={{
        pageSize: 20,
      }}
      data={modifiedData}
      cellEditable={{
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
          if (columnDef.field === "note") {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                console.log(newValue);
                const dataUpdate = [...data];
                const index = rowData.tableData.id;
                dataUpdate[index][columnDef.field as any] = newValue;
                setData([...dataUpdate]);
                resolve();
                console.log("dataUpdate", dataUpdate, rowData);
                handleEdit(dataUpdate[index]);
              }, 1000);
            });
          } else {
            return new Promise((resolve, reject) =>
              reject("Editing not allowed"),
            );
          }
        },
      }}
    />
  );
}

export default AbundantCartTable;
