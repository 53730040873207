import { Heading } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/react";
import { Paper } from "@material-ui/core";
import React from "react";
import { OrderQuery } from "../../generated/graphql";
import useStyles from "./hooks/useStyles";
import Line from "./Line";
import OrderHistory from "./OrderHistory";

interface Props {
  order: OrderQuery["orderh"];
}

const PaymentSelection: React.FC<Props> = ({ order }) => {
  const classes = useStyles();
  return (
    <div>
      <Paper className={classes.midSecBox}>
        <Heading
          fontSize="xl"
          textTransform="uppercase"
          // fontWeight="thin"
          borderBottom="2px"
          borderColor="yellow.700"
          mb="1"
        >
          Payment Selection
        </Heading>
        <Line
          name="Method"
          value={
            getPaymentMethod(
              order?.paymentselections?.[0]?.deliveryMethod || ""
            ) +
            " - " +
            OnlinePaymentMethod(order?.cardName || "")
          }
        />
        {order?.paymentselections?.[0]?.senderName ||
        order?.paymentselections?.[0]?.note ? (
          <Box boxShadow="lg" padding="10px" bgColor="gray.300">
            <Heading
              fontSize="xl"
              textTransform="uppercase"
              // fontWeight="thin"
              borderBottom="2px"
              borderColor="yellow.700"
              mb="1"
            >
              Send As Gift
            </Heading>
            <Line
              name="Sender Name"
              value={order?.paymentselections?.[0]?.senderName || ""}
            />
            <Line
              name="Note"
              value={order?.paymentselections?.[0]?.note || ""}
            />
          </Box>
        ) : (
          <></>
        )}
      </Paper>
      <OrderHistory order={order} />
    </div>
  );
};

export default PaymentSelection;

// function getPaymentMethod(method: "PHD" | "PPS" | "COD" | string) {
//   if (method === "PHD") {
//     return "Pay & Home Delivery";
//   } else if (method === "PPS") {
//   } else if (method === "COD") {
//     return "Cash on Delivery";
//   } else {
//     return "No Payment Method";
//   }
// }
function getPaymentMethod(method: "PO" | "PD" | string) {
  if (method === "PD") {
    return "Pay & Home Delivery";
  } else if (method === "PO") {
    return "Pay Online and Pick from Store";
  } else {
    return "No Payment Method";
  }
}

function OnlinePaymentMethod(method: string) {
  if (method === "MintPay") return "MintPay";
  if (method === "KokoPay") return "KokoPay";
  else return "PayHere";
}
