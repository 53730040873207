import create from "zustand";
import { devtools } from "zustand/middleware";

type Store = {
  role: string;
  username: string;
  setUserRole: (role: string, username: string) => void;
};

const useStore = create<Store>(
  devtools((set: any) => ({
    role: "",
    username: "",
    setUserRole: (role: string, username: string) =>
      set(() => ({ role, username }), "SET_USER_ROLE"),
  }))
);

export default useStore;
