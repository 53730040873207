import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& h3": {
        marginTop: "0px !important",
      },
    },
    heading: {
      textAlign: "center",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    mainDetails: {
      padding: theme.spacing(2),
      flex: 1,
      margin: theme.spacing(1),
    },
    lineRoot: {
      margin: theme.spacing(1),
    },
    lineName: {
      fontSize: 20,
      fontWeight: "bold",
      letterSpacing: "1px !important",
      borderBottom: "1px solid #f2f2f2",
    },
    orderItems: {
      padding: theme.spacing(2),
      flex: 4,
      margin: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        overflow: "auto",
      },
    },
    table: {
      "& th, td": {
        padding: 5,
      },
      "& th": {
        textAlign: "left",
      },
    },
    midSec: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    midSecBox: {
      padding: theme.spacing(2),
      flex: 1,
      margin: theme.spacing(1),
    },
  })
);

export default useStyles;
