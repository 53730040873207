import { Heading } from "@chakra-ui/layout";
import { Paper } from "@material-ui/core";
import React from "react";
import { Orderh } from "../../generated/graphql";
import useStore from "../../utils/store";
import Money from "../Misc/Money";
import useStyles from "./hooks/useStyles";

interface Props {
  orderds: Orderh["orderds"];
}

const OrderItems: React.FC<Props> = ({ orderds }) => {
  const classes = useStyles();
  const role = useStore((state) => state.role);

  return (
    <Paper className={classes.orderItems}>
      <Heading
        fontSize="xl"
        textTransform="uppercase"
        // fontWeight="thin"
        borderBottom="2px"
        borderColor="yellow.700"
        mb="1"
      >
        Order Items
      </Heading>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Product/Package Name</th>
            <th>Color Code</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Value</th>
            {role !== "DeliveryPerson" && <th>Delivery Person</th>}
            {/* <th>Status</th> */}
          </tr>
        </thead>
        <tbody>
          {orderds
            ?.filter((item) => true)
            ?.map((d, i) => {
              let newProductName = "";
              let colorCode = "";
              if (d?.product) {
                newProductName = d?.product.productName;
                if (d.color) {
                  d?.product?.color_cats?.forEach((item) => {
                    if (item?.color?.id === d?.color?.id) {
                      newProductName = `${newProductName} (${item?.color?.colorName})`;
                      colorCode = item?.colorCode as any;
                    }
                  });
                }
              }
              return (
                <tr key={d?.id + " " + i}>
                  <td>{newProductName}</td>
                  <td>{colorCode}</td>
                  <td>{d?.qty}</td>
                  <td>
                    <Money notDiscFS="1rem" value={d?.price as number} />
                  </td>
                  <td>
                    <Money
                      notDiscFS="1rem"
                      value={(d?.qty as number) * (d?.price as number)}
                    />
                  </td>
                  {role !== "DeliveryPerson" && (
                    <td>{d?.deliveryPerson?.username || "-"}</td>
                  )}

                  {/* <td>{d?.status || "-"}</td> */}
                </tr>
              );
            })}
        </tbody>
      </table>
    </Paper>
  );
};

export default OrderItems;
