import React from "react";
import ViewColors from "../components/Color/ViewColors";

interface Props {}

const ColorsPage: React.FC<Props> = () => {
  return <ViewColors />;
};

export default ColorsPage;
