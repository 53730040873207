import { useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { NOTIFYSTOCKS_QUERY } from "../Product/hooks/useNotifyStock";

interface Props {}

const ViewNotifyStock: React.FC<Props> = () => {
  const { refetch } = useQuery(NOTIFYSTOCKS_QUERY, {
    skip: true,
  });
  const tableRef = React.useRef(null);

  return (
    <>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Notify Stocks"
          columns={[
            { title: "Color Code", field: "colorCode", sorting: false },
            {
              title: "Email",
              field: "email",
              sorting: false,
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                {/* <MaterialTableCustomSort
                  sort={sortQueryContact}
                  setSort={setSortQueryContact}
                  materialTableRef={tableRef}
                  columns={columns}
                /> */}
              </Box>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            exportButton: true,
            pageSize: 10,
            pageSizeOptions: [10, 50, 100, 1000],
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  // name_contains: query.search,
                },
                // sort: sortQueryContact,
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      notifystocksConnection: { values, aggregate },
                    },
                  } = res;
                  console.log(values);
                  const newData = values.map((item: any) => ({
                    colorCode: item.colorCode,
                    email: item.email,
                  }));
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewNotifyStock;
