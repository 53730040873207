import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Select from "react-select";
import {
  useDeliveryPersonOrdersQuery,
  useOrderLazyQuery,
} from "../../generated/graphql";
import useStore from "../../utils/store";
import Loading from "../Misc/Loading";
import Order from "../Order";
import DeliveryPersonButtons from "./DeliveryPersonButtons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    label: {
      fontWeight: "bold",
      fontSize: 18,
      paddingBottom: 10,
      paddingTop: 10,
    },
  })
);

interface Props {}

const DeliveryPersonOrders: React.FC<Props> = () => {
  const classes = useStyles();
  const role = useStore((state) => state.role);
  const username = useStore((state) => state.username);

  const { data, loading, error } = useDeliveryPersonOrdersQuery();
  const [
    fetchOrder,
    { loading: orderLoading, data: orderData },
  ] = useOrderLazyQuery();

  const handleChange = async (val: any) => {
    await fetchOrder({ variables: { id: val.value } });
  };

  if (loading) return <Loading />;
  if (error) return <div>Error</div>;

  const orders = data?.deliveryPersonOrders;

  return (
    <div className={classes.root}>
      <div className={classes.label}>Select the order: </div>
      <Select
        options={orders?.map((order) => ({
          value: order?.id,
          label: order?.orderCode,
        }))}
        onChange={handleChange}
      />
      {orderLoading ? (
        <Loading />
      ) : (
        orderData && (
          <div>
            <DeliveryPersonButtons
              orderId={orderData.orderh?.id}
              status={
                orderData.orderh?.orderds?.filter((item) =>
                  role === "DeliveryPerson"
                    ? item?.deliveryPerson?.username === username
                    : true
                )?.[0]?.status
              }
            />
            <Order order={orderData.orderh} />
          </div>
        )
      )}
    </div>
  );
};

export default DeliveryPersonOrders;
