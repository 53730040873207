import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import WebContentForm from "../components/WebContent/WebContentForm";

interface Props {}

const WebContentFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/web-content-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(WEBCONTENT_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { webContent } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <WebContentForm webContent={webContent} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <WebContentForm />
      </div>
    );
  }
};

export default WebContentFormPage;

export const WEBCONTENT_QUERY = gql`
  query WEBCONTENT_QUERY($id: ID!) {
    webContent(id: $id) {
      id
      title
      link
      image
      desp
      type
      linkText
      isLeft
      mobileImage
      color
      mobileColor
      imageOnly
      heroButtonBackgroundColor
      heroButtonHoverBackgroundColor
      heroButtonTextColor
      heroButtonHoverTextColor
    }
  }
`;
