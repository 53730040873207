import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";
import StoreForm from "../components/Store/StoreForm";

interface Props {}

const StoreFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/store-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(STORE_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { store } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <StoreForm store={store} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <StoreForm />
      </div>
    );
  }
};

export default StoreFormPage;

export const STORE_QUERY = gql`
  query STORE_QUERY($id: ID!) {
    store(id: $id) {
      id
      storeName
    }
  }
`;
