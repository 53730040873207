import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useNotifyStock = () => {
  const { data, loading, error } = useQuery(NOTIFYSTOCKS_QUERY);
  return {
    notifyStockData: data,
    notifyStockLoading: loading,
    notifyStockError: error,
  };
};

export default useNotifyStock;

export const NOTIFYSTOCKS_QUERY = gql`
  query NOTIFYSTOCKS_QUERY(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    notifystocksConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        colorCode
        email
      }
    }
  }
`;
