import { green } from "@material-ui/core/colors";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useDiscountStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    heading: {
      fontWeight: "bold",
      letterSpacing: 3,
      textAlign: "center",
      padding: 10,
      fontSize: 20,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    paper: {
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "80%",
      margin: "0 auto",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "85%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      // margin: "0 auto"
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    input: {
      display: "none",
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    image: {
      height: 300,
      width: "100%",
      margin: "0 auto",
    },
  })
);

export default useDiscountStyles;
