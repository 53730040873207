import { useQuery } from "@apollo/client";
import { Badge, Box, Button } from "@chakra-ui/react";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable, { MTableToolbar } from "material-table";
import * as React from "react";
import { useHistory } from "react-router-dom";
import createPersistedState from "use-persisted-state";
import MaterialTableCustomSort from "../Misc/MaterialTableCustomSort";
import { BLOGS_QUERY } from "../Product/hooks/useBlogs";
interface Props {}
const columns = [
  {
    id: "title",
    name: "Title",
  },
  {
    id: "is_active",
    name: "Publish Status",
  },
];
const ViewBlogs: React.FC<Props> = () => {
  const useCounterState = createPersistedState("sortQueryBlog");
  const [sortQueryBlog, setSortQueryBlog] = useCounterState("title:asc");
  const tableRef = React.useRef(null);

  const history = useHistory();
  const { refetch } = useQuery(BLOGS_QUERY, {
    skip: true,
  });

  const handleEditClick = (id: string) => {
    history.push({
      pathname: `/admin/blog-form/${id}`,
    });
  };

  const handleAddClick = () => {
    history.push({
      pathname: "/admin/blog-form",
    });
  };

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={handleAddClick}
        className="add-btn"
        m="3"
        size="sm"
        leftIcon={<AddIcon />}
        textTransform="uppercase"
      >
        Add Blog
      </Button>
      <div>
        <MaterialTable
          tableRef={tableRef}
          title="Blog"
          columns={[
            { title: "Blog Name", field: "title", sorting: false },
            {
              title: "Publish Status",
              field: "is_active",
              filtering: false,
              render: (rowData: any) => (
                <Badge
                  colorScheme={rowData.is_active ? "green" : "yellow"}
                  variant="solid"
                >
                  {rowData.is_active ? "Published" : "Draft"}
                </Badge>
              ),
              sorting: false,
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Box display="flex" w="100%" justifyContent="space-between">
                <Box flex="1">
                  <MTableToolbar {...props} />
                </Box>
                <MaterialTableCustomSort
                  sort={sortQueryBlog}
                  setSort={setSortQueryBlog}
                  materialTableRef={tableRef}
                  columns={columns}
                />
              </Box>
            ),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Blog",
              onClick: (event, rowData: any) => {
                handleEditClick(rowData.id);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            sorting: false,
            headerStyle: {
              zIndex: "unset",
            },
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              refetch({
                where: {
                  title_contains: query.search,
                },
                sort: sortQueryBlog,
                start: query.page * query.pageSize,
                limit: query.pageSize,
              })
                .then((res) => {
                  const {
                    data: {
                      blogsConnection: { values, aggregate },
                    },
                  } = res;
                  const newData = values.map((item: any) => ({
                    id: item.id,
                    title: item.title,
                    is_active: item.is_active,
                  }));
                  resolve({
                    data: newData,
                    page: query.page,
                    totalCount: aggregate.totalCount,
                  });
                })
                .catch((err) => console.log(err));
            })
          }
        />
      </div>
    </>
  );
};

export default ViewBlogs;
