import React from "react";
import ViewWarrenties from "../components/Warrenty/ViewWarrenties";

interface Props {}

const WarrantiesPage: React.FC<Props> = () => {
  return <ViewWarrenties />;
};

export default WarrantiesPage;
