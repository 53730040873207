import React from "react";
import ViewDiscounts from "../components/Discount/ViewDiscounts";

interface Props {}

const DiscountsPage: React.FC<Props> = () => {
  return <ViewDiscounts />;
};

export default DiscountsPage;
