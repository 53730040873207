import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const useWebContent = () => {
  const { data, loading, error } = useQuery(WEBCONTENT_QUERY);
  return {
    webContentData: data,
    webContentLoading: loading,
    webContentError: error,
  };
};

export default useWebContent;

export const mapWebContent = (webContentData: any) => {
  const mappedWebContent = webContentData.webContentsConnection.values.map(
    (webContent: { title: string; __typename: string; id: number }) => ({
      id: webContent.id,
      label: webContent.title,
      value: webContent.title,
    })
  );
  return mappedWebContent;
};

export const WEBCONTENT_QUERY = gql`
  query WEBCONTENT_QUERY(
    $sort: String
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    webContentsConnection(
      start: $start
      limit: $limit
      sort: $sort
      where: $where
    ) {
      aggregate {
        totalCount
      }
      values {
        id
        title
        link
        image
        desp
        type
        linkText
        isLeft
        color
        mobileImage
      }
    }
  }
`;
