import { gql, useMutation } from "@apollo/client";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Poppers from "@material-ui/core/Popper";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import styles from "../../../assets/jss/material-dashboard-react/components/headerLinksStyle";
import Button from "../CustomButtons/Button";

const useStyles = makeStyles(styles as any);
interface Props {}

const AdminNavbarLinks: React.FC<Props> = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [logout] = useMutation(LOGOUT_MUTATION);
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event: any) => {
    if (openProfile && (openProfile as any).contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    history.push("/admin/user");
    setOpenProfile(null);
  };

  const handleLogoutClick = async () => {
    try {
      await logout();
      sessionStorage.clear();
      localStorage.clear();
      history.push("/login");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className={classes.manager}>
        <Button
          // color={window.innerWidth > 959 ? "transparent" : "white"}
          // justIcon={window.innerWidth > 959}
          // simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          //@ts-ignore
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              //@ts-ignore
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <MenuList role="menu">
                  {/* <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem> */}
                  <Divider light />
                  <MenuItem
                    onClick={handleLogoutClick}
                    className={classes.dropdownItem}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
};

export default AdminNavbarLinks;

const LOGOUT_MUTATION = gql`
  mutation LOGOUT_MUTATION {
    logout
  }
`;
