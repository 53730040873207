import { Badge, Box, Button, Container, Flex, Text } from "@chakra-ui/react";
// import Button from "@material-ui/core/Button";
import FaceIcon from "@material-ui/icons/Face";
import ShopIcon from "@material-ui/icons/Shop";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import Axios from "axios";
//@ts-ignore
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiLink } from "../../config";
import { CBCRoles, CBRoles } from "../../utils/misc";
import Loading from "../Misc/Loading";
import Charts from "./Charts";
import DeliveryPersonOrders from "./DeliveryPersonOrders";
import Summary from "./Summary";

const Dashboard = () => {
  const role = sessionStorage.getItem("role");
  const username = sessionStorage.getItem("username");
  const history = useHistory();
  const now = new Date();
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchSummary = async () => {
    const res = await Axios.get(`${apiLink}/orderhs/get-summary`, {
      withCredentials: true,
    });
    console.log(res.data);
    setSummary(res.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSummary();
  }, []);
  return (
    <>
      <Container centerContent>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          bg="gray.50"
          shadow="lg"
        >
          <Box
            p="15px"
            display="flex"
            fontFamily="sans-serif"
            flexDirection="column"
            alignItems="center"
            maxW="sm"
          >
            <Badge
              colorScheme="green"
              fontSize="2xl"
              mb="1"
              rounded="full"
              textAlign="center"
              px="3"
            >
              <Text display="flex" alignItems="center">
                <FaceIcon /> <Text ml="1">{username}</Text>
              </Text>
            </Badge>
            <Badge colorScheme="purple" display="block" fontSize="base">
              {dateFormat(now, "dddd, mmmm dS, yyyy")}
            </Badge>
          </Box>

          <>
            <Flex direction={{ base: "column", md: "row" }}>
              {CBCRoles(role) ? (
                <>
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => history.push("/admin/returnorders")}
                    m="15px"
                    mt={{ base: 0, md: 15 }}
                    letterSpacing="0.01rem"
                    shadow="lg"
                    leftIcon={<ShopIcon />}
                  >
                    Return Orders
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => history.push("/admin/orders")}
                    m="15px"
                    mt={{ base: 0, md: 15 }}
                    letterSpacing="0.01rem"
                    shadow="lg"
                    leftIcon={<ShopIcon />}
                  >
                    My Orders
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => history.push("/admin/order-wise")}
                    m="15px"
                    mt={{ base: 0, md: 15 }}
                    letterSpacing="0.01rem"
                    shadow="lg"
                    leftIcon={<ShowChartIcon />}
                  >
                    Orders Wise
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => history.push("/admin/product-wise")}
                    m="15px"
                    mt={{ base: 0, md: 15 }}
                    letterSpacing="0.01rem"
                    shadow="lg"
                    leftIcon={<ShowChartIcon />}
                  >
                    Product Wise
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => history.push("/admin/category-wise")}
                    m="15px"
                    mt={{ base: 0, md: 15 }}
                    letterSpacing="0.01rem"
                    shadow="lg"
                    leftIcon={<ShowChartIcon />}
                  >
                    Category Wise
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => history.push("/admin/customer-wise")}
                    m="15px"
                    mt={{ base: 0, md: 15 }}
                    letterSpacing="0.01rem"
                    shadow="lg"
                    leftIcon={<ShowChartIcon />}
                  >
                    Customer Wise
                  </Button>

                  {/* <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => history.push("/admin/settings")}
                    m="15px"
                    mt={{ base: 0, md: 15 }}
                    letterSpacing="0.1rem"
                    shadow="lg"
                    leftIcon={<SettingsIcon />}
                  >
                    Settings
                  </Button> */}
                </>
              ) : (
                <></>
              )}
            </Flex>
          </>
        </Box>
      </Container>
      <Box>
        {role && role === "DeliveryPerson" && <DeliveryPersonOrders />}

        {role && CBRoles(role) && (
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Summary data={summary} />
                <Charts data={summary} />
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Dashboard;
