import * as React from "react";
import GoBackButton from "../../Misc/GoBackButton";
import FilterBox from "./FilterBox";
import HitCount from "./HitCount";
import LowStock from "./LowStock";
import PurchaseCount from "./PurchaseCount";
import PurchaseGrowth from "./PurchaseGrowth";

interface Props {}

const ProductWise: React.FC<Props> = () => {
  const today = new Date(Date.now()).toISOString().split("T")[0];
  const [fromDate, setFromDate] = React.useState(
    localStorage.getItem("ProductWiseFromDate") ||
      new Date(today.slice(0, 7)).toISOString().split("T")[0]
  );
  const [toDate, setToDate] = React.useState(
    localStorage.getItem("ProductWiseToDate") || today
  );
  const [orderBy, setOrderBy] = React.useState(
    localStorage.getItem("ProductWiseOrderBy") || "DESC"
  );

  React.useEffect(() => {
    localStorage.setItem("ProductWiseFromDate", fromDate);
    localStorage.setItem("ProductWiseToDate", toDate);
    localStorage.setItem("ProductWiseOrderBy", orderBy);
  }, [fromDate, toDate, orderBy]);
  return (
    <>
      <GoBackButton />
      <FilterBox
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "50% 50%",
          gridColumnGap: "20px",
        }}
      >
        <HitCount fromDate={fromDate} toDate={toDate} orderBy={orderBy} />
        <PurchaseCount fromDate={fromDate} toDate={toDate} orderBy={orderBy} />
        <PurchaseGrowth />
        <LowStock orderBy={orderBy} />
      </div>
    </>
  );
};

export default ProductWise;
