import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

interface Props {
  fromDate: string;
  toDate: string;
  setFromDate: React.Dispatch<React.SetStateAction<string>>;
  setToDate: React.Dispatch<React.SetStateAction<string>>;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  outBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  innerBox: {
    border: "1px solid #000000",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "white",
  },
}));

const FilterBox: React.FC<Props> = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  orderBy,
  setOrderBy,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.outBox}>
      <div className={classes.innerBox}>
        <TextField
          id="date"
          label="From Date"
          type="date"
          defaultValue={fromDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setFromDate(e.target.value)}
        />
        <TextField
          id="date"
          label="To Date"
          type="date"
          defaultValue={toDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setToDate(e.target.value)}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Order By</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value as string)}
          >
            <MenuItem value={"ASC"}>Ascending</MenuItem>
            <MenuItem value={"DESC"}>Descending</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default FilterBox;
const backgroundColors = [
  "#FF6363",
  "#FFAB76",
  "#867AE9",
  "#FFD31D",
  "#3FC5F0",
];
const colors = ["#3e3e3e"];
export const TableTitle = ({
  title,
  backgroundColorIndex,
  ColorIndex,
}: any) => (
  <div
    style={{
      fontSize: "24px",
      fontWeight: "bold",
      backgroundColor: backgroundColors[backgroundColorIndex],
      color: colors[ColorIndex],
      padding: "15px",
      textTransform: "uppercase",
      marginLeft: "-10px",
      marginRight: "10px",
      marginTop: "-10px",
      borderRadius: "8px",
      boxShadow: "5px 5px 1px 0px  rgba(156,156,156,0.64)",
      marginBottom: "10px",
    }}
  >
    {title}
  </div>
);
