import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import BrandForm from "../components/Brand/BrandForm";
import GoBackButton from "../components/Misc/GoBackButton";
import Loading from "../components/Misc/Loading";

interface Props {}

const BrandFormPage: React.FC<Props> = () => {
  const match = useRouteMatch("/admin/brand-form/:id");
  const isEdit = match !== null;
  let id = null;

  if (isEdit) {
    //@ts-ignore
    id = match?.params.id;
  }

  const { data, loading, error } = useQuery(BRAND_QUERY, {
    variables: {
      id,
    },
    skip: id === null,
    fetchPolicy: "cache-and-network",
  });
  if (error) return <div>Error</div>;
  if (loading) return <Loading />;

  if (id) {
    const { brand } = data;
    // console.log(getQuiz);
    return (
      <div>
        <GoBackButton />
        <BrandForm brand={brand} />
      </div>
    );
  } else {
    return (
      <div>
        <GoBackButton />
        <BrandForm />
      </div>
    );
  }
};

export default BrandFormPage;

export const BRAND_QUERY = gql`
  query BRAND_QUERY($id: ID!) {
    brand(id: $id) {
      id
      brandName
      inactive
    }
  }
`;
