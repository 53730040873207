import Axios from "axios";
import MaterialTable from "material-table";
import * as React from "react";
import { api } from "../../../config";
import { TableTitle } from "./FilterBox";

interface Props {
  orderBy: string;
}

const LowStock: React.FC<Props> = ({ orderBy }) => {
  const [products, setProducts] = React.useState([]);

  const getProducts = async () => {
    const res = await Axios.get(
      `${api}/color-cats?colorStock_lt=3&_limit=1000&_sort=colorStock:${orderBy}`,
      {
        withCredentials: true,
      }
    );
    // console.log(res.data);
    const data = res.data.map((item: any) => ({
      name: item.product.productName + "-" + item.colorCode,
      stock: item.colorStock,
    }));
    setProducts(data);
  };
  React.useEffect(() => {
    getProducts();
  }, [orderBy]);
  return (
    <>
      <div style={{ marginTop: 20 }}>
        <TableTitle
          title="Low Stock (less than 3)"
          backgroundColorIndex={3}
          ColorIndex={0}
        />
        <MaterialTable
          columns={[
            { title: "Product Name", field: "name" },
            { title: "Stock", field: "stock" },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            debounceInterval: 500,
            toolbar: false,
          }}
          data={products}
        />
      </div>
    </>
  );
};

export default LowStock;
