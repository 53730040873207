import React from "react";
import ViewNotifyStock from "../components/NotifyStock/ViewNotifyStock";

interface Props {}

const NotifyStockPage: React.FC<Props> = () => {
  return <ViewNotifyStock />;
};

export default NotifyStockPage;
