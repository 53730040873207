import React from "react";
import ViewContacts from "../components/Contact/ViewContact";

interface Props {}

const ContactsPage: React.FC<Props> = () => {
  return <ViewContacts />;
};

export default ContactsPage;
