import { makeStyles } from "@material-ui/core/styles";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React from "react";
import { Route, Switch } from "react-router-dom";
import logo from "../assets/img/logo.png";
import bgImage from "../assets/img/sidebar-5.jpg";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";
import Navbar from "../components/Template/Navbars/Navbar";
import Sidebar from "../components/Template/Sidebar/Sidebar";
import { CBRoles } from "../utils/misc";
import routes, { cashierRoutes, marketingRoutes } from "../utils/routes";

let ps: any;
type RouteProps = {
  path: string;
  name: string;
  rtlName: string;
  icon: React.ReactNode;
  component: React.FC | React.ComponentClass;
  layout: string;
  show: boolean;
};

const switchRoutes = (
  <Switch>
    {routes.map((prop: RouteProps, key: any) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    {/* <Redirect from="/admin" to="/admin/dashboard" /> */}
  </Switch>
);

const useStyles = makeStyles(styles as any);

export default function Home({ ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [image] = React.useState(bgImage);
  const [color] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const role = sessionStorage.getItem("role");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current as any, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={
          role && CBRoles(role)
            ? routes
            : role && role === "Cashier"
            ? cashierRoutes
            : role && role === "Marketing"
            ? marketingRoutes
            : []
        }
        logoText={""}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        //@ts-ignore
        color={color}
        {...rest}
      />
      <div
        className={classes.mainPanel}
        //@ts-ignore
        ref={mainPanel}
      >
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
      </div>
    </div>
  );
}
